import { Component, Input, OnInit } from '@angular/core';
import { Const } from '@wearewarp/universal-libs';
import { Const as ConstLocal } from '@const/Const';
import { ResponsePalletHistoryItem } from '@wearewarp/types/rest-api/admin/pallet-history'
import { Shipment, ShipmentItem, Task } from '@wearewarp/types/data-model';
import { BaseDialog } from '@dialogs/base-dlg';

@Component({
  selector: 'pallet-history',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class PalletHistory extends BaseDialog {
  @Input() shipmentId: string;
  @Input() itemId: string;

  public listHistory: ResponsePalletHistoryItem[] = [];
  public isLoading: Boolean = false;

  public get noData(){
    return !this.listHistory.length;
  }
  constructor() {
    super()
  }

  ngOnInit(): void {
    if(this.shipmentId){
      this.getDataForShipment();
    }
    else if(this.itemId){
      this.getDataForItem();
    }
  }

  private getDataForShipment(){
    let url = `${ConstLocal.API_WAREHOUSE_MANAGEMENT('pallet_history')}/${this.shipmentId}`;
    this.isLoading = true;
    this.api.GET(url).subscribe({
      next: (res) => {
        this.listHistory = res.data.list_data;
        this.isLoading = false;
        console.log(res)
      },
      error: (err) => {
        this.isLoading = false;
        console.log(err)
      }
    })
  }

  private getDataForItem(){
    let url = `${ConstLocal.API_WAREHOUSE_MANAGEMENT('pallet_history')}/${this.itemId}/item`;
    this.isLoading = true;
    this.api.GET(url).subscribe({
      next: (res) => {
        this.listHistory = res.data.list_data;
        this.isLoading = false;
        console.log(res)
      },
      error: (err) => {
        this.isLoading = false;
        console.log(err)
      }
    })
  }

  getDotColor(item: ResponsePalletHistoryItem) {
    if(this.isJobTask(item) && !this.isUploadPODTask(item) && !this.isScanBarcodeTask(item)) {
      return 'blue'
    }
    return 'gray'
  }

  isJobTask(item: ResponsePalletHistoryItem) {
    return item.type == 'route_task'
  }

  isWarehouseTask(item: ResponsePalletHistoryItem) {
    return item.type == 'warehouse_task'
  }

  isUploadProductPhotoWarehouseTask(item: ResponsePalletHistoryItem) {
    return this.isWarehouseTask(item) && item.data.taskType == Const.WarehouseTaskType.uploadProductPhoto
  }

  isUpdateShipmentItemTask(item: ResponsePalletHistoryItem) {
    return item.type == 'update_shipment_items'
  }

  isUploadPODTask(item: ResponsePalletHistoryItem) {
    return item.type == 'route_task' && item.data?.taskType == Const.PalletHistoryTaskType.route_task.uploadPOD
  }

  isScanBarcodeTask(item: ResponsePalletHistoryItem) {
    return item.type == 'route_task' && item.data?.taskType == Const.PalletHistoryTaskType.route_task.scanBarcode
  }

  isTransportTask(item: ResponsePalletHistoryItem) {
    return this.isJobTask(item) && !this.isUploadPODTask(item) && !this.isScanBarcodeTask(item)
  }

  isUploadBOLTask(item: ResponsePalletHistoryItem) {
    return this.isWarehouseTask(item) && item.data.taskType == Const.WarehouseTaskType.uploadBOL
  }

  isAddShipmentItemsTask(item: ResponsePalletHistoryItem) {
    return this.isUpdateShipmentItemTask(item) && item.data.taskType == Const.PalletHistoryTaskType.update_shipment_items.addItem
  }

  getActionText(item: ResponsePalletHistoryItem) {
    if(this.isWarehouseTask(item)) {
      let name = item.data.metadata?.location?.name || item.data.metadata?.location?.warpId || 'N/A';
      if(this.isUploadBOLTask(item)) {
        return `Shipment ${item.data.metadata?.task?.metadata?.warpId || 'N/A'}, ${this.getActionTextWarehouseTask(item)}`
      } else {
        let stage = item.data.metadata?.task?.stage;
        stage = stage == 'inbound' ? 'IN' : 'OUT';
        return `[${stage}] Item ID ${item.data.metadata?.item?.warpId || 'N/A'}, ${this.getActionTextWarehouseTask(item)}`
      }
    } 
    else if(this.isUploadPODTask(item)) {
      return this.getActionTextUpdatePODTask(item)
    } 
    else if(this.isScanBarcodeTask(item)) {
      return `Scan barcode at ${item.data.metadata?.stop?.type}`
    }
    else if(this.isJobTask(item)) {
      return this.getActionTextJobTask(item)
    }
    else {
      return this.getActionTextUpdateShipmentItemTask(item);
    }
  }

  getActionTextWarehouseTask(item: ResponsePalletHistoryItem) {
    let type = item.data.taskType;
    let status = item.data.metadata?.task?.status;
    let itemInfo = item.data.metadata?.item || {};
    if(status == Const.WarehouseTaskStatus.completed) {
      switch(type) {
        case Const.WarehouseTaskType.addDimensions: 
          return `Add dimensions ${itemInfo?.length}x${itemInfo?.width}x${itemInfo?.height} ${itemInfo?.sizeUnit}`
        case Const.WarehouseTaskType.addWeight: 
          return `Update weight ${itemInfo?.actualWeight} ${itemInfo?.weightUnit}`
        case Const.WarehouseTaskType.assignBarcode: 
          return `Assign barcode ${itemInfo?.barcodes?.join(', ')}`
        case Const.WarehouseTaskType.markLoaded: 
          return 'Mark loaded'
        case Const.WarehouseTaskType.printLabel: 
          return 'Print label'
        case Const.WarehouseTaskType.uploadBOL: 
          return 'Upload BOL'
        case Const.WarehouseTaskType.uploadProductPhoto: 
          return 'Upload product photo'
      }
    } else {
      let action = status === Const.WarehouseTaskStatus.pending ? 'Reset' : 'Skip';
      switch(type) {
        case Const.WarehouseTaskType.addDimensions: 
          return `${action} add dimensions`
        case Const.WarehouseTaskType.addWeight: 
          return `${action} update weight`
        case Const.WarehouseTaskType.assignBarcode: 
          return `${action} assign barcode`
        case Const.WarehouseTaskType.markLoaded: 
          return `${action} mark loaded`
        case Const.WarehouseTaskType.printLabel: 
          return `${action} print label`
        case Const.WarehouseTaskType.uploadBOL: 
          return `${action} upload BOL`
        case Const.WarehouseTaskType.uploadProductPhoto: 
          return `${action} upload product photo`
      }
    }
  }

  getLocationNameForWarehouseTask(item: ResponsePalletHistoryItem) {
    return item.data.metadata?.location?.name || item.data.metadata?.location?.warpId || 'N/A';
  }

  getLinkLocation(item: ResponsePalletHistoryItem) {
    return `${this.routeAdminLocationList}?search=${item.data.metadata?.location?.name || ''}`
  }

  getActionTextJobTask(item: ResponsePalletHistoryItem) {
    const task = item.data.metadata?.task || {} as Task;
    const shipment = item.data.metadata?.shipment || {} as Shipment;
    const items = item.data.metadata?.items || [] as ShipmentItem[];
    let text1 = `${task?.type} at ${this.getAddress(task)}`;
    let text2 = `Shipment ${shipment?.code}(S-${shipment.warpId}), ${items.length} items - ${this.synthesizeItemText(items)}`;
    return `${text1}, ${text2}`
  }

  getActionTextUpdatePODTask(item: ResponsePalletHistoryItem) {
    const task = item.data.metadata?.task || {} as Task;
    const shipment = item.data.metadata?.shipment || {} as Shipment;
    return `Update POD for task ${task.type} shipment ${shipment?.code}(S-${shipment.warpId}) at ${this.getAddress(task)}`
  }

  getAddress(task: Task) {
    let address = task?.address?.formatedAddress;
    if(!address) {
      address = `${task?.address?.street}, ${task?.address?.city}, ${task?.address?.state} ${task?.address?.zipcode}`
    }
    return address
  }

  synthesizeItemText(items: ShipmentItem[]) {
    let rs: any = {};
    for(let item of items) {
      if(rs[item.qtyUnit]){
        rs[item.qtyUnit] += item.qty;
      } else {
        rs[item.qtyUnit] = item.qty;
      }
    }
    let tmpArr = [];
    for(let key of Object.keys(rs)) {
      tmpArr.push(`${rs[key]} ${key}`)
    }
    return tmpArr.join(', ')
  }

  getActionTextUpdateShipmentItemTask(item: ResponsePalletHistoryItem) {
    const shipment = item.data.metadata?.shipment || {} as Shipment;
    let shipmentItems = item.data.metadata?.items || [] as ShipmentItem[];
    if(!shipmentItems?.length) {
      shipmentItems = [item.data.metadata?.item]
    }
    let itemNameText = shipmentItems.map(item => `Item ID ${item?.warpId || 'N/A'}`).join(', ')
    switch(item.data.taskType) {
      case Const.PalletHistoryTaskType.update_shipment_items.addItem: {
        return `Shipment ${shipment?.code}(S-${shipment.warpId}) - Add item: ${itemNameText}(${this.synthesizeItemText(shipmentItems)})`
      }
      case Const.PalletHistoryTaskType.update_shipment_items.createdItem: {
        return `Shipment ${shipment?.code}(S-${shipment.warpId}) - Create item: ${itemNameText}(${this.synthesizeItemText(shipmentItems)})`
      }
      case Const.PalletHistoryTaskType.update_shipment_items.editItem: {
        let dataUpdate = item.data.metadata?.dataUpdate || [];
        let txt = '';
        for(let index in dataUpdate) {
          let key = dataUpdate[index];
          let arr = key.split('.');
          let value = shipmentItems[0];
          if(arr.length > 1) {
            for(let childKey of arr) {
              value = value[childKey];
            }
          } else {
            value = value[key];
          }
          if(Number(index) > 0) {
            txt += ', '
          }
          txt += `${key}: ${value}`
        }
        return `Shipment ${shipment?.code}(S-${shipment.warpId}) - Edit item: ${itemNameText}(${txt})`
      }
      case Const.PalletHistoryTaskType.update_shipment_items.removeItem: {
        return `Shipment ${shipment?.code}(S-${shipment.warpId}) - Remove item: ${itemNameText}(${this.synthesizeItemText(shipmentItems)})`
      }
    }
  }

  getActorText(item: ResponsePalletHistoryItem) {
    const by = item.whenBy?.by || {};
    let actor;
    if(by?.fullname) {
      actor = by.fullname;
    }else {
      actor = `${by.firstName} ${by.lastName}`;
    }
    return `by ${this.getActorRoleText(by?.collection)} ${actor}`
  }

  getActorRoleText(role: string) {
    switch(role) {
      case 'users': return 'User';
      case 'drivers': return 'Driver';
      case 'carriers': return 'Carrier';
      case 'clients': return 'Customer';
    }
  }

  getTimeLabel(item: ResponsePalletHistoryItem) {
    return this.displayDateDB(item.whenBy?.when, ConstLocal.FORMAT_GUI_DATETIME_V3)
  }

  getPodForTaskUploadPod(item: ResponsePalletHistoryItem) {
    if(!this.isUploadPODTask(item)){
      return [];
    }
    return item.data.metadata?.podUploadFiles || [];
  }
  
  getPhotoForTaskUploadProductPhoto(item: ResponsePalletHistoryItem) {
    if(!this.isUploadProductPhotoWarehouseTask(item)){
      return [];
    }
    return item.data.metadata?.photoUploads || [];
  }

  getPhotoForTaskAddShipmentItems(item: ResponsePalletHistoryItem) {
    if(!this.isAddShipmentItemsTask(item)){
      return [];
    }
    return item.data.metadata?.photoUploads || [];
  }

  linkActorDetail(item: ResponsePalletHistoryItem) {
    const by = item.whenBy?.by || {};
    if(by?.collection == 'users') {
      return `${this.routeAdminUserList}/${by.id}`
    } 
    else if(by?.collection == 'drivers') {
      return `${this.routeAdminDriverList}/${by.id}`
    }
    else if(by?.collection == 'carriers') {
      return `${this.routeAdminCarrierList}/${by.id}`
    }
    else if(by?.collection == 'clients') {
      return `${this.routeAdminClientList}/${by.id}`
    }
  }
}
