import { Component } from "@angular/core";
import { BaseForm } from "@app/admin/base/form-base";
import { FormDataShipmentThirdPartyCarrierInfo } from "@wearewarp/types/rest-api/admin/form-data/shipment-manifest";
import { Const } from "@const/Const";
import { Validators } from '@angular/forms';
import { BizUtil } from "@services/biz";

@Component({
  selector: '[form-shipment-third-party-carrier-info]',
  templateUrl: './index.html',
})
export class FormShipmentThirdPartyCarrierInfo extends BaseForm<FormDataShipmentThirdPartyCarrierInfo> {

  protected formGroupDeclaration: FormGroupDeclaration = {
    carrierName: {label: 'Carrier name', placeHolder: 'Enter external carrier', required: true},
    primaryContact: {label: 'Primary Contact', required: true, type: 'formGroup', childItem: {
      firstName: {label: 'First Name', required: true},
      lastName: {label: 'Last Name', required: true},
      phone: {label: 'Phone', required: true},
      phoneExtension: {label: '', placeHolder: 'Ext'},
      email: {label: 'Email', validators: Validators.email}
    }},
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  join(...args): string {
    return args.join(".");
  }

  public getFormData(): FormDataShipmentThirdPartyCarrierInfo {
    let data = super.getFormData();
    data.contacts = [];
    if (BizUtil.isContactNotEmpty(data.primaryContact)) {
      data.contacts.push({...data.primaryContact, type: Const.ContactType.primary});
    }
    delete data.primaryContact;
    return data
  }

  protected beforeBindModel(model: FormDataShipmentThirdPartyCarrierInfo): FormDataShipmentThirdPartyCarrierInfo {
    for (let item of model.contacts ?? []) {
      switch (item.type) {
        case Const.ContactType.primary:
          model.primaryContact = item;
          break;
        case Const.ContactType.secondary:
          model.secondaryContact = item;
          break;
      }
    }
    return model;
  }

}
