import { Component, ViewChild } from '@angular/core';
import { BaseForm } from '@app/admin/base/form-base';
import { ShipmentManifestTabContent } from '../../tab-content-component';
import { ComponentForm } from '@app/admin/crossdock/manifests/interface';
import { FormDataManifestItems } from "@wearewarp/types/rest-api/admin/form-data/shipment-manifest";
import { FormManifestItems } from '../../forms/items';

@Component({
  selector: '[shipment-manifest-items]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class ShipmentManifestItems extends ShipmentManifestTabContent implements ComponentForm<FormDataManifestItems> {
  @ViewChild('formComp') formComp: FormManifestItems;
  
  getForm(): BaseForm<FormDataManifestItems> {
    return this.formComp;
  }

}