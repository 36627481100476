import { Component } from "@angular/core";
import { BaseForm } from "@app/admin/base/form-base";
import { Input } from "@angular/core";
import { ShipmentManifestCreateMode } from "@app/enum";
import { FormDataShipmentManifestBasicInfo } from "@wearewarp/types/rest-api/admin/form-data/shipment-manifest";
import { Const } from "@const/Const";
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { FormControl, Validators } from '@angular/forms';
import { VehicleType } from "@wearewarp/types/data-model";

@Component({
  selector: '[form-shipment-manifest-basic-info]',
  templateUrl: './index.html',
})
export class FormShipmentManifestBasicInfo extends BaseForm<FormDataShipmentManifestBasicInfo> {

  @Input() createMode: ShipmentManifestCreateMode;

  vehicleType: VehicleType;
  listClients = [];
  listShipmentTypes = [WarpConst.ShipmentType.fullTruckLoad, WarpConst.ShipmentType.lessThanTruckload];

  protected formGroupDeclaration: FormGroupDeclaration = {
    shipmentType: {label: 'Shipment Type', required: true, submitReadOnly: true, initialValue: 'FTL'},
    vehicleType: {label: 'Type of Truck', required: true},
    clientId: {label: 'Customer', placeHolder:'Select', required: true},
    isCrossDock: {label: 'Using Cross Dock', type: 'boolean', initialValue: true},
    isNotAutoRouteFTL: {label: 'Stop auto routing FTL', type: 'boolean', initialValue: false},
  }

  ngOnInit(): void {
    if (!this.isCreateModeWarpCarrier) {
      this.formGroupDeclaration.shipmentType.required = false;
      this.formGroupDeclaration.vehicleType.required = false;
    }
    super.ngOnInit();
  }

  get isCreateModeWarpCarrier() {
    return this.createMode === ShipmentManifestCreateMode.warpCarrier;
  }

  onDropdownSelectChange(key: string, event) {
    switch (key) {
      case 'vehicleType':
        this.vehicleType = event;
        if (event?.code) {
          this.setItemValue(key, event);
        } else {
          this.setItemValue(key, null);
        }
        break;
      case 'shipmentType':
        this.onShipmentTypeChange(event);
      default:
        break;
    }
  }

  private onShipmentTypeChange(value) {
    let isVehicleRequired = value === Const.ShipmentTypes.fullTruckLoad ? true : false;
    this.declaration.vehicleType.required = isVehicleRequired;
    for (let key of ['vehicleType']) {
      let fc = <FormControl> this.formInput.get(key);
      if (fc) {
        if (!isVehicleRequired) {
          fc.removeValidators(Validators.required);
        } else {
          fc.addValidators(Validators.required);
        }
        fc.updateValueAndValidity();
      }
    }
  }

  public getShipmentTypeName(code: string): string {
    switch (code) {
      case Const.ShipmentTypes.fullTruckLoad:
        return 'FTL';
      case Const.ShipmentTypes.lessThanTruckload:
        return 'LTL';
    }
  }

  public getFormData(): FormDataShipmentManifestBasicInfo {
    let data = super.getFormData();
    if (!this.isCreateModeWarpCarrier) {
      delete data.isNotAutoRouteFTL;
    }
    return data
  }

  get isFTL(): boolean {
    return this.getItemValue('shipmentType') == Const.ShipmentTypes.fullTruckLoad;
  }

}
