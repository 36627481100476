

import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableModule } from 'ng-zorro-antd/table';
import { RouterModule } from '@angular/router';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { ViewPreviousRouteComponent } from './view-previous-route.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzInputModule,
    NzPopoverModule,
    NzTableModule,
    RouterModule,
    NzDescriptionsModule,
    NzIconModule,
    NzCollapseModule,
    NzCalendarModule,
    NzDividerModule,
    NzToolTipModule,
  ],
  declarations: [
    ViewPreviousRouteComponent,
  ],
  exports: [
    ViewPreviousRouteComponent,
  ]
})
export class ViewPreviousRouteModule {}