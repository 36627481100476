import { IconLayer, ScatterplotLayer } from '@deck.gl/layers'
import { DateUtil } from '@services/date-utils';
import { CompositeLayer } from 'deck.gl';
import { DRIVER_PIN_ICON_MAPPING } from './warehouse_pin_mapping';

//FIXME
var lastPostition;
export default class LiveTrackingLayer extends CompositeLayer {
    id: string
    static layerName = "LiveTrackingLayer"
    data: any[]
    options: any = {}
    _baseLayer: any = null
    _layer: any = null

    constructor(id, locations, options = {}) {
        super({id, data: {locations, options}})
        this.options = options
        this.data = locations?.map(this.processLocationData).filter(it => it)

        this._layer = this.createDriverLocationLayer()
        this._baseLayer = this.createBaseDotLocationLayer()
    }

    loadLocations(locations) {
        if (locations?.length !== this.data?.length)
            this.data = locations?.map(this.processLocationData).filter(it => it)
        else {
            for (let i = 0; i < locations.length; i++) {
                const updated = this.processLocationData(locations[i])
                Object.assign(this.data[i], updated)
            }
        }
    }

    processLocationData(data) {
        const { location, ts, source } = data
        if (!location) return null
        const { heading, latitude, longitude } = location || {}
        let speed = location?.speed;
        if (speed == null || speed < 0) {
          speed = 0;
        }
        const mph = source && source !== 'driver-app' ? speed || 0 : (speed || 0) * 3600 / 1609.34
        const info = DateUtil.displayLocalTime(ts, {timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, format: 'MMM D, YYYY, h:mm:ss a'}) +
            `\nGPS: ${latitude.toFixed(3)},${longitude.toFixed(3)}` +
            `\nSpeed: ${mph.toFixed(0)} mph` +
            (source ? `\nSource: ${source}` : '')
        const icon =  mph >= 55 ? "0" : mph >= 30 ? "1" : mph >= 15 ? "2" : mph >= 5 ? "3" : mph >=2 ? "4" : "5"
        let bearing = 90 - (heading || 0)
        if (bearing < 0)
            bearing += 360

        return {
            ts,
            coordinates: [location.longitude, location.latitude],
            info,
            icon,
            angle: bearing,
            live: data.live,
        }
    }

    toRadians(degrees) {
        return degrees * Math.PI / 180;
    };

    private getDistance(lat1, lon1, lat2, lon2) {
        const R = 6371; // km
        const dLat = this.toRadians(lat2 - lat1);
        const dLon = this.toRadians(lon2 - lon1);
        lat1 = this.toRadians(lat1);
        lat2 = this.toRadians(lat2);

        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c;
        return d * 1000;
    }

    /**
     * tính toán lại duration cho transition dựa trên khoảng cách. để tránh bị giật icon trên map
     * @param data 
     * @returns 
     */
    private cacheDuration = 20;
    getTransitionDuration(data) {
        const minDuration = 20;
        const maxDuration = 2000;
        const lastHistory = data?.[data.length - 1];

        if (!lastPostition) {
            lastPostition = lastHistory;
            return minDuration;
        }
        if(lastHistory.ts === lastPostition.ts) return this.cacheDuration;


        const prevLocation = lastPostition;
        const distance = this.getDistance(
            lastHistory.coordinates[1],
            lastHistory.coordinates[0],
            prevLocation.coordinates[1],
            prevLocation.coordinates[0]);
        lastPostition = lastHistory;

        this.cacheDuration = Math.min(Math.max(minDuration, distance * 10), maxDuration);
        return this.cacheDuration;
    }

    createBaseDotLocationLayer() {
        return new ScatterplotLayer({
            id: `${this.id}-live-layer-dot`,
            data: this.data,
            pickable: true,
            getPosition: d => [...d.coordinates, 0.0001],
            stroked: true,
            filled: true,
            opacity: 0.8,
            getRadius: d => 16,
            radiusMinPixels: 16,
            radiusMaxPixels: 16,
            getFillColor: d => d.live ? [64, 113, 203] : [100, 100, 100],
            getLineColor: d => d.live ? [64, 113, 203] : [100, 100, 100],
            transitions: {
                getFillColor: 200,
                getPosition: {
                    duration: this.getTransitionDuration(this.data),
                },
            },
            updateTriggers: {
                getPosition: [this.data],
                getFillColor: [this.data]
            }
        });
    }

    createDriverLocationLayer() {
        return new IconLayer({
            id: `${this.id}-live-layer`,
            data: this.data,
            pickable: true,
            getPosition: d => d.coordinates,
            iconAtlas: '/assets/svg/driver_pin.svg',
            iconMapping: DRIVER_PIN_ICON_MAPPING,
            getIcon: d => d.icon,
            getAngle: d => d.angle,
            getSize: d => 24,
            transitions: {
                getPosition: {
                    duration: this.getTransitionDuration(this.data),
                }
            },
            updateTriggers: {
                getPosition: [this.data]
            }
        });
    }

    renderLayers() {
        return [
            this._baseLayer, this._layer
        ];
    }

}
