import {Component, Input} from '@angular/core';
import {BaseFormDialog1} from '@dialogs/base-form-dlg1';
import {Const} from '@const/Const';
import {FormEditPlanDuration} from '@app/admin/crossdock/rate-configuration/edit-plan-duration/FormEditPlan';
import moment from 'moment-timezone';
import {XDockDateHelper} from '@app/admin/crossdock/XDockDateHelper';

@Component({
  selector: '[warehouse-edit-rate-plan-duration]',
  templateUrl: './view.html',
  styleUrls: ['index.scss']
})
export class EditPlanDuration extends BaseFormDialog1<FormEditPlanDuration> {
  @Input() warehouseId: string;
  @Input() timezone: string;
  protected formGroupDeclaration: FormGroupDeclaration = {
    startDate: {label: 'Apply from date', type: 'date', notAcceptEmpty: true, required: true, readOnly: true},
    endDate: {label: 'To date', type: 'date', notAcceptEmpty: true, required: true},
  };

  get timezoneText() {
    return XDockDateHelper.timezoneText(this.timezone);
  }

  get shouldCreateFormImmediately() {
    return true;
  }

  get headerText() {
    return 'Edit Plan Duration';
  }

  getDefaultEndDateSelect() {
    // ngày kết thúc plan >= ngày bắt đầu hoặc >= ngày mai
    const tomorrow = moment(new Date()).add(1, 'day').endOf('day').toDate();
    const startDateValue = this.getItemValue('startDate');
    return startDateValue ? moment(startDateValue).endOf('day').toDate() : tomorrow;
  }

  disabledEndDate = (value: Date): boolean => {
    return moment(value).endOf('day').toDate().getTime() < this.getDefaultEndDateSelect().getTime();
  };

  clickEdit() {
    if (!this.warehouseId) {
      this.showErr('Missing warehouseId!');
      return;
    }
    if (!this.model.id) {
      this.showErr('Missing planId!');
      return;
    }
    if (!this.timezone) {
      this.showErr('Missing timezone!');
      return;
    }
    this.onProgress = true;
    const url = `${Const.APIURI_WAREHOUSE_RATE_PLAN}/${this.model.id}`;
    const body = this.getFormData_JSON(true);
    body.startDate = XDockDateHelper.getWarehouseMoment(this.model.startDate, this.timezone).startOf('day').toISOString();
    body.endDate = XDockDateHelper.getWarehouseMoment(body.endDate, this.timezone).endOf('day').toISOString();
    const options = {customHeaders: {warehouseId: this.warehouseId}};
    this.api.PUT(url, body, options).subscribe(
      resp => {
        this.onProgress = false;
        this.onUpdateSuccess(resp);
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }

  protected beforeBindModel(model: FormEditPlanDuration): any {
    model.startDate = XDockDateHelper.getLocalMoment(model.startDate, this.timezone).toISOString();
    model.endDate = XDockDateHelper.getLocalMoment(model.endDate, this.timezone).toISOString();
    return super.beforeBindModel(model);
  }
}
