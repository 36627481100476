<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>

<div *ngIf="!isLoading && !isError">
  <div class="flex-space-between">
    <div>
      <span nz-icon nzType="info-circle" nzTheme="fill" style="color: #0A6CF5;"></span>
      <span class="left10">Only pallets can be added. If you have cartons or pieces separately, please palletize them first.</span>
    </div>
    <button nz-button (click)="onBtnAddPallet()" [disabled]="!isEnabledBtnAddPallet">
      <i nz-icon nzType="plus"></i>Add pallet
    </button>
  </div>
  <div class="top20">
    <nz-table #nzTable nzBordered="true"
      [nzData]="listOfData"
      [nzHideOnSinglePage]="true"
      [nzNoResult]="tplNoData"
      [nzSize]="'default'">
      <ng-template #tplNoData>
        <div class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
      </ng-template>
      <thead>
        <tr>
          <th>Pallet name</th>
          <th>Pallet label</th>
          <th>Weight</th>
          <th>DIM</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of nzTable.data; let i = index">
          <td>
            {{ item.name || 'N/A' }}
          </td>
          <td>
            <div *ngIf="item.label">{{ item.label }}</div>
            <div *ngIf="!item.label">
              <nz-tag nzColor="gold">To label</nz-tag>
            </div>
          </td>
          <td>
            <div *ngIf="item.weight">{{ getWeightValue(item) }}</div>
            <div *ngIf="!item.weight">
              <nz-tag nzColor="gold">To weight</nz-tag>
            </div>
          </td>
          <td>
            <div *ngIf="getDimValue(item)">{{ getDimValue(item) }}</div>
            <div *ngIf="!getDimValue(item)">
              <nz-tag nzColor="gold">To DIM</nz-tag>
            </div>
          </td>
          <td>
            <div>
              <span class="btn-edit" (click)="onBtnEditPallet(item)">Edit</span>
              <span class="btn-delete" (click)="onBtnRemovePallet(item)">Remove</span>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>

