<div class="location-settings">
  <div class="row-item"><nz-switch nzSize="small" [(ngModel)]="scanBarcodeRequired"></nz-switch> Scan Barcode Required</div>
  <div class="row-item"><nz-switch nzSize="small" [(ngModel)]="isAllowSearchBarcode"></nz-switch> Allow Search Barcode</div>
  <div class="row-item">
    <div>
      <nz-switch nzSize="small" [(ngModel)]="photoRequired"></nz-switch> POD Required
    </div>
    <nz-radio-group [(ngModel)]="podAtLocationLevel" class="radio-group">
      <label class="radio-item" nz-radio [nzValue]="false">Shipment Level</label>
      <label class="radio-item" nz-radio [nzValue]="true">Stop Level</label>
    </nz-radio-group>
  </div>
  <!-- <div class="row-item"><nz-switch nzSize="small" [(ngModel)]="podAtLocationLevel"></nz-switch> POD At Location Level</div> -->
  <div class="row-item"><nz-switch nzSize="small" [(ngModel)]="bolRequired"></nz-switch> BOL Required</div>
  <div class="row-item"><nz-switch nzSize="small" [(ngModel)]="signatureRequired"></nz-switch> Signature Required</div>
  <div class="row-item">
    <div>Geofencing feature</div>
    <nz-radio-group [(ngModel)]="useGeoFencing" class="radio-group">
      <label class="radio-item" nz-radio [nzValue]="">No</label>
      <label class="radio-item" nz-radio [nzValue]="useGeoFencingType.force">Force</label>
      <label class="radio-item" nz-radio [nzValue]="useGeoFencingType.alert">Alert</label>
    </nz-radio-group>
  </div>
  <div class="row-item"><nz-switch nzSize="small" [(ngModel)]="isIdVerificationRequired"></nz-switch> ID Verification</div>

  <div class="row-item"><nz-switch nzSize="small" [(ngModel)]="isAllowPickupMoreItems"></nz-switch> Allow driver to go with more items</div>
  <div class="row-item"><nz-switch nzSize="small" [(ngModel)]="isAllowPickupLessItems"></nz-switch> Allow driver to go with less items</div>
</div>
