<ng-template [nzModalTitle]>
  <div class="flex-space-between component-header">
    <div>Storage Fee</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>

<div>
  <form [formGroup]="formInput" class="form-detail" nz-form>
    <div nz-row [nzGutter]="[32, 32]">
      <div nz-col [nzSpan]="12">
        <div class="group-form">
          <div class="group-item">
            <div class="form-label-v2">
              {{ getLabel("unit") }}
              <span *ngIf="isRequired('unit')" class="label-mark-required"></span>
            </div>
            <div class="top10">
              <nz-form-item>
                <nz-form-control>
                  <nz-select nzBackdrop="true" 
                    nzAllowClear nzShowSearch
                    [formControlName]="'unit'"
                    [nzPlaceHolder]="getPlaceHolder('unit')"
                    class="full-w"
                  >
                    <nz-option *ngFor="let item of units" 
                      [nzValue]="item" [nzLabel]="getUnitName(item)">
                    </nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div class="group-item">
            <div class="form-label-v2">
              {{ getLabel("price") }}
              <span *ngIf="isRequired('price')" class="label-mark-required"></span>
            </div>
            <div class="top10">
              <nz-form-item>
                <nz-form-control [nzErrorTip]="getStartingPriceErrorMsg()" [nzValidateStatus]="getStartingPriceControl()">
                  <nz-input-group nzPrefix="$">
                    <input 
                      [max]="999999" [min]="0.01" [step]="0.1" 
                      [type]="getInputType('price')" 
                      class="full-w" nz-input
                      formControlName="price" 
                      [placeholder]="getPlaceHolder('price')"
                    />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div class="group-item">
            <ng-container *ngFor="let groupKey of ['tiers'];" [formArrayName]="groupKey">
              <form nz-form *ngFor="let item of getArrayControls(groupKey); let i = index" [formGroupName]="i">
                <div class="tdm-row cell-spacing">
                  <div class="tdm-col tdm-col-2" *ngFor="let key of ['day', 'price']">
                    <div [ngClass]="{'price': key === 'price' && countTiers > 0}">
                      <div class="form-label-v2">
                        {{getLabel(fullKey(groupKey, key))}}
                        <span *ngIf="isRequired(fullKey(groupKey, key))" class="label-mark-required"></span>
                      </div>
                      <nz-form-item>
                        <ng-container *ngIf="key === 'price'">
                          <nz-form-control 
                            [nzErrorTip]="getPriceErrorMsg(item)"
                            [nzValidateStatus]="getPriceControl(item)"
                          >
                            <nz-input-group nzPrefix="$">
                              <input nz-input [formControlName]="key"
                                [type]="getInputType(fullKey(groupKey, key))"
                                [placeholder]="getPlaceHolder(fullKey(groupKey, key))"
                                (input)="onInputChanged($event, key)"
                                (keypress)="onInputKeyPress($event, key)"
                              />
                            </nz-input-group>
                          </nz-form-control>
                        </ng-container>

                        <ng-container *ngIf="key == 'day'">
                          <nz-form-control
                            [nzErrorTip]="getFromDayErrorMsg(item, i)"
                            [nzValidateStatus]="getFromDayControl(item)"
                          >
                            <input nz-input [formControlName]="key"
                              [type]="getInputType(fullKey(groupKey, key))"
                              [placeholder]="getPlaceHolder(fullKey(groupKey, key))"
                              (input)="onInputChanged($event, key)"
                              (keypress)="onInputKeyPress($event, key)"
                            />
                          </nz-form-control>
                        </ng-container>

                        <div class="group-button" *ngIf="key === 'price'">
                          <ng-container *ngIf="countTiers > 0">
                            <span
                              nz-icon nzType="delete"
                              nzTheme="twotone" [nzTwotoneColor]="'#CD4246'"
                              (click)="removeItemInFormArray(groupKey, i)"
                            ></span>
                          </ng-container>
                        </div>
                      </nz-form-item>
                    </div>
                  </div>
                </div>
              </form>
            </ng-container>
          </div>

          <div class="group-item">
            <button nz-button nzType="dashed" (click)="addItemToFormArray('tiers')">
              <span nz-icon nzType="plus"></span>
              Add tier
            </button>
          </div>

        </div>
      </div>

      <div nz-col [nzSpan]="12">
        <div class="group-explanation">
          <div class="title">Live Explanation</div>
          <div class="content" [innerHTML]="liveExplanationText"></div>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template [nzModalFooter]>
  <div class="group-footer">
    <div (onCancel)="closeDialog()" (onOK)="onSubmit()" 
      [canClickCancel]="!onProgress"
      [canClickOK]="needUpdate && !onProgress" 
      [labelOK]="isEdit ? 'Update' : 'Create'" 
      [nzIconCancel]="null" [nzIconOK]="null"
      [onProgress]="onProgress" form-footer></div>
  </div>
</ng-template>