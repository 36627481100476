import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Utils } from "@services/utils";

@Component({
  selector: "copy-text",
  template: `
    <span class="clickable" (click)="onCopyText()" (mouseleave)="onMouseLeave()">
      <i nz-icon [nzType]="icon" nzTheme="outline" nz-tooltip="{{ displayTooltip }}"></i>
    </span>
  `,
})
export class CopyTextComponent extends BaseComponent {
  @Input("text") copyText: string;
  @Input("tooltip") tooltip: string = "Copy URL";
  @Input("icon") icon: string = 'link';

  displayTooltip: string = '';
  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.displayTooltip = this.tooltip;
  }

  onCopyText() {
    Utils.copyTextToClipboard(this.copyText, (e) => {
      if (e) {
        this.displayTooltip = "Cannot copy text to clipboard";
      } else {
        this.displayTooltip = "Copied";
      }
    });
  }

  onMouseLeave() {
    this.displayTooltip = this.tooltip;
  }
}
