import { Component } from "@angular/core";
import { DateUtil } from "@services/date-utils";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';

@Component({
  selector: '[comm-log]',
  templateUrl: './index.html',
  styleUrls: ['../../../dialogs/dialogs.scss', './index.scss']
})

export class CommLogList extends BaseFormDialog1 {
  public isLoading: boolean = false;
  public isError: boolean = false;
  private timezone: string = DateUtil.getLocalTimeZone();
  public selectedId = '';
  private statuses = Const.CommLogStatuses;

  ngOnInit(): void {
    this.selectedId = this.model.histories[0]?.logId;
  }

  public getType(type) {
    return type === 'email' ? 'Email' : 'SMS';
  }

  public getReceiver(data) {
    return data.type === 'email' ? data.email : data.phone;
  }

  $asSentStatusText = (item) => {
    let { status, type } = item;

    switch (status.toLowerCase()) {
      case 'pending':
        return 'Pending';
      case 'sent':
      case 'unknown':
        return 'Sent';
      case 'failed':
        return 'Failed';
      case 'success':
        return 'Success';
      case 'delivered':
        return 'Delivered';
      case 'opened':
        return 'Opened';
      case 'clicked':
        return 'Clicked';
      case 'unsubscribed':
        return 'Unsubscribed';
      case 'undelivered':
        return 'Undelivered';
      case 'invalid':
        return 'Invalid';
      case 'unreachable':
        return 'Unreachable';
      default:
        return status;
    }
  };

  makeColor(item) {
    let { status, type } = item;
    switch (status.toLowerCase()) {
      case 'pending':
        return 'gray';
      case 'sent':
      case 'success':
      case 'unknown':
        return 'green';
      case 'failed':
      case 'invalid':
      case 'unsubscribed':
        return 'red';
      case 'delivered':
      case 'opened':
      case 'clicked':
        return 'blue';
      case 'undelivered':
      case 'unreachable':
        return 'orange';
      default:
        return '';
    }
  }

  chooseItem(logId: string) {
    this.selectedId = logId;
  }

  formatDate(time: string) {
    return DateUtil.displayTimeWindow({ from: time, to: time }, {
      timezone: this.timezone,
      formatDateOnly: 'MM/DD/YY',
      format: "MM/DD/YY h:mm A",
    });
  }
}
