import { Component, Input } from "@angular/core";
import { DataModelManifest, FormDataShipmentManifestDraftData } from "@wearewarp/types/rest-api/admin/form-data/shipment-manifest";
import { BaseComponent } from "@abstract/BaseComponent";
import { Const } from "@const/Const";
import { BizUtil } from "@services/biz";
import { OrderUtil } from "@services/order";
import { MasterData } from "@services/master.data";
import { FormDataShipmentLocation } from "@wearewarp/types/rest-api/admin/form-data/shipment-entry";
import { DateUtil } from "@services/date-utils";
import { Utils } from "@services/utils";
import { Contact } from "@wearewarp/types/data-model";
import { DeliveryMap } from "@app/admin/components/map";
@Component({
  selector: "[shipment-manifest-info-summary]",
  templateUrl: "./view.html",
  styleUrls: ["./style.scss"],
})
export class ShipmentManifestInfoSummary extends BaseComponent {

  map: DeliveryMap;
  deliveryInfos: Array<FormDataShipmentLocation> = [];
  public displayInfo: any = {}
  private _modelDraft: FormDataShipmentManifestDraftData;
  @Input() get modelDraft(): FormDataShipmentManifestDraftData { return this._modelDraft};
  set modelDraft(value) {
    if (!value) return;
    this._modelDraft = value;
    if (this.modelDraft.items?.pallets?.length) {
      this.displayInfo.numOfPallets = this.modelDraft.items.pallets.length;
    }
    if (this.modelDraft.basicInfo?.shipmentType) {
      this.displayInfo.shipmentType = OrderUtil.getShipmentTypeName(this.modelDraft.basicInfo.shipmentType);
    }
    if (this.modelDraft.basicInfo?.vehicleType?.name) {
      this.displayInfo.vehicleTypeName = BizUtil.getVehicleName(this.modelDraft.basicInfo.vehicleType);
    }
    if (this.modelDraft.basicInfo?.clientId) {
      this.displayInfo.clientId = this.modelDraft.basicInfo.clientId;
      if (!this.displayInfo.clientName) {
        this.fetchClientInfo();
      }
    }
    let deliveryInfos = [];
    if (this.modelDraft.pickInfo) {
      deliveryInfos.push(this.modelDraft?.pickInfo);
      this.displayInfo.pickLocationName = this.modelDraft.pickInfo.locationName;
      this.displayInfo.pickAddr = MasterData.getAddressText(this.modelDraft.pickInfo.addr);
      this.displayInfo.pickWindowTime = this.getTimePeriods(this.modelDraft.pickInfo);
    }
    if (this.modelDraft.dropInfo) {
      deliveryInfos.push(this.modelDraft?.dropInfo);
      this.displayInfo.dropLocationName = this.modelDraft.dropInfo.locationName;
      this.displayInfo.dropAddr = MasterData.getAddressText(this.modelDraft.dropInfo.addr);
      this.displayInfo.dropWindowTime = this.getTimePeriods(this.modelDraft.dropInfo);
    }
    if (this.modelDraft.thirdPartyCarrierInfo) {
      this.displayInfo.thirdPartyCarrierName = this.modelDraft.thirdPartyCarrierInfo.carrierName;
      let primaryContact = (this.modelDraft.thirdPartyCarrierInfo?.contacts ?? []).find(it => it.type == Const.ContactType.primary);
      if (primaryContact) {
        this.displayInfo.thirdPartyContactName = this.getFullName(primaryContact);
        let phone = this.displayPhone(primaryContact.phone);
        if (primaryContact.phoneExtension) {
          phone = `${phone} Ext ${primaryContact.phoneExtension}`;
        }
        this.displayInfo.thirdPartyContactPhone = phone;
        this.displayInfo.thirdPartyContactEmail = primaryContact.email;
      }
    }
    this.setDeliveryInfos(deliveryInfos);
  }

  private _modelManifest: DataModelManifest;
  @Input() get modelManifest(): DataModelManifest { return this._modelManifest};
  set modelManifest(value) {
    this._modelManifest = value;
    this.displayInfo.createMode = value.createMode;
  }

  setDeliveryInfos(data: Array<FormDataShipmentLocation>) {
    this.deliveryInfos = data;

    this.map?.loadShipments([{ deliveryInfos: this.deliveryInfos }])
    this.map?.refresh()
    this.map?.fitBoundsToShipment()

  }

  private fetchClientInfo() {
    let clientId = this.displayInfo.clientId;
    if (!clientId) return;
    let url = `${Const.APIURI_CLIENTS}/${clientId}`;
    this.api.GET(url).subscribe(
      resp => {
        this.displayInfo.clientName = resp.data?.name;
      }
    );
  }

  getTimePeriods(info: FormDataShipmentLocation) {
    let windows = info?.windows
      ?.map((window) =>
        DateUtil.displayTimeWindow(window, {
          timezone: info?.addr?.metadata?.timeZoneStandard,
          format: "MM/DD/YYYY h:mm A",
        })
      );
    if (Utils.isArrayNotEmpty(windows)) return windows.join("\n");
    else if (info.requiresAppointment) return 'Requires Appointment.';
    else return '';
  }

  getFullName(contact: Contact) {
    if (!contact) return "N/A";
    if (contact?.fullName) return contact?.fullName;
    if (contact?.firstName || contact?.lastName) {
      return `${contact?.firstName == "N/A" ? "" : contact?.firstName} ${contact?.lastName == "N/A" ? "" : contact?.lastName}`;
    }
    return "N/A";
  }

  onMapChange() {
    if (this.deliveryInfos?.length && this.map) {
      setTimeout(() => {
        this.map?.loadShipments([{ deliveryInfos: this.deliveryInfos }])
        this.map?.refresh()
        this.map?.fitBoundsToShipment()  
      }, 10)
    }
  }

}
