import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Const } from "@const/Const";
import { WarehouseForm } from "@app/admin/warehouses/warehouse-form";
import { DialogService } from "@dialogs/dialog.service";
import { BaseFormItem } from "@app/admin/base/form-item";
import { Utils } from "@services/utils";
import { ImageUtil } from "@services/image-util";
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: "[client-detail-logo]",
  templateUrl: "./index.html",
  styleUrls: [
    "./index.scss",
    "../../../../detail.scss",
    "../../../../../../styles/row-col.scss",
    "../../../../../../styles/form-v2.scss",
  ],
})
// Customer Information
export class ClientDetailLogo extends BaseFormItem {
  protected formGroupDeclaration: FormGroupDeclaration = {
    logos: {label: '', type: 'formGroup', childItem: {
      logoLightMode: {label: 'Logo Light Mode', required: false},
      logoDarkMode: {label: 'Logo Dark Mode', required: false}
    }},
  };

  previews = {
    logoLightMode: null,
    logoDarkMode: null
  };

  imageFile: any = {
    logoLightMode: null,
    logoDarkMode: null
  };
  isImageCropped: boolean = false;
  croppedImage: any = {
    logoLightMode: null,
    logoDarkMode: null
  };
  listAspectRatio = [
    {label: "Square (1:1)", value: 1 / 1},
    {label: "Landscape (2.40:1)", value: 2.40 / 1},
    {label: "Landscape (2.95:1)", value: 2.95 / 1},
    {label: "Free", value: 0}
  ]
  aspectRatios = {
    logoLightMode: 0,
    logoDarkMode: 0
  }
  maintainAspectRatio = {
    logoLightMode: false,
    logoDarkMode: false
  }
  scale = {
    logoLightMode: 0,
    logoDarkMode: 0,
  };
  transform = {
    logoLightMode: <ImageTransform>{
      scale: 1,
      translateUnit: 'px',
    },
    logoDarkMode: <ImageTransform>{
      scale: 1,
      translateUnit: 'px',
    }
  }

  private originModel;
  @Input() set myModel(value) {
    let currentModel = this.model;
    this.model = value;
    this.originModel = {...value};
    if(currentModel) this.bindDataModel(value);
    this.getImageLogo();
  }
  @Output() onSaveCustomerLogo: EventEmitter<any> = new EventEmitter<any>();

  get needUpdate() {
    if(this.isImageCropped) return true;
    return super.needUpdate;
  }

  disableEditing() {
    this.setEnableFormGroup(false);
    this.isEditing = false;
  }

  constructor(
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.disableEditing();
  }

  protected getApiUrl(): string {
    return Const.APIURI_CLIENTS;
  }

  protected onUpdateSuccess(resp) {
    super.onUpdateSuccess(resp);
    this.disableEditing();
    this.originModel = {...this.model};
    this.imageFile = {
      logoLightMode: null,
      logoDarkMode: null
    };
    this.getImageLogo();
  }

  protected get crudEntity(): string {
    return "clients";
  }

  onBtnCancel() {
    this.myModel = {...this.originModel};
    this.createFormInput(this.model);
    this.disableEditing();
    this.isImageCropped = false;
    this.getImageLogo();
  }

  isInputsChange() {
    return this.isFormDataChanged();
  }

  onFileSelected(inputElement: HTMLInputElement, key: string) {
    let file = inputElement.files[0];
    inputElement.value = "";
    const MAX_SIZE = 1024 * 1024 * 2;     // 1MiB
    if(file.size > MAX_SIZE) {
      this.showErr(`Please select an image file with a maximum size of ${MAX_SIZE/(1024 * 1024) }MB.`);
      return;
    }
    this.imageFile[key] = file;
    const obj = {
      file : file,
      fileName: file.name
    }
    this.setItemValue(`logos.${key}`, obj);
    this.resetScale();
  }

  onBtnDelImage(key) {
    this.confirmDeletion({
      message: "Are you sure you want to delete this logo?",
      txtBtnOk: this.txtDelete,
      fnOk: async () => {
        this.setItemValue(`logos.${key}`, null);
        this.previews[key] = null;
        this.imageFile[key] = null;
        this.fileToUpload[key] = null;
      }
    });
  }

  viewImageItem(imgUrl) {
    if (!imgUrl) return;
    DialogService.previewImgs([imgUrl], 0);
  }

  imageCropped(event: ImageCroppedEvent, key) {
    this.isImageCropped = true;
    this.croppedImage[key] = event;
    this.croppedImage[key]['url'] = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
    this.fileToUpload[key] = new File([event.blob], this.getItemValue(`logos.${key}`).fileName || "image.jpeg", { type: "image/jpeg", });
    const obj = {
      file : this.fileToUpload[key],
      fileName: this.fileToUpload[key].name,
      id: Utils.isObject(this.getItemValue(`logos.${key}`)) ? '' : this.getItemValue(`logos.${key}`)
    }
    this.setItemValue(`logos.${key}`, obj);
  }

  cropImage(key) {
    this.imageFile[key] = this.fileToUpload[key];
    this.previews[key] = this.croppedImage[key]['url'];
    this.resetScale();
  }

  downloadImage(attachedFile, key) {
    let id = attachedFile.id || attachedFile._id;
    let url = `${Const.APIURI_DOWNLOAD}/${id}`;
    this.api.download(url).subscribe(
      resp => {
        let blob = new Blob([resp], { type: attachedFile.type });
        this.imageFile[key] = blob;
      }, err => {
        this.showErr(err);
      }
    )
  }

  getImageLogo() {
    if(Utils.isObjectNotEmpty(this.model?.logoImages)) {
      for (let key in this.model.logoImages) {
        this.previews[key] = this.attachedFileUrl(this.model.logoImages[key]);
        this.downloadImage(this.model.logoImages[key], key);
      }
    } else {
      this.imageFile = {
        logoLightMode: null,
        logoDarkMode: null
      };
    }
  }

  onChangeScale(key, value){
    this.scale[key] = value;
    this.transform[key] = {
        ...this.transform[key],
        scale:  1 + this.scale[key] * .1
    };
  }

  resetScale() {
    for(let key of ['logoLightMode', 'logoDarkMode']) {
      this.scale[key] = 0;
      this.transform[key] = {
        ...this.transform[key],
        scale:  1
      };
    }
  }

  onChangeAspectRatios(key, value) {
    this.aspectRatios[key] = value;
    this.maintainAspectRatio[key] = value == 0 ? false : true;
  }

  onBtnSave() {
    if (this.isCreateNew) {
      const logos = this.getItemValue('logos');
      const data = {
        logos: logos,
        files: this.fileToUpload
      }
      this.onSaveCustomerLogo.emit(data);
      this.disableEditing();
    }
    else {
      super.onBtnSave();
    }
  }
}
