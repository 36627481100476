import { Component, Input } from '@angular/core';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import { Const } from '@const/Const';
import { FormEditRate } from '@app/admin/crossdock/rate-configuration/edit-rate/FormEditRate';
import { Validators } from '@angular/forms';
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { RateAdjustmentType, RateAdjustmentUnit } from '@wearewarp/types';

@Component({
  selector: '[warehouse-edit-rate-adjustment]', templateUrl: './view.html', styleUrls: ['index.scss']
})
export class EditRateAdjustment extends BaseFormDialog1<FormEditRate> {
  @Input() warehouseId: string;
  protected formGroupDeclaration: FormGroupDeclaration = {
    name: { label: 'Fee name', type: 'string', inputType: 'text', placeHolder: 'Enter Fee Name', notAcceptEmpty: true, required: true },
    feeType: { label: 'Fee Type', required: true, placeHolder: 'Select Fee Type' },
    unit: { label: 'UoM', required: true, initialValue: WarpConst.RateAdjustmentUnit.pallet },
    price: {
      label: 'Unit price',
      type: 'number',
      inputType: 'number',
      notAcceptEmpty: true,
      required: true,
      placeHolder: '0.00',
      validators: [Validators.min(0.01), Validators.maxLength(6)]
    },
    triggerCode: { label: 'Trigger', required: true, placeHolder: 'Select shipment status', readOnly: true, submitReadOnly: true },
  };

  feeTypes = Object.values(WarpConst.RateAdjustmentTypes);
  units = Object.values(WarpConst.RateAdjustmentUnit);
  triggers = [WarpConst.WarehouseJobStatus.arrivedAtWarehouse, WarpConst.WarehouseJobStatus.checkedOut];

  get shouldCreateFormImmediately() {
    return true;
  }

  get headerText() {
    return `Edit ${this.model?.name}`;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getFeeTypeName(type: RateAdjustmentType): string {
    const types = {
      inbound_fee: 'Inbound Fee',
      outbound_fee: 'Outbound Fee'
    }
    return types[type] || ''
  }

  onChangeFeeType(val: RateAdjustmentType | null) {
    if(!val) this.setItemValue('triggerCode', null);
    if(val === WarpConst.RateAdjustmentTypes.inbound_fee) {
      this.setItemValue('triggerCode', WarpConst.RateAdjustmentTriggerCodes.arrivedAtWarehouse)
    }
    if(val === WarpConst.RateAdjustmentTypes.outbound_fee) {
      this.setItemValue('triggerCode', WarpConst.RateAdjustmentTriggerCodes.checkedOut)
    }
  }

  getUnitName(unit: RateAdjustmentUnit): string {
    const units = {
      pallet: 'Pallet',
      case: 'Case',
      pack: 'Pack',
      piece: 'Piece'
    }
    return units[unit] || ''
  }

  getTriggerName(status: string): string {
    const statues = {
      arrivedAtWarehouse: 'Arrived At Warehouse',
      checkedOut: 'Checked Out'
    }
    return statues[status] || ''
  }

  clickEdit() {
    if (!this.warehouseId) {
      this.showErr('Missing warehouseId!');
      return;
    }
    this.onProgress = true;
    const url = Const.API_WAREHOUSE_MANAGEMENT(`rate_adjustments/${this.model?.id}`);
    const body = this.getFormData_JSON(true);
    const options = { customHeaders: { warehouseId: this.warehouseId } };
    this.api.PUT(url, body, options).subscribe(resp => {
      this.onProgress = false;
      this.onUpdateSuccess({ ...this.model, ...body });
    }, err => {
      this.showErr(err);
      this.onProgress = false;
    });
  }
}
