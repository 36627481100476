import { BaseComponent } from '@abstract/BaseComponent';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogService } from '@dialogs/dialog.service';
import { ImageUtil } from '@services/image-util';
import { Utils } from '@services/utils';
import { AttachedFile } from '@wearewarp/types/data-model';

@Component({
  selector: 'file-tool',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent extends BaseComponent {
  @Input() fileName: string;
  private _file: AttachedFile;
  @Input() set file(value: AttachedFile){
    if(value){
      this._file = value;
      this.isUploading = false;
    }
  };
  get file(){
    return this._file;
  }
  @Input() uploadTxt: string = 'Upload';
  @Output() onUpload: EventEmitter<any> = new EventEmitter();
  public isUploading: boolean = false;
  public uid: string;
  constructor() {
    super();
    this.uid = Utils.generateULID();
  }

  ngOnInit(): void {
  }

  downloadInvoice(){
    if(this.file) this.downloadAttachedFile(this.file);
  }

  onViewInvoice(){
    if(this.file) DialogService.preview([this.file], 0)
  }

  onClickAddFile(){
    let element: HTMLElement;
    element = document.getElementById(`input-${this.uid}`) as HTMLElement;
    element.click()
  }

  onFileSelected(inputElement: HTMLInputElement) {
    let file = inputElement.files[0];
    inputElement.value = "";
    this.isUploading = true;
    ImageUtil.resizeImageIfNeeded(file).then((blob) =>{
      this.onUpload.emit({file: blob, filename: blob.name});
    });
  }
}
