<ng-container *ngIf="!model?.type">
  <div class="flex" style="align-items: center;">
    <div class="grey right5 pointer" (click)="onClickBtn('')">Click to rate</div>
    <div class="rate-icon pointer hover like" (click)="onClickBtn('like')"></div>
    <div class="rate-icon pointer hover dislike" (click)="onClickBtn('dislike')"></div>
  </div>
</ng-container>
<ng-container *ngIf="model?.type">
  <div class="flex">
    <div class="rate-icon like" [ngClass]="{'active': isLike}"
      nz-popover 
      nzPopoverTitle="Information"
      [nzPopoverContent]="tplReviewInfo"
      [nzPopoverTrigger]="isLike && isShowPopover ? 'hover': null">
    </div>
    <div class="rate-icon dislike" [ngClass]="{'active': isDislike}"
      nz-popover 
      nzPopoverTitle="Information"
      [nzPopoverContent]="tplReviewInfo"
      [nzPopoverTrigger]="isDislike && isShowPopover ? 'hover': null">
    </div>
    <div class="rate-icon pointer edit-btn" (click)="onClickBtn('')">Edit</div>
    <ng-template #tplReviewInfo>
      <div>
        <b>Note:</b> {{ model?.note || 'N/A'}}
      </div>
      <div *ngIf="model?.whenBy" class="top5">
        <warp-when-by [model]="model?.whenBy" [viewTemplate]="tplReviewWhenBy"></warp-when-by>
      </div>
    </ng-template>
  </div>
</ng-container>

<ng-template #tplReviewWhenBy let-hasData="hasData" let-displayInfo="displayInfo">
  <span *ngIf="hasData" class="grey f12" style="font-style: italic">Reviewed {{displayInfo.createdWhen}} by
    <a [routerLink]="[displayInfo.hyperLinkCreatedBy]" 
      class="grey"
      target="_blank">
      {{displayInfo.createdBy}}</a> 
  </span>
</ng-template>

