import { TabContentComponent } from "@app/admin/base/tabs/interface";
import { AfterViewInit, Directive, OnDestroy, OnInit } from "@angular/core";
import { ShipmentManifestContext } from "../../interface";
import { DataModelManifest, FormDataShipmentManifestDraftData } from "@wearewarp/types/rest-api/admin/form-data/shipment-manifest";

@Directive()
export class ShipmentManifestTabContent implements TabContentComponent, OnInit, OnDestroy, AfterViewInit {
  private _id: string;
  private _context: any;

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

  setId(id: string) {
    this._id = id;
  }

  get id(): string {
    return this._id;
  }

  setContext(context: any) {
    this._context = context;;
  }

  get context(): ShipmentManifestContext {
    return this._context;
  }

  get manifestId(): string {
    return this.modelManifest?.manifestId;
  }

  get modelDraft(): FormDataShipmentManifestDraftData | undefined {
    return this.context?.modelDraft;
  }

  get modelManifest(): DataModelManifest | undefined {
    return this.context?.modelManifest;
  }

  onTabActive(active: boolean) {
  }

}