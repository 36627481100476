import { Utils } from "@services/utils";
import { MasterData } from "@services/master.data";
import { 
  FormDataManifestItems, 
  FormDataShipmentManifestDraftData, 
  FormDataShipmentThirdPartyCarrierInfo, 
  FormDataShipmentManifestBasicInfo, 
  FormDataShipmentManifestRevenue 
} from "@wearewarp/types/rest-api/admin/form-data/shipment-manifest";
import { ShipmentManifestFormId } from "../enum";
import { FormDataShipmentLocation } from "@wearewarp/types/rest-api/admin/form-data/shipment-entry";
import { ShipmentManifestCreateMode } from "@app/enum";

export class ShipmentManifestBuilder {
  private draftData: FormDataShipmentManifestDraftData;

  constructor(draftData: FormDataShipmentManifestDraftData) {
    this.draftData = draftData;
  }

  setDraftData(data: FormDataShipmentManifestDraftData) {
    this.draftData = data;
  }

  private generateIdIfNeeded(item) {
    if (!item.id) {
      item.id = Utils.generateULID();
    }
  }

  private buildDraftWarpCarrier(formData: any, formId: ShipmentManifestFormId): FormDataShipmentManifestDraftData {
    let draft: FormDataShipmentManifestDraftData = Object.assign({}, this.draftData ?? {});
    switch (formId) {
      case ShipmentManifestFormId.warpCarrierItems:
        draft.items = <FormDataManifestItems>formData;
        break;
      case ShipmentManifestFormId.warpCarrierBasicInfo:
        draft.basicInfo = <FormDataShipmentManifestBasicInfo>formData;
        break;
      case ShipmentManifestFormId.warpCarrierPickup:
        draft.pickInfo = <FormDataShipmentLocation>formData;
        this.generateIdIfNeeded(draft.pickInfo);
        break;
      case ShipmentManifestFormId.warpCarrierDropoff:
        draft.dropInfo = <FormDataShipmentLocation>formData;
        this.generateIdIfNeeded(draft.dropInfo);
        break;
      case ShipmentManifestFormId.warpCarrierRevenue:
        let _formData = <FormDataShipmentManifestRevenue>formData;
        draft.cost = _formData.cost;
        if (Utils.isBoolean(_formData.settings?.isDoNotInvoice)) {
          if (!Utils.isObject(draft.settings)) draft.settings = {};
          draft.settings.isDoNotInvoice = _formData.settings.isDoNotInvoice;
        }
        draft = this.updateServiceOption_FromSingleRevenue(draft);
        break;
    }
    draft.shipmentManifestMode = ShipmentManifestCreateMode.warpCarrier;
    this.draftData = draft;
    return draft;
  }

  private buildDraftThirdPartyCarrier(formData: any, formId: ShipmentManifestFormId): FormDataShipmentManifestDraftData {
    let draft: FormDataShipmentManifestDraftData = Object.assign({}, this.draftData ?? {});
    switch (formId) {
      case ShipmentManifestFormId.thirdPartyCarrierInfo:
        draft.thirdPartyCarrierInfo = <FormDataShipmentThirdPartyCarrierInfo>formData;
        break;
      case ShipmentManifestFormId.thirdPartyCarrierItems:
        draft.items = <FormDataManifestItems>formData;
        break;
      case ShipmentManifestFormId.thirdPartyCarrierVehicle:
        draft.basicInfo = <FormDataShipmentManifestBasicInfo>formData;
        break;
      case ShipmentManifestFormId.thirdPartyCarrierPickup:
        draft.pickInfo = <FormDataShipmentLocation>formData;
        this.generateIdIfNeeded(draft.pickInfo);
        break;
      case ShipmentManifestFormId.thirdPartyCarrierDropoff:
        draft.dropInfo = <FormDataShipmentLocation>formData;
        this.generateIdIfNeeded(draft.dropInfo);
        break;
      case ShipmentManifestFormId.thirdPartyCarrierRevenue:
        let _formData = <FormDataShipmentManifestRevenue>formData;
        draft.cost = _formData.cost;
        if (Utils.isBoolean(_formData.settings?.isDoNotInvoice)) {
          if (!Utils.isObject(draft.settings)) draft.settings = {};
          draft.settings.isDoNotInvoice = _formData.settings.isDoNotInvoice;
        }
        draft = this.updateServiceOption_FromSingleRevenue(draft);
        break;
    }
    draft.shipmentManifestMode = ShipmentManifestCreateMode.thirdPartyCarrier;
    this.draftData = draft;
    return draft;
  }

  public buildDraftData(formData: any, formId: string): FormDataShipmentManifestDraftData {
    let _formId = <ShipmentManifestFormId>formId;
    switch (_formId) {
      case ShipmentManifestFormId.warpCarrierItems:
      case ShipmentManifestFormId.warpCarrierBasicInfo:
      case ShipmentManifestFormId.warpCarrierPickup:
      case ShipmentManifestFormId.warpCarrierDropoff:
      case ShipmentManifestFormId.warpCarrierRevenue:
        return this.buildDraftWarpCarrier(formData, _formId);
      case ShipmentManifestFormId.thirdPartyCarrierInfo:
      case ShipmentManifestFormId.thirdPartyCarrierItems:
      case ShipmentManifestFormId.thirdPartyCarrierVehicle:
      case ShipmentManifestFormId.thirdPartyCarrierPickup:
      case ShipmentManifestFormId.thirdPartyCarrierDropoff:
      case ShipmentManifestFormId.thirdPartyCarrierRevenue:
        return this.buildDraftThirdPartyCarrier(formData, _formId);
      default:
        throw Error(`Unknown formId ${formId}`);
    }
  }

  // Update serviceOption ở delivery khi thay đổi serviceOption ở cost
  private updateServiceOption_FromSingleRevenue(draftData: FormDataShipmentManifestDraftData): FormDataShipmentManifestDraftData {
    const serviceOptionsCost = draftData.cost?.serviceOptions || [];
    let pickInfo = draftData.pickInfo;
    let dropInfo = draftData.dropInfo;
    if (!pickInfo || !dropInfo) return draftData;
    let pickArr = [];
    let dropArr = [];
    for (let item of serviceOptionsCost) {
      let option = MasterData.getServiceOptionById(item._id);
      if (!option) continue;
      if (option.type == 'pickup') {
        pickArr.push(option._id);
      } else if (option.type == 'delivery') {
        dropArr.push(option._id);
      }
    }
    pickInfo.serviceOptions = pickArr;
    dropInfo.serviceOptions = dropArr;
    return draftData;
  }

}