import { Component } from '@angular/core';
import { Const } from '@const/Const';
import { BaseDetail } from '@base/detail';
import { DialogService } from '@dialogs/dialog.service';
import { AddRateAdjustment } from '@app/admin/crossdock/rate-configuration/add-rate';
import { EditRateAdjustment } from '@app/admin/crossdock/rate-configuration/edit-rate';
import { XDockDateHelper } from '@app/admin/crossdock/XDockDateHelper';
import { Const as WarpConst } from '@wearewarp/universal-libs';
import { RateAdjustmentType, RateAdjustmentUnit } from '@wearewarp/types';
import { InputHelper } from '@services/input-helper';

@Component({
  selector: '[warehouse-rate-plan-detail]', 
  templateUrl: './index.html', 
  styleUrls: ['./style.scss'],
})
export class WarehouseRatePlanDetail extends BaseDetail {
  public uomTypes = [];
  public adjustmentTypes = [];
  public listAdjustment = [];
  public warehouseId: string;
  public loadingCheckEditDuration = false;
  public canEditDuration = false;
  private allDetailDataLoaded = false;
  private isRefresh = false;

  get warehouseTimezone() {
    return this.model?.metadata?.warehouse?.timeZone;
  }

  get timezoneText() {
    return XDockDateHelper.timezoneText(this.warehouseTimezone);
  }

  get warehouseName() {
    return this.model?.metadata?.warehouse?.name;
  }

  get clientName() {
    return this.model?.metadata?.client?.name;
  }

  get isExpired() {
    return this.model?.status && this.model?.status === WarpConst.RatePlanStatus.expired;
  }

  get allDataLoaded() {
    return !this.onProgress && this.allDetailDataLoaded && this.model;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  public getDurationText() {
    return XDockDateHelper.getDurationText(this.model?.startDate, this.model?.endDate, this.warehouseTimezone);
  }

  public statusText() {
    switch (this.model?.status) {
      case WarpConst.RatePlanStatus.apply_soon:
        return 'Apply Soon';
      case WarpConst.RatePlanStatus.applying:
        return 'Applying';
      case WarpConst.RatePlanStatus.expired:
        return 'Expired';
      default:
        return 'Unknown';
    }
  }

  getStatusColor() {
    switch (this.model?.status) {
      case WarpConst.RatePlanStatus.apply_soon:
        return 'warning';
      case WarpConst.RatePlanStatus.applying:
        return 'success';
      case WarpConst.RatePlanStatus.expired:
        return 'default';
      default:
        return 'default';
    }
  }

  get isApplying(): boolean {
    return this.model?.status === WarpConst.RatePlanStatus.applying;
  }

  get isApplySoon(): boolean {
    return this.model?.status === WarpConst.RatePlanStatus.apply_soon;
  }

  clickAddFee(item) {
    DialogService.openFormDialog1(AddRateAdjustment, {
      nzComponentParams: {
        warehouseId: this.warehouseId,
        planId: this.model.id,
        categoryCode: item.value,
        categoryName: item.label,
        closeOnSuccess: true,
        createSuccess: resp => {
          const newRate = resp.data;
          this.addAdjustmentToList(newRate);
        }
      }, nzClassName: 'modal-no-padding warehouse-add-rate-plan-modal',
    });
  }

  getFeeList(typeItem) {
    return this.listAdjustment?.filter(item => item.categoryCode == typeItem.value) || [];
  }

  getStorageRateAdjustment(typeItem) {
    const adjustmentList = this.getFeeList(typeItem);
    return adjustmentList;
  }

  onBtnRefresh() {
    this.isRefresh = true;
    super.onBtnRefresh();
  }

  clickEditFee(feeItem) {
    DialogService.openFormDialog1(EditRateAdjustment, {
      nzComponentParams: {
        model: feeItem,
        warehouseId: this.warehouseId,
        closeOnSuccess: true,
        updateSuccess: resp => {
          feeItem.name = resp.name;
          feeItem.unit = resp.unit;
          feeItem.price = resp.price;
          feeItem.feeType = resp.feeType;
          feeItem.triggerCode = resp.triggerCode;
        }
      }, nzClassName: 'modal-no-padding warehouse-add-rate-plan-modal',
    });
  }

  clickDeleteFee(feeItem) {
    if (!this.warehouseId) {
      this.showErr('Missing warehouseId!');
      return;
    }
    const url = Const.API_WAREHOUSE_MANAGEMENT(`rate_adjustments/${feeItem?.id}`);
    const options = { customHeaders: { warehouseId: this.warehouseId } };
    feeItem.deleting = true;
    this.api.DELETE(url, options).subscribe(
      resp => {
        feeItem.deleting = false;
        this.removeAdjustmentFromList(feeItem);
      }, err => {
        this.showErr(err);
        feeItem.deleting = false;
      }
    );
  }

  protected getApiUrl(): string {
    return Const.APIURI_WAREHOUSE_RATE_PLAN;
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any) {
    super.handleNavigationEnd(url, prevQueryParam);
  }

  protected onGetDetailSuccess(data): any {
    this.warehouseId = data.warehouseId;
    this.fetchDetail(data.id);
    return super.onGetDetailSuccess(data);
  }

  public addAdjustmentToList(item) {
    if (!this.listAdjustment) {
      this.listAdjustment = [item];
    } else {
      this.listAdjustment.push(item);
    }
  }

  private updateAdjustment(updateItem) {
    const index = this.listAdjustment.findIndex(item => item.id === updateItem.id);
    if (index !== -1) {
      this.listAdjustment.splice(index, 1, updateItem);
    }
  }

  public removeAdjustmentFromList(item) {
    const index = this.listAdjustment.indexOf(item);
    if (index !== -1) {
      this.listAdjustment.splice(index, 1);
    }
  }

  private async fetchDetail(planId) {
    if (!this.warehouseId) {
      this.showErr('Missing warehouseId!');
      return;
    }
    this.startProgress();
    try {
      const notFetchUomTypes = this.uomTypes.length > 0 && !this.isRefresh;
      const uomTypesPromise = notFetchUomTypes ? Promise.resolve(this.uomTypes) : this.fetchUomTypes();
      const notFetchAdjustmentTypes = this.adjustmentTypes.length > 0 && !this.isRefresh;
      const adjustmentTypesPromise = notFetchAdjustmentTypes ? Promise.resolve(this.adjustmentTypes) : this.fetchAdjustmentTypes();
      const notFetchAdjustments = this.listAdjustment.length > 0 && !this.isRefresh;
      const listAdjustmentPromise = notFetchAdjustments ? Promise.resolve(this.listAdjustment) : this.fetchAllAdjustments(planId);
      [
        this.uomTypes,
        this.adjustmentTypes,
        this.listAdjustment,
      ] = await Promise.all(
        [
          uomTypesPromise,
          adjustmentTypesPromise,
          listAdjustmentPromise,
        ]
      );
      this.allDetailDataLoaded = true;
      this.isRefresh = false;
    } catch (e) {
      console.log(e);
      this.showErr(e);
    }

    this.stopProgress();
  }

  private async fetchUomTypes() {
    const url = Const.APIV2(`${Const.APIURI_METADATA}/ORG_0/WAREHOUSE_MANAGING_UNITS/rate_adjustment_units`);
    const response = await this.api.GET(url).toPromise();
    const list = response?.value ? JSON.parse(response.value) : [];
    return list.filter(item => item.active) || [];
  }

  private async fetchAdjustmentTypes() {
    const url = Const.APIV2(`${Const.APIURI_METADATA}/ORG_0/WAREHOUSE_MANAGING_SERVICES_FEES/services_fees_crossdock`);
    const response = await this.api.GET(url).toPromise();
    const list = response?.value ? JSON.parse(response.value) : [];
    return list?.filter(item => item.active) || [];
  }

  private async fetchAllAdjustments(planId) {
    const url = `${Const.APIURI_WAREHOUSE_RATE_PLAN}/${planId}/adjustments`;
    const response = await this.api.GET(url).toPromise();
    return response?.data?.list_data || [];
  }

  getFeeTypeName(type: RateAdjustmentType): string {
    const types = {
      inbound_fee: 'Inbound Fee',
      outbound_fee: 'Outbound Fee'
    }
    return types[type] || ''
  }

  getUnitName(unit: RateAdjustmentUnit): string {
    const units = {
      pallet: 'Pallet',
      case: 'Case',
      pack: 'Pack',
      piece: 'Piece'
    }
    return units[unit] || ''
  }

  getTriggerName(status: string): string {
    const statues = {
      arrivedAtWarehouse: 'Arrived At Warehouse',
      checkedOut: 'Checked Out'
    }
    return statues[status] || ''
  }

  getPlanType() {
    return this.isCustomType ? 'Plan for specific customer' : 'Default Plan';
  }

  get isCustomType(): boolean {
    return this.model.type === WarpConst.RatePlanTypes.custom;
  }

  formatMoney(amt: number | string) {
    return InputHelper.formatMoney2(`${amt || 0}`);
  }

  getRouterLink() {
    let filter = JSON.stringify({ type: this.model.type });
    return this.router.navigate([this.routeAdminWarehouseRatePlan], { queryParams: { warehouseId: this.warehouseId, filter }})
  }
}
