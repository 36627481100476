import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input, Output } from "@angular/core";
import { Const } from "@const/Const";

@Component({
  selector: "contact-info",
  template: `
    <div [ngClass]="{ 'contact-info-comp-inline': !!inline }">
      <!-- <a *ngIf="type === 'phone' && !isUnsubscribed" [href]="'tel:' + countryCode + owner">{{ displayPhone(owner) }}</a> -->
      <!-- <a *ngIf="type === 'email' && !isUnsubscribed" [href]="'mailto:' + owner">{{ owner }}</a> -->
      <span *ngIf="!isUnsubscribed">{{ type === "phone" ? displayPhone(owner) : owner }}</span>
      <span *ngIf="isUnsubscribed" nz-tooltip [nzTooltipTitle]="getMsg()">
        <em class="contact-unsubscribed">{{ type === "phone" ? displayPhone(owner) : owner }}</em>
        <i nz-icon class="unsubscribed-icon" nzType="warning" nzTheme="outline"></i>
      </span>
    </div>
  `,
  styles: [
    `
      .contact-info-comp-inline {
        display: inline-block;
      }
      .contact-unsubscribed {
        margin-right: 4px;
      }
      .unsubscribed-icon {
        color: #faad14;
      }
    `,
  ],
})
export class ContactInfoComponent extends BaseComponent {
  isUnsubscribed = false;
  @Input("type") type: "phone" | "email";
  @Input("owner") owner: string;
  @Input("inline") inline = false;
  @Input("countryCode") countryCode = "+1";
  @Input("scopes") scopes: string[] = [];
  @Input("isFetch") isFetch: boolean = true;
  @Input() get isUnsub() { return this.isUnsubscribed}
  set isUnsub(value) {
    if (!this.isFetch) {
      this.isUnsubscribed = !!value;
    }
  }

  constructor() {
    super();
  }

  ngOnInit() {
    if (this.isFetch) {
      this.fetchData();
    }
  }

  fetchData() {
    if (!this.owner || !this.type || !this.scopes?.length) return;
    const owner = this.type === "phone" ? this.owner.replace(/[^0-9]/g, "") : this.owner.toLowerCase();
    const conditions = {
      owner: this.type === "phone" ? this.countryCode + owner : owner,
      type: this.type,
      scopes: this.scopes,
    };
    this.api
      .POST(
        Const.API_SERVICE_COMM_UNSUBSCRIBE + '/check-exist',
        conditions
      )
      .subscribe((res) => {
        if (res?.message === "Success") {
          this.isUnsubscribed = res.data?.exist ?? false;
        }
      });
  }

  getMsg() {
    if (this.type === "phone") {
      return "The contact has opted out of receiving messages! In order to opt in again please send \"start\" to +18667921767";
    }
    return "The contact has opted out of receiving messages!";
  }
}
