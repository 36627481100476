export enum ShipmentManifestFormId {
  warpCarrierItems = 'warpCarrierItems',
  warpCarrierBasicInfo = 'warpCarrierBasicInfo',
  warpCarrierPickup = 'warpCarrierPickup',
  warpCarrierDropoff = 'warpCarrierDropoff',
  warpCarrierRevenue = 'warpCarrierRevenue',
  thirdPartyCarrierInfo = 'thirdPartyCarrierInfo',
  thirdPartyCarrierItems = 'thirdPartyCarrierItems',
  thirdPartyCarrierVehicle = 'thirdPartyCarrierVehicle',
  thirdPartyCarrierPickup = 'thirdPartyCarrierPickup',
  thirdPartyCarrierDropoff = 'thirdPartyCarrierDropoff',
  thirdPartyCarrierRevenue = 'thirdPartyCarrierRevenue',
}