import { Component, Input } from '@angular/core';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import { Const } from '@const/Const';
import { FormAddRate } from '@app/admin/crossdock/rate-configuration/add-rate/FormAddRate';
import { Validators } from '@angular/forms';
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { RateAdjustmentType, RateAdjustmentUnit } from '@wearewarp/types';

@Component({
  selector: '[warehouse-add-rate-adjustment]',
  templateUrl: './view.html',
  styleUrls: ['index.scss']
})
export class AddRateAdjustment extends BaseFormDialog1<FormAddRate> {
  @Input() warehouseId: string;
  @Input() planId: string;
  @Input() categoryCode: string;
  @Input() categoryName: string;
  protected formGroupDeclaration: FormGroupDeclaration = {
    name: { label: 'Fee name', type: 'string', inputType: 'text', placeHolder: 'Enter Fee Name', notAcceptEmpty: true, required: true },
    feeType: { label: 'Fee Type', required: true, placeHolder: 'Select Fee Type' },
    unit: { label: 'UoM', required: true, initialValue: WarpConst.RateAdjustmentUnit.pallet },
    price: {
      label: 'Unit price',
      type: 'number',
      inputType: 'number',
      notAcceptEmpty: true,
      required: true,
      placeHolder: '0.00',
      validators: [Validators.min(0.01), Validators.maxLength(6)]
    },
    triggerCode: { label: 'Trigger', required: true, placeHolder: 'Select shipment status', readOnly: true, submitReadOnly: true },
  };

  feeTypes = Object.values(WarpConst.RateAdjustmentTypes);
  units = Object.values(WarpConst.RateAdjustmentUnit);
  triggers = [WarpConst.WarehouseJobStatus.arrivedAtWarehouse, WarpConst.WarehouseJobStatus.checkedOut];

  constructor() {
    super();
  }

  get isCreateNew(): boolean {
    return true;
  }

  get shouldCreateFormImmediately() {
    return true;
  }

  get headerText() {
    return `Add ${this.categoryName}`;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  getFeeTypeName(type: RateAdjustmentType): string {
    const types = {
      inbound_fee: 'Inbound Fee',
      outbound_fee: 'Outbound Fee'
    }
    return types[type] || ''
  }

  onChangeFeeType(val: RateAdjustmentType | null) {
    if(!val) this.setItemValue('triggerCode', null);
    if(val === WarpConst.RateAdjustmentTypes.inbound_fee) {
      this.setItemValue('triggerCode', WarpConst.RateAdjustmentTriggerCodes.arrivedAtWarehouse)
    }
    if(val === WarpConst.RateAdjustmentTypes.outbound_fee) {
      this.setItemValue('triggerCode', WarpConst.RateAdjustmentTriggerCodes.checkedOut)
    }
  }

  getUnitName(unit: RateAdjustmentUnit): string {
    const units = {
      pallet: 'Pallet',
      case: 'Case',
      pack: 'Pack',
      piece: 'Piece'
    }
    return units[unit] || ''
  }

  getTriggerName(status: string): string {
    const statues = {
      arrivedAtWarehouse: 'Arrived At Warehouse',
      checkedOut: 'Checked Out'
    }
    return statues[status] || ''
  }

  clickAdd() {
    if (!this.warehouseId) {
      this.showErr('Missing warehouseId!');
      return;
    }
    if (!this.planId) {
      this.showErr('Missing planId!');
      return;
    }
    if (!this.categoryCode) {
      this.showErr('Missing categoryCode!');
      return;
    }
    this.onProgress = true;
    const url = `${Const.APIURI_WAREHOUSE_RATE_PLAN}/${this.planId}/adjustments`;
    const body = this.getFormData_JSON(true);
    body.categoryCode = this.categoryCode;
    const options = { customHeaders: { warehouseId: this.warehouseId } };
    this.api.POST(url, body, options).subscribe(
      resp => {
        this.onProgress = false;
        this.onCreateSuccess(resp);
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }
}
