<form class="form-detail" *ngIf="!isLoadingNotificationType" [formGroup]="formInput" nz-form>
  <div class="group no-padding auto-report-notification">
    <!-- header -->
    <div class="group-header" style="display: flex; align-items: center">
      <div style="text-wrap: nowrap !important">Auto Report Notification</div>
      <div style="width: 100%"></div>
      <ng-container *ngIf="requirePermissions([PermissionCode.client.update])">
        <!-- edit button -->
        <button nz-button (click)="onBtnEdit()" class="btn-primary" *ngIf="!isEditing">
          <i nz-icon nzType="edit" nzTheme="outline"></i>
          Edit
        </button>
        <!-- cancel button with confirmation -->
        <button (nzOnConfirm)="onBtnCancel()" *ngIf="isEditing && isInputsChange()" [disabled]="onProgress"
          class="btn-primary right10" nz-button nz-popconfirm nzPopconfirmPlacement="top" nzOkText="Yes"
          nzCancelText="No" nzPopconfirmTitle="Are you sure you want to cancel editing?">
          <i nz-icon nzTheme="outline" nzType="close"></i>
          Cancel
        </button>
        <!-- cancel button -->
        <button (click)="onBtnCancel()" *ngIf="isEditing && !isInputsChange()" [disabled]="onProgress"
          class="btn-primary right10" nz-button>
          <i nz-icon nzTheme="outline" nzType="close"></i>
          Cancel
        </button>
        <!-- save button -->
        <button nz-button nzType="primary" (click)="onBtnSave()" class="btn-primary" *ngIf="isEditing"
          [disabled]="onProgress || !needUpdate" [nzLoading]="onProgress">
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Save
        </button>
      </ng-container>
    </div>
    <!-- form -->
    <div class="group-content">
      <div class="group-type">
        <div class="text">Select lane that customer want to receive notifications</div>
        <div class="group-btn-content">
          <nz-select nzBackdrop="true"
            [formControlName]="'reportType'"
            style="min-width: 200px;"
            (ngModelChange)="onChangeReportType($event)"
          >
            <nz-option *ngFor="let item of reportTypes" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
          </nz-select>

          <div class="action-lane" *ngIf="isSpecificLaneMode">
            <button nz-button [disabled]="!isEditing" (click)="addLane()">
              <span nz-icon nzType="plus" nzTheme="outline"></span> Add Lane
            </button>
          </div>
        </div>
      </div>

      <table class="table">
        <thead class="ant-table-thead">
          <tr>
            <th class="ant-table-cell" style="max-width: 320px;width: 320px;" *ngIf="isSpecificLaneMode">Lane</th>
            <th class="ant-table-cell" style="max-width: 250px;width: 250px;">Email to</th>
            <th class="ant-table-cell" style="max-width: 250px;width: 250px;">Email cc</th>
            <th class="ant-table-cell" style="max-width: 150px;width: 150px;">Slack</th>
            <th class="ant-table-cell">Notification Type</th>
            <th class="ant-table-cell" *ngIf="isEditing">Action</th>
          </tr>
        </thead>
  
        <tbody class="ant-table-tbody">
          <ng-container *ngFor="let groupKey of ['autoMessages'];" [formArrayName]="groupKey">
            <tr class="ant-table-row" *ngFor="let item of messagesOfPage; let i = index" [formGroupName]="getIndex(i)">
              <td class="ant-table-cell no-padding" style="max-width: 320px;width: 320px;" *ngIf="isSpecificLaneMode">
                <ng-container *ngIf="getValue(getIndex(i), 'lanes').length">
                  <div *ngFor="let item of getValue(getIndex(i), 'lanes')" class="lane-item">
                    <div class="lane-name">
                      <img src="/assets/svg/pickup-icon.svg" />
                      {{ item.fromText }}
                    </div>
                    <div class="lane-desc">
                      <img src="/assets/svg/dropoff-icon.svg" />
                      {{ item.toText }}
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!getValue(getIndex(i), 'lanes').length">
                  <div class="lane-item">
                    N/A
                  </div>
                </ng-container>
              </td>
              <td class="ant-table-cell" style="max-width: 250px;width: 250px;">
                <ng-container *ngIf="getValue(getIndex(i), 'recipientTos').length">
                  <ng-container *ngFor="let item of getValue(getIndex(i), 'recipientTos')">
                    <div class="email-name">
                      {{ item.firstName }} {{ item.lastName }}
                    </div>
                    <div class="email-desc">
                      {{ item.email }}
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!getValue(getIndex(i), 'recipientTos').length">
                  N/A
                </ng-container>
              </td>
              <td class="ant-table-cell" style="max-width: 250px;width: 250px;">
                <ng-container *ngIf="getValue(getIndex(i), 'recipientCcs').length">
                  <ng-container *ngFor="let item of getValue(getIndex(i), 'recipientCcs')">
                    <div class="email-name">
                      {{ item.firstName }} {{ item.lastName }}
                    </div>
                    <div class="email-desc">
                      {{ item.email }}
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!getValue(getIndex(i), 'recipientCcs').length">
                  N/A
                </ng-container>
              </td>
              <td class="ant-table-cell" style="max-width: 150px;width: 150px;">
                <ng-container *ngIf="getValue(getIndex(i), 'slackChannelIds').length">
                  <ng-container *ngFor="let item of getValue(getIndex(i), 'slackChannelIds')">
                    <div class="channel-id">
                      {{ item.channelId }}
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!getValue(getIndex(i), 'slackChannelIds').length">
                  N/A
                </ng-container>
              </td>
              <td class="ant-table-cell">
                <ng-container *ngIf="getValue(getIndex(i), 'notificationTypes').length">
                  <ul class="notification-type">
                    <li *ngFor="let item of getValue(getIndex(i), 'notificationTypes')">
                      {{ item }}
                    </li>
                  </ul>
                </ng-container>
                <ng-container *ngIf="!getValue(getIndex(i), 'notificationTypes').length">
                  N/A
                </ng-container>
              </td>

              <td class="ant-table-cell" *ngIf="isEditing">
                <nz-space class="group-button">
                  <ng-container *ngIf="(isCreateNew || isEditing)">
                    <span *nzSpaceItem
                      style="cursor: pointer;"
                      nz-icon nzType="edit"
                      nzTheme="outline" (click)="editItemSetting(getIndex(i))"
                    ></span>

                    <span *nzSpaceItem
                      style="cursor: pointer;"
                      nz-icon nzType="copy"
                      nzTheme="outline" (click)="cloneItemSetting(getIndex(i))"
                    ></span>
                  </ng-container>
  
                  <ng-container *ngIf="(isSpecificLaneMode || (isAllLaneMode && getIndex(i) > 0)) && isEditing">
                    <span *nzSpaceItem
                      style="cursor: pointer;"
                      nz-icon nzType="minus-circle"
                      nzTheme="twotone" [nzTwotoneColor]="'#ff4d4f'"
                      (click)="removeItemSetting(groupKey, getIndex(i))"
                    ></span>
                  </ng-container>
                </nz-space>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>

      <ng-container *ngIf="dataMessages?.length > pageSize">
        <div class="top20">
          <nz-pagination [nzPageIndex]="pageIndex" [nzPageSize]="pageSize" [nzTotal]="dataMessages?.length" (nzPageIndexChange)="changePageIndex($event)"></nz-pagination>
        </div>
      </ng-container>
    </div>
  </div>
</form>

<ng-template #tplIconLocation><i nz-icon nzType="environment"></i></ng-template>
