<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>Add pallets</div>
    <div class="flex">
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>

<div>
  <div class="bottom10" style="text-align: end;">
    <ng-container *ngIf="numOfItemSelected">
      <span class="number-of-selected">Selected: {{ numOfItemSelected }} {{ numOfItemSelected > 1 ? 'items' : 'item' }}</span>
      <span class="divider">|</span>
    </ng-container>
    <span  class="number-of-total">Total: {{ totalCount }} {{ totalCount > 1 ? 'items' : 'item' }}</span>
    <span class="divider">|</span>
    <span  class="number-of-showing">Show: {{ numOfItemShowing }} {{ numOfItemShowing > 1 ? 'items' : 'item' }}</span>
  </div>
  <nz-table #nzTable nzBordered="true"
      [nzData]="listOfData"
      [nzHideOnSinglePage]="true"
      [nzPageSize]="limit"
      [nzNoResult]="tplNoData"
      [nzSize]="'default'"
      (nzPageIndexChange)="onDataListPageChanged($event)"
      (nzCurrentPageDataChange)="currentPageDataChange($event)">
      <ng-template #tplNoData>
        <div class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
      </ng-template>
      <thead>
        <tr>
          <th [(nzChecked)]="allChecked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="checkAll($event)"></th>
          <th>Pallet name</th>
          <th>Pallet label</th>
          <th>Weight</th>
          <th>DIM</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of nzTable.data; let i = index">
          <td
            [(nzChecked)]="item.checked"
            [nzDisabled]="item.disabled"
            (nzCheckedChange)="refreshStatus()"
          ></td>
          <td>
            {{ item.name || 'N/A' }}
          </td>
          <td>
            {{ item.label || 'N/A' }}
          </td>
          <td>
            {{ getWeightValue(item) }}
          </td>
          <td>
            {{ getDimValue(item) }}
          </td>
        </tr>
      </tbody>
    </nz-table>
</div>
<ng-template [nzModalFooter]>
  <div class="form-footer-shipment-manifest-add-pallet top10 bottom10">
    <div form-footer [onProgress]="onProgress" 
    [nzIconCancel]="null" [nzIconOK]="null"
    [canClickOK]="numOfItemSelected && !onProgress"
    labelOK="Add"
    (onOK)="onBtnSave()" (onCancel)="closeDialog()"></div>
  </div>
</ng-template>