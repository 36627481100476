import { Component, ViewChild } from '@angular/core';
import { BaseForm } from '../../../../../../base/form-base';
import { ComponentForm } from '../../../../interface';
import { FormDataShipmentManifestDraftData, FormDataShipmentManifestRevenue } from "@wearewarp/types/rest-api/admin/form-data/shipment-manifest";
import { ShipmentManifestTabContent } from '../../tab-content-component';
import { FormShipmentManifestRevenue } from '../../forms/shipment-revenue';

@Component({
  selector: '[shipment-warp-carrier-revenue]',
  templateUrl: './index.html'
})
export class ShipmentWarpCarrierRevenue extends ShipmentManifestTabContent implements ComponentForm<FormDataShipmentManifestRevenue> {

  @ViewChild('formComp') formComp: FormShipmentManifestRevenue;
  
  getForm(): BaseForm<FormDataShipmentManifestRevenue> {
    return this.formComp;
  }

  onTabActive(active: boolean): void {
    if (active) {
      this.updateSelectLocations();
      this.formModel = {
        settings: {
          isDoNotInvoice: this.modelDraft?.settings?.isDoNotInvoice
        },
        cost: this.modelDraft?.cost
      }
    }
  }

  get modelDraft(): FormDataShipmentManifestDraftData {
    return super.modelDraft;
  }

  private _formModel: FormDataShipmentManifestRevenue;
  get formModel(): FormDataShipmentManifestRevenue {
    return this._formModel;
  }
  set formModel(value) {
    this._formModel = value;
  }

  public selectLocations = [];

  ngOnInit(): void {
    this.updateSelectLocations();
  }

  updateSelectLocations() {
    let pickInfo = this.modelDraft?.pickInfo;
    let dropInfo = this.modelDraft?.dropInfo;
    if (!pickInfo || !dropInfo) return;
    this.selectLocations = [
      { deliveryId: pickInfo.id, type: pickInfo.type, label: 'Pickup Location 1'},
      { deliveryId: dropInfo.id, type: dropInfo.type, label: 'Delivery Location 1'},
    ]
  }

}