import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTableModule } from 'ng-zorro-antd/table';
import { CustomerLaneList } from '.';
import { ModuleWPaginator } from '@app/admin/components/paginator/module';
import { ModuleCustomerLaneListFilter } from '../list-filter/module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzSelectModule,
    NzTableModule,
    ModuleWPaginator,
    ModuleCustomerLaneListFilter,
  ],
  declarations: [CustomerLaneList],
  exports: [CustomerLaneList]
})
export class ModuleCustomerLaneList {
}