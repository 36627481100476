<div class="shipment-manifest-header">
  <div class="btn-close center-children clickable" [ngClass]="{'disabled': !canClose}">
    <i nz-icon nzType="close" nzTheme="outline" (click)="closeDialog()"></i>
  </div>
  <div class="page-title">Create shipment</div>
</div>

<div class="shipment-manifest-content">
  <div style="flex: 1; margin-right: 20px; background: #FFFFFF;;">
    <div shipment-manifest-tabs #shipmentTabs [tabs]="tabs" 
      [validateCanChangeTab]="validateCanChangeTab" (tabChanged)="onTabChanged($event)">
    </div>
  </div>
  <div shipment-manifest-info-summary [modelDraft]="modelDraft" [modelManifest]="modelManifest"></div>
</div>

<div class="shipment-manifest-footer">
  <button style="margin-left: 20px; margin-right: 20px;" 
    nz-button nzSize="large" nzType="primary" 
    (click)="onBtnSave()" [nzLoading]="isSavingData" [disabled]="isSavingData">
    Save and continue
  </button>
  <button nz-button nzSize="large" (click)="closeDialog()" [disabled]="!canClose">Cancel</button>
</div>


