import { Component, forwardRef, Inject, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../base-custom-input';
import { DialogService } from '@dialogs/dialog.service';
import { SelectAddNewPaymentTerms } from './select-add-new';

@Component({
  selector: 'app-form-carrier-payment-term',
  templateUrl: './view.html',
  styleUrls: ['style.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FormCarrierPaymentTermComponent), multi: true }],

})
export class FormCarrierPaymentTermComponent extends BaseInputComponent {

  @Input() isDisabled: boolean = false;

  _paymentTerms: string;
  get paymentTerms() { return this._paymentTerms}
  set paymentTerms(value) {
    this._paymentTerms = value;
    this.onValueChange();
  }

  public options = [
    { name: 'Net 60 (No fee)', numOfDay: 60, feePercentage: 0, value: '60_0' },
    { name: 'Net 30 (No fee)', numOfDay: 30, feePercentage: 0, value: '30_0' },
    { name: 'Net 15 (2% fee)', numOfDay: 15, feePercentage: 2, value: '15_2' },
    { name: 'Net 7 (3% fee)', numOfDay: 7, feePercentage: 3, value: '7_3' },
    { name: 'Quick Pay - Due on receipt (4% fee)', numOfDay: 1, feePercentage: 4, value: '1_4' },
    { name: 'Net 15 (3% fee)', numOfDay: 15, feePercentage: 3, value: '15_3' },
    { name: 'Net 7 (5% fee)', numOfDay: 7, feePercentage: 5, value: '7_5' },
    { name: 'Quick Pay - Due on receipt (8% fee)', numOfDay: 1, feePercentage: 8, value: '1_8' },
  ];

  constructor(
    @Inject(Injector) protected injector: Injector,
  ) {
    super(injector)
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  get value() {
    return this.selected
  }

  set value(data) {
    if (data?.value) {
      this._paymentTerms = data.value;
      const exists = this.options.find(option => option.value === data.value);
      if (!exists) {
        this.options.push(data);
      }
    }
    this.onValueChange();
  }

  onValueChange() {
    this.selected = this.options.find(option => option.value === this.paymentTerms);
    this.onChange(this.selected);
    this.onTouch(this.selected);
  }

  onBtnAddNewPaymentTerms() {
    DialogService.openFormDialog1(SelectAddNewPaymentTerms, {
      nzComponentParams: {
        closeOnSuccess: true,
        onSave: (data) => {
          const exists = this.options.find(option => option.value === data.value);
          if (!exists) {
            this.options.push(data);
          }
          this.paymentTerms = data.value;
        }
      },
      nzClassName: "modal",
      nzCentered: true,
    });
  }

}
