<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>{{headerText}}</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>

<div>
  <form nz-form class="form-detail" [formGroup]="formInput" nzLayout="vertical">
    <div>
      <div class="form-label-v2 bottom5">
        {{getLabel('checkedInAt')}}
        <span *ngIf="isRequired('checkedInAt')" class="label-mark-required"></span>
      </div>
      <nz-form-item>
        <nz-form-control>
          <nz-date-picker 
            nzShowTime class="full-w"
            formControlName="checkedInAt"
            nzFormat="yyyy-MM-dd HH:mm:ss" 
          ></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div>
      <div class="form-label-v2 bottom5">
        {{getLabel('checkedOutAt')}}
        <span *ngIf="isRequired('checkedOutAt')" class="label-mark-required"></span>
      </div>
      <nz-form-item>
        <nz-form-control>
          <nz-date-picker 
            nzShowTime class="full-w"
            formControlName="checkedOutAt"
            nzFormat="yyyy-MM-dd HH:mm:ss" 
          ></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </div>
  </form>
</div>

<div *nzModalFooter>
  <div form-footer [onProgress]="onProgress" 
    [nzIconCancel]="null" [nzIconOK]="null"
    [canClickOK]="needUpdate && !onProgress"
    [canClickCancel]="!onProgress" [labelOK]="'Update'"
    (onOK)="onBtnSave()" (onCancel)="closeDialog()"
  ></div>
</div>