<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>Add payment term</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>

<form nz-form [formGroup]="formInput" class="form-detail" [formGroup]="formInput" nzLayout="vertical">
  <div *ngFor="let key of ['name', 'numOfDay', 'feePercentage']">
    <nz-form-item>
      <nz-form-label>{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></nz-form-label>
      <nz-form-control>
        <input nz-input [formControlName]="key"
          [type]="getInputType(key)"
          [placeholder]="getPlaceHolder(key)"
          (input)="onInputChanged($event, key)"
          (keypress)="onInputKeyPress($event, key)"
          (focusout)="onInputFocusOut($event, key)">
      </nz-form-control>
    </nz-form-item>
  </div>
</form>

<div form-footer [onProgress]="onProgress" [nzIconCancel]="null" [nzIconOK]="null"
  [canClickOK]="needUpdate && !onProgress" [canClickCancel]="!onProgress" labelOK="Add"
  (onOK)="onBtnSave()" (onCancel)="closeDialog()"></div>