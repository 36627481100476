import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { WarpId } from "@wearewarp/universal-libs";
import { Const } from "@const/Const";
import { Utils } from "@services/utils";

interface IDisplayManifestShipment {
  id: string, 
  warpId: number, 
  orderId: string
}

@Component({
  selector: '[display-manifest-shipment-warpid]',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayManifestShipmentWarpID extends BaseComponent {
  public displayInfo: any = {};
  public limit = 3;

  @Input() shipments: Array<IDisplayManifestShipment> = [];

  ngOnChanges() {

  }

  getRouterLink(item: IDisplayManifestShipment) {
    if (item?.orderId) {
      return [Const.routeAdminOrderList, item.orderId];
    }
    return [Const.routeAdminOrderList];
  }

  public getWarpIdText(shipment: IDisplayManifestShipment) {
    return WarpId.showShipment(shipment.warpId);
  }

  public getDisplayShipments(shipments) {
    if (!Utils.isArray(shipments)) return [];
    let data = [...shipments];
    let newArray = data.slice(0, this.limit);
    return newArray;
  }

  public getMoreShipments(shipments) {
    if (!Utils.isArray(shipments)) return [];
    let data = [...shipments];
    let newArray = data.slice(this.limit);
    return newArray;
  }

}