import { Component } from "@angular/core";
import { Input } from "@angular/core";
import { DateUtil } from '@services/date-utils';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import { Const } from "@const/Const";
import moment from "moment-timezone";

@Component({
  selector: 'dlg-edit-storage-time',
  templateUrl: './view.html',
  styleUrls: ['../../../../dialogs/dialogs.scss', '../../../../app.scss']
})
export class DlgEditStorageTime extends BaseFormDialog1 {
  public static get declaration(): FormGroupDeclaration {return {
    checkedInAt: { label: 'Checked In At', type: 'date', required: true },
    checkedOutAt: { label: 'Checked Out At', type: 'date', required: true }
  }}

  protected formGroupDeclaration: FormGroupDeclaration = DlgEditStorageTime.declaration;

  @Input() timezone: string;
  public headerText: string = 'Edit Storage Time';

  protected beforeBindModel(model: any) {
    const now = moment().tz(this.timezone).format('YYYY-MM-DDTHH:mm:ss');
    let data: any = {
      checkedInAt: null,
      checkedOutAt: now
    }
    if (model?.checkedInAt) {
      data['checkedInAt'] = DateUtil.convertLocalTime2(model.checkedInAt, this.timezone);
    }
    if(model?.checkedOutAt) {
      data['checkedOutAt'] = DateUtil.convertLocalTime2(model.checkedOutAt, this.timezone);
    }
    return data;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  public onBtnSave() {
    let json: any = this.formData_JSON(true)
    let manualCheckedInAt = DateUtil.convertLocalTime(json['checkedInAt'], this.timezone).toISOString();
    let manualCheckedOutAt = DateUtil.convertLocalTime(json['checkedOutAt'], this.timezone).toISOString();
    const params = { manualCheckedInAt, manualCheckedOutAt }

    this.api.POST(`${Const.APIURI_WAREHOUSE_JOB}/${this.model.id}/storage-time`, params).subscribe(
      (response) => {
        this.updateSuccess(params);
        this.showSuccess('Storage time updated successfully');
        this.closeDialog();
      },
      (err) => {
        this.showErr(err);
        this.closeDialog();
      }
    );
  }
}