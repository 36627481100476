import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzButtonModule } from "ng-zorro-antd/button";
import { PalletHistory } from '.';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { ListPhoto } from './list-photo';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@NgModule({
  imports: [
    CommonModule,
    NzIconModule,
    NzPopoverModule,
    NzButtonModule,
    NzTimelineModule,
    NzBadgeModule,
    NzToolTipModule
  ],
  declarations: [
    PalletHistory,
    ListPhoto
  ],
  exports: [
    PalletHistory
  ]
})
export class PalletHistoryModule {}