import { Component, ViewChild } from '@angular/core';
import { BaseForm } from '../../../../../../base/form-base';
import { ShipmentManifestTabContent } from '../../tab-content-component';
import { ComponentForm } from '../../../../interface';
import { FormDataShipmentManifestBasicInfo } from "@wearewarp/types/rest-api/admin/form-data/shipment-manifest";
import { FormShipmentManifestBasicInfo } from '../../forms/basic-info';
import { Const as WarpConst } from "@wearewarp/universal-libs";

@Component({
  selector: '[shipment-third-party-carrier-vehicle]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class ShipmentThirdPartyCarrierVehicle extends ShipmentManifestTabContent implements ComponentForm<FormDataShipmentManifestBasicInfo> {
  @ViewChild('formComp') formComp: FormShipmentManifestBasicInfo;
  
  getForm(): BaseForm<FormDataShipmentManifestBasicInfo> {
    return this.formComp;
  }

  get formModel() {
    let origin = this.modelDraft?.basicInfo;
    return origin ?? { clientId: this.modelManifest?.clientId, shipmentType: WarpConst.ShipmentType.lessThanTruckload }
  }

  get createMode() {
    return this.modelManifest?.createMode;
  }

}