import { Component } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { BaseList } from "@app/admin/base/list";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { CreateExternalRouteConnecting } from "./create-external-route-connecting";
import { Utils } from "@services/utils";

@Component({
  selector: '[warehouse-external-route-connecting]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss','../../list.scss']
})
export class WarehouseExternalRouteConnecting extends BaseList {
  public search: string = null
  public page: number = 1;
  public loaded: number = 0;
  protected getApiUrl(): string {
    return Const.APIURI_WAREHOUSE_EXTERNAL_ROUTE_CONNECTING;
  }

  doSearch(s) {
    this.search = s;
    this.page = 1;
    this.loadData();
  }

  loadData() {
    this.routeWithQueryUrl(this.buildQuery(), true);
  }

  private buildQuery(): any {
    let q = { page: this.page || 1, loaded: Date.now() };
    if (this.search) {
      q["search"] = this.search;
    }
    let condition: any = {};
    const f = JSON.stringify(condition);
    if (f.length > 2) {
      q['filter'] = f;
    }
    return q;
  }

  constructor() {
    super();
  }

  public createExternalRouteConnecting(){
    DialogService.openFormDialog1(CreateExternalRouteConnecting, {
      nzClassName: 'modal-xl modal-no-padding',
      nzComponentParams: {
        closeOnSuccess: true,
        createSuccess: () => { this.getData()},
      },
    })
  }
  
  updateExternalRouteConnecting(item){
    DialogService.openFormDialog1(CreateExternalRouteConnecting, {
      nzClassName: 'modal-xl modal-no-padding',
      nzComponentParams: {
        model: {id: item.id, fromWarehouseId: item.fromWarehouseId, toWarehouseIds: item.toWarehouseIds},
        closeOnSuccess: true,
        updateSuccess: () => { this.getData()}
      },
    })
  }

  public getWarehouseName(metadata){
    if(!metadata || !Utils.isObjectNotEmpty(metadata)) return 'N/A';
    return `[${metadata?.warpId}] ${metadata?.name}`
  }

}