<div class="white-box top20">
  <div class="flex-space-between center-vertical">
    <div class="group-title">
      <div class="medium-title">
        {{ feeType.label }}
      </div>
      <div class="description top8">
        The storage fee changes depending on how long items are stored. Weekends and US holidays are excluded.
      </div>
    </div>
    
    <!--Add Fee Button-->
    <button (click)="clickAddFee(feeType)" 
      *ngIf="canAddFee"
      nz-button nzType="primary"
    >
      <span nz-icon nzType="plus-circle" nzTheme="outline"></span>
      Add fee
    </button>
  </div>
  <div class="top15">
    <nz-table #storageTable [nzData]="rateAdjustments" [nzFrontPagination]="false" [nzShowPagination]="false"
      [nzSize]="'default'" nzBordered="true" nzSize="small">
      <thead>
        <tr>
          <th nzWidth="220px">
            <div class="group-th">
              <div>UoM</div>
              <div nz-tooltip nzTooltipTitle="Unit of Measurement">
                <span nz-icon nzType="info-circle" nzTheme="fill" class="gray-icon"></span>
              </div>
            </div>
          </th>
          <th>Storage Fee Details</th>
          <th *ngIf="!isAdminReadOnlyRole && isApplySoon" nzWidth="250px" nzAlign="right">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let feeItem of storageTable.data; let index = index">
          <td>{{ getUnitName(feeItem.unit) }}</td>
          <td [innerHTML]="liveExplanationText(feeItem)"></td>
          <td *ngIf="isApplySoon">
            <div class="group-btn">
              <span (click)="clickEditFee(feeItem)" 
                class="accent click" 
                nz-icon nz-tooltip nzTheme="outline"
                nzTooltipTitle="Edit fee" nzType="edit"
              ></span>
              <div (nzOnConfirm)="clickDeleteFee(feeItem)"
                *ngIf="!feeItem.deleting"
                class="flex center-vertical left20 click red" 
                nz-popconfirm nz-tooltip
                nzPopconfirmPlacement="top" 
                nzPopconfirmTitle="Are you sure you want to delete this fee?"
                nzTooltipTitle="Delete fee"
              >
                <span nz-icon nzTheme="outline" nzType="delete"></span>
              </div>
              <div *ngIf="feeItem.deleting" class="flex center-vertical left20">
                <span nz-icon nzTheme="outline" nzType="loading"></span>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>