import { Component, Input } from "@angular/core";
import { BaseForm, FormGroupDeclaration } from '@wearewarp/ng-form';

export interface FormDataRating {
  type: string,
  note: string,
}
@Component({
  selector: 'form-rating-dialog',
  templateUrl: './view.html',
  styleUrls: [
    './style.scss'
  ]
})
export class FromRatingDialog extends BaseForm<FormDataRating> {
  protected override formGroupDeclaration: FormGroupDeclaration = {
    type: {label: ''},
    note: {label: 'Write a review', placeHolder: 'Write a review', notAcceptEmpty: true},
  }

  @Input() type;
  @Input() viewMode: boolean = false;
  @Input() thumpUpOptions: string[] = [];
  @Input() thumpDownOptions: string[] = [];

  public currentNote: string = '';

  ngOnInit(): void {
    if (this.thumpUpOptions.length && !this.thumpUpOptions.includes('Others')) {
      this.thumpUpOptions.push('Others');
    }
    if (this.thumpDownOptions.length && !this.thumpDownOptions.includes('Others')) {
      this.thumpDownOptions.push('Others');
    }
    super.ngOnInit();
    if (this.type) {
      this.setItemValue('type', this.type);
    }
    this.currentNote = this.getItemValue('note');
    this.loadData();
  }

  // Do data BE chỉ lưu dạng string nên phải xử lý load lại dữ liệu cũ
  public optionItemState: {[key: number]: boolean} = {};
  loadData() {
    let note = this.currentNote;
    if (!note) return;
    for(let i = 0; i < this.options.length; i++) {
      let option = this.options[i];
      if (this.currentNote.includes(option)) {
        this.optionsSelected.push(option);
        this.optionItemState[i] = true;
      }
      else {
        this.optionItemState[i] = false;
      }
    }
    let currentOtherArr = [];
    let tmpArr = note.split(',').map(x => x.trim());
    for (let item of tmpArr) {
      if (!this.options.includes(item)) {
        currentOtherArr.push(item);
      }
    }
    let currentOtherNote = currentOtherArr.join(', ');
    this.setItemValue('note', currentOtherNote);
    if (currentOtherNote) {
      this.optionsSelected.push('Others');
      this.optionItemState[this.options.length - 1] = true;
    }
  }

  get isLike() {
    return this.getItemValue('type') === 'like';
  }

  get isDislike() {
    return this.getItemValue('type') === 'dislike';
  }

  // Luôn cho submit
  canSubmit(): boolean {
    return true;
  }

  onClickBtn(type: 'like'|'dislike') {
    const currentType = this.getItemValue('type');
    if (currentType === type) {
      return;
    }
    this.setItemValue('type', type);
    if (this.optionsSelected.includes('Others')) {
      this.optionsSelected = ['Others'];
      this.optionItemState = {};
      this.optionItemState[this.options.length - 1] = true;
    } else {
      this.optionsSelected = [];
      this.optionItemState = {};
    }
  }

  get options() {
    if (this.isLike) {
      return this.thumpUpOptions;
    } else if (this.isDislike) {
      return this.thumpDownOptions;
    } else {
      return []
    }
  }

  get isSelectedOthers() {
    return this.optionsSelected.includes('Others');
  }

  optionsSelected: string[] = [];

  onCheckboxClick(option, event) {
    if (event && !this.optionsSelected.includes(option)) {
      this.optionsSelected.push(option);
    } else {
      this.optionsSelected = this.optionsSelected.filter(x => x !== option);
    }
  }

  getFormData(): FormDataRating {
    let data: FormDataRating = super.getFormData();
    if (!this.options.length) {
      return data;
    }
    if (this.optionsSelected.includes('Others')) {
      let arr = this.optionsSelected.filter(item => item !== 'Others');
      if (data.note) { arr.push(data.note); }
      return {
        ...data,
        note: arr.join(', ')
      }
    } else {
      return {
        ...data,
        note: this.optionsSelected.join(', ')
      }
    }
  }
  
}