import { Component } from "@angular/core";
import { BaseDetail } from "@app/admin/base/detail";
import { Const } from "@const/Const";
import _ from 'underscore'

@Component({
  selector: '[warehouse-manifest-detail]',
  templateUrl: './index.html',
  styleUrls: [ './index.scss' ]
})
export class WarehouseManifestDetail extends BaseDetail {

  ngOnInit(): void {
    super.ngOnInit(); 
    this.autoShrinkSideBar();
    this.handleHashURL();
  }

  selectedTabIndex = 0;

  protected getApiUrl(): string {
    return Const.APIURI_WAREHOUSE_MANIFEST;
  }

  keys = {
    items: 'items',
    deliveryPlanning: 'deliveryPlanning',
  }
  tabs = [
    {
      name: 'Items',
      key: this.keys.items,
      fragment: 'items',
    },
    {
      name: 'Delivery Planning',
      key: this.keys.deliveryPlanning,
      fragment: 'delivery-planning',
      total: 0,
    },
  ]

  onTabChange(event) {
    let tab = this.getTabData(event.index)
    let url = this.router.url;
    let arr = url.split('#')[0].split('/');
    let manifestId = arr[arr.length - 1];
    this.router.navigate([this.routeAdminCrossdockWarehouseManifests, manifestId], {fragment: tab.fragment})
  }

  private getTabData(index: number) {
    if (index >= 0 && index < this.tabs.length) {
      return this.tabs[index];
    }
    return this.tabs[0];
  }

  protected handleHashURL() {
    let arr = this.router.url.split('#');
    if (arr.length > 0) {
      let fragment = arr[arr.length - 1];
      if (fragment) {
        for (let i=0; i<this.tabs.length; i++) {
          let tab = this.tabs[i];
          if (tab.fragment == fragment && this.selectedTabIndex != i) this.selectedTabIndex = i;
        }
      }
    }
  }

  protected onGetDetailSuccess(data) {
    // từ items lấy ra tất cả các cặp carrier consignee
    const cartons = data?.data?.cartons || [];
    const groupByCarton = _.groupBy(cartons, (item) => {
      let carrier = item.carrier || '';
      let consignee = item.consignee || '';
      return `${carrier}---${consignee}`;
    });
    const numOfDeliveryPlanning = Object.keys(groupByCarton).length;
    this.tabs.find(it => it.key == this.keys.deliveryPlanning).total = numOfDeliveryPlanning;
    return data;
  }
  
}
