import { Shipment } from '@wearewarp/types/data-model';
import { CompositeLayer } from 'deck.gl';
import { ArcLayer, ScatterplotLayer } from '@deck.gl/layers'
import _ from 'underscore'
import Utils from './utils';
import { BizUtil } from '@services/biz';

export default class ShipmentLayer extends CompositeLayer {
    id: string
    static layerName = "ShipmentLayer"
    shipments: any[]
    selectShipment: (s: string) => void
    hideArc: boolean = false

    constructor(id, shipments, hideArc=false, selectShipment=null) {
        super({id, data: {shipments, hideArc}})
        this.id = id
        this.hideArc = hideArc
        this.shipments = shipments
        this.selectShipment = selectShipment
    }

    getSourceColor(item, mile) {
        if (item.selected) return [0, 255, 255, 100]
        // if (item.status === 'complete') return [35, 133, 82]
        // if (item.lastJobId) return [24, 144, 255]
        return mile === 'last' ? [14, 160, 98] : [44, 68, 230]
    }

    getTargetColor(item, mile) {
        if (item.selected) return [0, 200, 200, 100]
        // if (item.status === 'complete') return [35, 133, 82, 60]
        // if (item.lastJobId) return [24, 144, 255, 60]
        return mile === 'first' ? [14, 160, 98]:[200, 90, 46]
    }

    createShipmentArcLayer() {
        const byLane = {}
        for (let shipment of this.shipments) {
            const pickup = shipment.deliveryInfos.filter(x => x.type === 'PICKUP')[0]?.addr
            const dropoff = shipment.deliveryInfos.filter(x => x.type === 'DROPOFF')[0]?.addr
            if (!pickup || !dropoff) continue
            const fromLocId = Utils.calculateAddressLocationId(pickup)
            const toLocId = Utils.calculateAddressLocationId(dropoff)
            const lane = [fromLocId, toLocId].sort().join('<>')
            const existing = byLane[lane] || []
            existing.push(shipment.id)
            byLane[lane] = existing
        }

        const tilts = {}
        const heights = {}
        for (const k in byLane) {
            const l = byLane[k]
            let inc = 0
            if (l.length == 1) {
                inc = 0
            } else if (l.length == 2) {
                inc = 30
            } else if (l.length == 3 || l.length == 4) {
                inc = 15
            } else {
                inc = 90 / l.length
            }
            for (let i = 0; i < l.length; i++) {
                tilts[l[i]] = inc * (i - (l.length - 1) / 2)
                heights[l[i]] = 0.5 + inc * (i - (l.length - 1) / 2) / 180
            }
        }

        const data = this.shipments.map(it => {
            const metadata: any = it.metadata
            const client = it.client || it.metadata?.client
            const { mile, traffic, workload } = metadata || {}
            const { distance } = traffic || {}
            const mileage = distance ? (distance / 1609.34).toFixed(1) : null
            const pickup = BizUtil.getPickInfo(it)
            const dropoff = BizUtil.getDropInfo(it)
            const pickupAddr = pickup?.addr
            const dropoffAddr = dropoff?.addr
            if (!pickupAddr || !dropoffAddr) return null
            const status = (it.lastJobId ? 'routed + ' : '') + `${it.status || ''}`
            const pickupTime = Utils.getDisplayTime(pickup, null)
            const dropoffTime = Utils.getDisplayTime(dropoff, null)
            const info = (it.warpId ? `Shipment ${it.code ?? it.warpId}\n` : '') +
                (client?.name ? `Customer: ${client?.name}\n` : '') +
                (pickupAddr ? `Origin: ${pickupAddr.street}, ${pickupAddr.state} ${pickupAddr.zipcode}\n` : '') +
                (dropoffAddr ? `Dest: ${dropoffAddr.street}, ${dropoffAddr.state} ${dropoffAddr.zipcode}\n` : '') +
                `${status || ''}` +
                (mileage ? `\nMileage: ${mileage}mi` : '') +
                (workload ? `\n${workload} Pallets` : '') +
                (pickupTime ? `\nPickup  ${pickupTime}` : '') +
                (dropoffTime ? `\nDropoff ${dropoffTime}` : '')

            if (!pickupAddr?.metadata?.longitude) return null
            if (!dropoffAddr?.metadata?.longitude) return null
            return {
                warpId: it.warpId,
                code: it.code,
                id: it.id,
                type: 'SHIPMENT',
                from: [pickupAddr.metadata.longitude, pickupAddr.metadata.latitude],
                to: [dropoffAddr.metadata.longitude, dropoffAddr.metadata.latitude],
                sourceColor: this.getSourceColor(it, mile),
                targetColor: this.getTargetColor(it, mile),
                info,
                width: 2,
                tilt: tilts[it.id],
                height: heights[it.id],
                shipment: it,
                // width: it.selected ? 5 : 2
            }
        }).filter(it => it)

        return new ArcLayer({
            id: `${this.id}-arc-layer`,
            data,
            pickable: true,
            getWidth: d => d.width,
            getSourcePosition: d => d.from,
            getTargetPosition: d => d.to,
            getHeight: d => d.height,
            getTilt: d => d.tilt,
            getSourceColor: d => d.sourceColor,
            getTargetColor: d => d.targetColor,
            greatCircle: true,
            onClick: (info, event) => {
                console.log(info)
                const { object } = info
                this.selectShipment && this.selectShipment(object.id)
            }
        });
    }

    getDeliveryMapInfo(info, mile) {
        if (!info?.addr?.metadata?.longitude) return null
        return {
            name: info.locationName,
            locationId: info.warehouseId,
            coordinates: [info.addr.metadata.longitude, info.addr.metadata.latitude],
            addr: info.addr,
            info: `${info.locationName || ''}\n${info.addr.street}\n${info.addr.state} ${info.addr.zipcode}`,
            color: info.type === 'PICKUP' ? (mile === 'last' ? [14, 160, 98] : [44, 68, 230]) : (mile === 'first' ? [14, 160, 98] : [200, 90, 46])
        }
    }

    createShipmentDotLayer() {
        const locations = _.flatten(this.shipments.map(it => {
            const metadata: any = it?.metadata
            const { mile } = metadata || {}
            const pickup = it.deliveryInfos.filter(x => x.type === 'PICKUP')[0]
            const dropoff = it.deliveryInfos.filter(x => x.type === 'DROPOFF')[0]
            return [
                this.getDeliveryMapInfo(pickup, mile),
                this.getDeliveryMapInfo(dropoff, mile)
            ]
        })).filter(it => it)

        return new ScatterplotLayer({
            id: `${this.id}-scatterplot-layer`,
            data: locations,
            pickable: true,
            opacity: 1,
            stroked: true,
            filled: true,
            radiusScale: 6,
            radiusMinPixels: 6,
            radiusMaxPixels: 6,
            lineWidthMinPixels: 1,
            lineWidthMaxPixels: 1,
            getPosition: d => d.coordinates,
            getFillColor: d => d.color,
            getLineColor: d => [0, 0, 0]
        });
    }

    renderLayers() {
        return !this.hideArc ? [
            this.createShipmentDotLayer(),
            this.createShipmentArcLayer(),
        ] : [
            this.createShipmentDotLayer()
        ];
    }
}