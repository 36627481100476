import { Component, Input } from '@angular/core';
import { InputHelper } from '@services/input-helper';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import { FormArray, FormControl } from "@angular/forms";
import { Observable } from 'rxjs';
import { DateUtil } from '@services/date-utils';

@Component({
  selector: 'customer-add-deposit-amount',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class CustomerAddDepositAmount extends BaseFormDialog1 {

  @Input() onSave: (data: any) => Observable<any>;
  @Input() onDone: () => void;
  public defaulTimeZone = DateUtil.Timezone_LA;
  public shortTimezone = DateUtil.timezoneStandardToUsShort(this.defaulTimeZone);

  protected formGroupDeclaration: FormGroupDeclaration = {
    amount: { label: "Amount", required: true, type: 'number', getValue: InputHelper.getValueMoney, formatValue: (value) => InputHelper.formatMoney1(value) },
    paymentDate: { label: 'Payment Date', required: true },
    refs: { label: 'References', type: 'formArray', childItem: {
      label: '', notAcceptEmpty: true
    }, initialValue: ['']},
  }

  get shouldShowRemoveReference() {
    let fa = <FormArray>this.formInput.get('refs');
    return fa?.length && fa.length > 1;
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'amount':
        return InputHelper.handleInputChangeMoney(event, <FormControl>this.formInput.get(key), true);
      default:
        return super.onInputChanged(event, key);
    }
  }

  onInputKeyPress(event, key) {
    switch (key) {
      case 'amount':
        return InputHelper.handleInputKeyPressMoney(event);
      default:
        return super.onInputKeyPress(event, key);
    }
  }


  onBtnRemoveFormArray(index) {
    let itemValue = this.getItemValue(`refs[${index}]`);
    let message = itemValue ? `Remove reference number <b>${itemValue}</b>?` : `Remove reference number at position <b>${index + 1}</b>?`;
    this.confirmDeletion({
      message: message,
      txtBtnOk: 'Remove',
      fnOk: () => this.removeItemInFormArray('refs', index)
    });
  }

  onBtnAddRef() {
    this.addItemToFormArray('refs');
  }

  protected getFormData_JSON(isCreateNew) {
    let json: any = super.getFormData_JSON(true);
    json.paymentDate = DateUtil.convertLocalTime(json.paymentDate, DateUtil.Timezone_LA).toISOString();
    json.refs = json.refs.filter(x=>x);
    return json;
  }

  onBtnSave() {
    if (!this.needUpdate) {
      return;
    }
    let data = this.getFormData_JSON(true);
    this.onProgress = true;
    this.onSave(data).subscribe(
      resp => {
        this.closeDialog();
        this.onProgress = false;
        this.onDone();
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }

}