<div class="title">Shipment info</div>

<div *ngIf="deliveryInfos?.length" style="height: 180px; position: relative; margin-top: 10px;">
  <map-container [(map)]="map" (mapChange)="onMapChange()" mapStyle="streets-v11"></map-container>
</div>

<div *ngIf="displayInfo.createMode == 'warpCarrier'" class="group">
  <div class="group-title">Operation team</div>
  <div>WARP</div>
</div>

<div *ngIf="displayInfo.createMode == 'thirdPartyCarrier' && displayInfo.thirdPartyCarrierName" class="group">
  <div class="group-title">External Carrier</div>
  <div class="line">{{ displayInfo.thirdPartyCarrierName }}</div>
  <div class="line">
    <span>{{ displayInfo.thirdPartyContactName }}</span>
    <span *ngIf="displayInfo.thirdPartyContactPhone"> • {{ displayInfo.thirdPartyContactPhone }}</span>
    <span *ngIf="displayInfo.thirdPartyContactEmail"> • {{ displayInfo.thirdPartyContactEmail }}</span>
  </div>
</div>

<div *ngIf="displayInfo.numOfPallets" class="group">
  <div class="group-title">Items</div>
  <div>{{ displayInfo.numOfPallets }} {{ displayInfo.numOfPallets == 1 ? 'pallet' : 'pallets' }}</div>
</div>

<div *ngIf="displayInfo.clientName" class="group">
  <div class="group-title">Customer & Equipment</div>
  <div class="line">{{ displayInfo.clientName }}</div>
  <div class="line">{{ displayInfo.shipmentType }}</div>
  <div class="line">{{ displayInfo.vehicleTypeName }}</div>
</div>

<div *ngIf="displayInfo.pickAddr" class="group">
  <div class="group-title">Pickup</div>
  <div *ngIf="displayInfo.pickLocationName" class="line">{{ displayInfo.pickLocationName }}</div>
  <div class="line">{{ displayInfo.pickAddr }}</div>
  <div class="line">
    <img alt="Time Period" class="icon" src="assets/img/ic_shipment_info_date.svg" />
    {{ displayInfo.pickWindowTime }}
  </div>
</div>

<div *ngIf="displayInfo.dropAddr" class="group">
  <div class="group-title">Delivery</div>
  <div *ngIf="displayInfo.dropLocationName" class="line">{{ displayInfo.dropLocationName }}</div>
  <div class="line">{{ displayInfo.dropAddr }}</div>
  <div class="line">
    <img alt="Time Period" class="icon" src="assets/img/ic_shipment_info_date.svg" />
    {{ displayInfo.dropWindowTime }}
  </div>
</div>
