import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzListModule } from 'ng-zorro-antd/list';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzInputModule } from "ng-zorro-antd/input";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzTabsModule } from "ng-zorro-antd/tabs";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzEmptyModule } from "ng-zorro-antd/empty";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzResultModule } from "ng-zorro-antd/result";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { SearchBoxModule } from "@libs/search-box/search-box.module";
import { DetailModule } from "../../base/detail.module";
import { SelectBySearchingModule } from "@libs/select-client/module";
import { FormAddressUSModule } from "../../base/form-address-us/module";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { NzDrawerModule } from "ng-zorro-antd/drawer";
import { NzTagModule } from "ng-zorro-antd/tag";
import { PdfViewerModule } from "@libs/pdf-viewer/pdf-viewer.module";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { NzImageModule } from "ng-zorro-antd/image";
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { ModuleInputWarpIds } from '@wearewarp/ng-antd';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { WarehouseManifest } from ".";
import { ManifestListHeaderFilter } from "./list-header-filter";
import { ImportManifest } from "./import";
import { WarehouseManifestDetail } from "./detail";
import { ManifestDetailBasicInfo } from "./detail/components/basic-info";
import { ManifestDetailDocuments } from "./detail/components/documents";
import { ManifestDetailItems } from "./detail/components/items";
import { ManifestUploadBOLFiles } from "./detail/components/upload-bol-files";
import { ManifestUploadContainerImages } from "./detail/components/upload-container-images";
import { WarehouseManifestCartonDetail } from "./detail/components/carton-detail";
import { ManifestItemsListCarton } from "./detail/components/list-carton";
import { ManifestItemsListPallet } from "./detail/components/list-pallet";
import { WarehouseManifestPalletDetail } from "./detail/components/pallet-detail";
import { WarehouseManifestEditPallet } from "./detail/components/edit-pallet";
import { ManifestUploadLabelSKU } from "./detail/components/upload-label-sku";
import { LoadingComponent } from "./detail/components/loading/loading.component";
import { WarehouseManifestNavigationBar } from "./detail/components/navigation-bar";
import { ManifestDetailDeliveryPlanning } from "./detail/components/delivery-planning";
import { DisplayManifestShipmentWarpID } from "./detail/components/display-shipment-warpid";
import { TabsModule } from "@app/admin/base/tabs";
import { MarkRequiredModule } from '@app/admin/base/mark-required/module';
import { ShipmentManifestContainer } from "./create-shipment";
import { ShipmentManifestTabs } from "./create-shipment/components/shipment-tabs";
import { ShipmentManifestInfoSummary } from "./create-shipment/components/shipment-info";
import { FormManifestItems } from "./create-shipment/components/forms/items";
import { ShipmentManifestItems } from "./create-shipment/components/warp-carrier/items";
import { ShipmentWarpCarrierBasicInfo } from "./create-shipment/components/warp-carrier/basic-info";
import { FormShipmentManifestBasicInfo } from "./create-shipment/components/forms/basic-info";
import { ShipmentManifestAddPalletDlg } from "./create-shipment/components/forms/items/add-item-dlg";
import { UiCommonModule } from "../../../admin/components/common/module";
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SharedModule } from '@app/admin/shared/shared.module';
import { ShipmentWarpCarrierPickup } from "./create-shipment/components/warp-carrier/pickup";
import { FormShipmentLocationModule } from "../../shipment-entry/components/forms/shipment-location/module";
import { ShipmentWarpCarrierDelivery } from "./create-shipment/components/warp-carrier/delivery";
import { ShipmentWarpCarrierRevenue } from "./create-shipment/components/warp-carrier/revenue";
import { FormInputCostV3Module } from "@app/admin/shipment-entry/components/forms/input-cost-v3/module";
import { FormShipmentManifestRevenue } from "./create-shipment/components/forms/shipment-revenue";
import { ShipmentThirdPartyCarrierInfo } from "./create-shipment/components/third-party-carrier/carrier-info";
import { FormShipmentThirdPartyCarrierInfo } from "./create-shipment/components/forms/third-party-carrier-info";
import { ShipmentThirdPartyCarrierVehicle } from "./create-shipment/components/third-party-carrier/vehicle";
import { ShipmentThirdPartyCarrierPickup } from "./create-shipment/components/third-party-carrier/pickup";
import { ShipmentThirdPartyCarrierDelivery } from "./create-shipment/components/third-party-carrier/delivery";
import { MapModule } from "@app/admin/components/map/module";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzTableModule,
    NzTabsModule,
    NzDividerModule,
    NzEmptyModule,
    NzDropDownModule,
    NzResultModule,
    NzCollapseModule,
    NzDatePickerModule,
    NzButtonModule,
    NzSelectModule,
    NzIconModule,
    NzGridModule,
    NzListModule,
    NzCardModule,
    NzInputModule,
    NzFormModule,
    SearchBoxModule,
    DetailModule,
    SelectBySearchingModule,
    FormAddressUSModule,
    NzModalModule,
    NzSpinModule,
    NzModalModule,
    NzDrawerModule,
    NzTagModule,
    PdfViewerModule,
    NzImageModule,
    NzAutocompleteModule,
    NzRadioModule,
    NzSpaceModule,
    NzBreadCrumbModule,
    NzBadgeModule,
    NzAlertModule,
    NzCheckboxModule,
    NzToolTipModule,
    ModuleInputWarpIds,
    TabsModule,
    UiCommonModule,
    ScrollingModule,
    SharedModule,
    FormShipmentLocationModule,
    FormInputCostV3Module,
    MarkRequiredModule,
    MapModule,
  ],
  declarations: [
    WarehouseManifest,
    ManifestListHeaderFilter,
    ImportManifest,
    WarehouseManifestDetail,
    ManifestDetailBasicInfo,
    ManifestDetailDocuments,
    ManifestDetailItems,
    ManifestUploadBOLFiles,
    ManifestUploadContainerImages,
    ManifestUploadLabelSKU,
    ManifestItemsListCarton,
    ManifestItemsListPallet,
    WarehouseManifestCartonDetail,
    WarehouseManifestPalletDetail,
    WarehouseManifestEditPallet,
    LoadingComponent,
    WarehouseManifestNavigationBar,
    ManifestDetailDeliveryPlanning,
    DisplayManifestShipmentWarpID,
    ShipmentManifestContainer,
    ShipmentManifestTabs,
    ShipmentManifestInfoSummary,
    ShipmentManifestItems,
    ShipmentWarpCarrierBasicInfo,
    FormManifestItems,
    ShipmentManifestAddPalletDlg,
    FormShipmentManifestBasicInfo,
    ShipmentWarpCarrierPickup,
    ShipmentWarpCarrierDelivery,
    ShipmentWarpCarrierRevenue,
    FormShipmentManifestRevenue,
    ShipmentThirdPartyCarrierInfo,
    FormShipmentThirdPartyCarrierInfo,
    ShipmentThirdPartyCarrierVehicle,
    ShipmentThirdPartyCarrierPickup,
    ShipmentThirdPartyCarrierDelivery,
  ],
  exports: [
  ],
  providers: [],
})
export class ModuleCrossDockManifest {}
