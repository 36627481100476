<div
  style=" width: fit-content; padding: 15px; margin-bottom: 15px"
  *ngIf="showNetwork"
  transit-network
  [id]="_item.parent?.id"
  [showOrderDetail]="true"
  [compact]="true"
></div>
<nz-divider></nz-divider>
<div class="font-bold bottom10 flex-space-between">
  <div>Items</div>
  <button nz-button nzType="text" (click)="onViewItemsHistory()">
    <i nz-icon nzType="history" nzTheme="outline"></i>
    Items history
  </button>
</div>
<div
  *ngIf="shipmentItems && shipmentItems.length"
  style="border: solid 1px #eee; background-color: white"
  class="bottom10"
>
  <div class="flex shipping-item font-medium">
    <div style="width: 150px">Code</div>
    <div style="width: 160px">Name</div>
    <div style="width: 150px">Dimension</div>
    <div style="width: 100px">Weight</div>
    <div style="width: 150px">Storage Date</div>
    <div style="width: 430px">Warehouse Task</div>
  </div>
  <div
    class="flex shipping-item"
    *ngFor="let x of shipmentItems"
    warehouse-shipment-item
    [item]="x"
    [warehouse]="warehouse"
    [warehouseTasks]="x?.warehouseTasks"
    [outboundLeg]="outboundLeg"
    (onRefresh)="onRefresh.emit()"
    (onRemove)="resetTask($event)"
  ></div>
</div>
<nz-divider></nz-divider>
<div class="font-bold" style="margin-bottom: 15px">
  <ng-container *ngIf="taskUploadBol">
    <ng-container *ngIf="taskUploadBol?.metadata?.isWarning && taskUploadBol.status === 'pending'">
      <button
        class="pending" nz-button
        nzSize="small"
        [disabled]="isUploading"
        nz-popconfirm
        [nzPopconfirmTitle]="taskUploadBol?.metadata?.reason"
        nzPopconfirmPlacement="top"
        nzOkText="Keep Doing Task"
        nzCancelText="Skip Task"
        (nzOnConfirm)="onAddPodFile('BOL')"
        (nzOnCancel)="skipTask(taskUploadBol)"
      >
        <i *ngIf="isUploading" nz-icon nzType="loading" nzTheme="outline"></i>
        <span *ngIf="!isUploading" nz-icon nzType="exclamation-circle" nzTheme="outline"></span> 
        (Task {{noOfTaskUploadBol}}) Upload BOL
        <span nz-icon nzType="warning" nzTheme="outline"></span>
      </button>
    </ng-container>

    <ng-container *ngIf="!taskUploadBol?.metadata?.isWarning || isCompleted(taskUploadBol.status)">
      <button
        [ngClass]="{
          'completed': isCompleted(taskUploadBol?.status), 
          'pending': taskUploadBol?.status === 'pending'
        }"
        nz-button
        nzSize="small"
        [disabled]="isUploading"
        (click)="onAddPodFile('BOL')"
      >
        <i *ngIf="isUploading" nz-icon nzType="loading" nzTheme="outline"></i>
        <span 
          *ngIf="!isUploading" nz-icon 
          [nzType]="isCompleted(taskUploadBol?.status) ? 'check-circle' : 'exclamation-circle'" 
          nzTheme="outline"
        ></span> 
        <ng-container *ngIf="['completed', 'pending'].includes(taskUploadBol?.status)">
          (Task {{noOfTaskUploadBol}}) Upload BOL
        </ng-container>
        <ng-container *ngIf="taskUploadBol?.status === 'skipped'">
          (Task {{noOfTaskUploadBol}} - Skipped) Upload BOL
        </ng-container>
        <span style="margin-left: 3px" *ngIf="_item.bols?.length"> ({{_item.bols?.length}})</span>
      </button>
    </ng-container>
  </ng-container>
</div>

<div *ngIf="shipmentUploadBol">
  <input
    #inputFile
    type="file"
    hidden
    accept=".pdf,.png,.jpg,.jpeg"
    (change)="onFileSelected(inputFile)"
    [id]="'uploadPODInput-' + this.shipmentUploadBol?.id"
  />
</div>

<div *ngIf="pods && pods.length" class="pods-wrapper">
  <view-shipment-bol *ngFor="let pod of pods" [pod]="pod" (onRemove)="removeBol($event)"></view-shipment-bol>
</div>
<nz-divider></nz-divider>

<button style="background: #f6ffed; color: blue; border-color: #ffe58f" nz-button nzSize="small" (click)="comfirmCorrectDataAndStatus()">
  <i nz-icon nzType="{{isDataCorrecting ? 'loading' : 'exclamation-circle'}}" nzTheme="outline"></i> Correct data & status
</button>

<button style="background: #f6ffed; color: red; border-color: #ffe58f; margin-left: 5px" nz-button nzSize="small" (click)="comfirmArchiveJob()">
  <i nz-icon nzType="{{isArchiving ? 'loading' : 'exclamation-circle'}}" nzTheme="outline"></i> Move to Archive
</button>