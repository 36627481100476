<div *ngIf="file" class="center-vertical gap5">
    <a (click)="downloadInvoice()">
      <span *ngIf="!file?.isDownloading" class="right5" nz-icon nzType="download" nzTheme="outline" ></span>
      <span *ngIf="file?.isDownloading" nz-icon nzType="loading" nzTheme="outline"></span>
    </a>
    <a (click)="onViewInvoice()"><span>{{fileName}}</span></a>
</div>
<div *ngIf="!file">
  <nz-tag nzColor="warning">N/A</nz-tag>
    <a (click)="onClickAddFile()">{{uploadTxt}}</a>
    <input #inputFile type="file" hidden accept=".pdf,.png,.jpg,.jpeg" (change)="onFileSelected(inputFile)"
            id="input-{{uid}}">
    <span class="left5" *ngIf="isUploading" nz-icon nzType="loading" nzTheme="outline"></span>
</div>
