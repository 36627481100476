import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileComponent } from './file.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTagModule } from 'ng-zorro-antd/tag';

@NgModule({
  declarations: [FileComponent],
  imports: [
    CommonModule,
    NzIconModule,
    NzTagModule
  ],
  exports:[
    FileComponent
  ]
})
export class FileToolModule { }
