import {Component, Input} from '@angular/core';
import { Const } from "@const/Const";
import { FormControl } from "@angular/forms";
import { InputHelper } from "@services/input-helper";
import { BaseFormItem } from "@app/admin/base/form-item";
import { Utils } from "@services/utils";
import { DialogService } from '@dialogs/dialog.service';
import { CustomerAddDepositAmount } from '../deposit-amount/create';
import { HistoryDepositAmount } from '../deposit-amount/histories';

@Component({
  selector: "[client-detail-payment]",
  templateUrl: "./index.html",
  styleUrls: [
    "./index.scss",
    "../../../../detail.scss",
    "../../../../../../styles/row-col.scss",
    "../../../../../../styles/form-v2.scss",
  ],
})
// Payment Information
export class ClientDetailPayment extends BaseFormItem {
  protected formGroupDeclaration: FormGroupDeclaration = {
    paymentTerm: {label: 'Payment Terms'},
    payments: {
      label: "",
      type: "formArray",
      initialValue: [],
      childItem: {
        id: { label: "" },
        bankName: { label: "Bank Name", placeHolder: "Enter Bank Name", required: true },
        bankRoutingNumber: { label: "Routing Number", placeHolder: "Enter Routing Number", required: true },
        bankAccountNumber: {
          label: "Bank Account Number",
          placeHolder: "Enter Bank Account Number",
          required: true,
          validators: [this.validateAccountNumber],
        },
        ein: { label: "EIN Number", placeHolder: "Enter EIN Number", required: true },
        ssn: { label: "SSN Number", placeHolder: "Enter SSN Number", notAcceptEmpty: false },
      },
    },
  };
  private originModel;
  @Input() set myModel(value) {
    let currentModel = this.model;
    this.model = value;
    this.originModel = {...value};
    if(currentModel) this.bindDataModel(value);
  }

  public allPaymentTerms = ['prepay'];
  public totalDepositAmount = 0;

  ngOnInit(): void {
    super.ngOnInit();
    this.disableEditing();
    this.getDataPaymentDeposit();
  }

  disableEditing() {
    this.setEnableFormGroup(false);
    this.isEditing = false;
  }

  protected getApiUrl(): string {
    return Const.APIURI_CLIENTS;
  }

  protected onUpdateSuccess(resp) {
    super.onUpdateSuccess(resp);
    this.disableEditing();
    this.originModel = {...this.model};
  }

  get isExistPaymentInfo() {
    return this.getItemValue("payments")?.length ? true : false;
  }

  get isPrePay() {
    return this.getItemValue("paymentTerm") === "prepay";
  }

  validateAccountNumber(input: FormControl) {
    const value = input?.value?.trim();
    if (InputHelper.isBankAccountNumberValid(value)) return null;
    return {
      invalid: {
        en: "Invalid account number",
      },
    };
  }

  protected getFormData_JSON(isCreateNew: boolean): object {
    let data: any = super.getFormData_JSON(true);
    data.payments.forEach((item) => {
      //generate System ULID for bank info
      if (!item.id) item.id = Utils.generateULID();
    });
    return data;
  }

  onBtnCancel() {
    this.myModel = {...this.originModel};
    this.createFormInput(this.model);
    this.disableEditing();
  }

  isInputsChange() {
    return this.isFormDataChanged();
  }

  formatMoney(amt: number | string) {
    return InputHelper.formatMoney2(`${amt || 0}`);
  }

  private getDataPaymentDeposit() {
    const url = `${Const.APIV2(Const.APIURI_CLIENTS)}/${this.model?.id}/payment-deposit`;
    this.api.GET(url).subscribe(
      resp => {
        this.totalDepositAmount = (resp?.data?.list_data || []).reduce((sum, item) => sum + (item.info?.amount ?? 0), 0);
      }, err => {
        this.showErr(err);
      }
    );
  }

  onBtnAddDeposit() {
    DialogService.openFormDialog1(CustomerAddDepositAmount, {
      nzComponentParams: {
        closeOnSuccess: true,
        onDone: () => { this.getDataPaymentDeposit() },
        onSave: data => {
          const url = `${Const.APIV2(Const.APIURI_CLIENTS)}/${this.model?.id}/payment-deposit`;
          return this.api.POST(url, data);
        }
      },
      nzClassName: "modal",
    });
  }

  onBtnViewHistoryDeposit() {
    DialogService.openDialog(HistoryDepositAmount, {
      nzComponentParams: {
        clientId: this.model?.id,
      },
      nzClassName: "modal-xl",
    })
  }

}
