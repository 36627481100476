<div #filter filter-layout [showClearButton]="false"></div>

<ng-template #tplFilter_client let-key="key" let-form="form">
  <ng-container [formGroup]="form">
    <div style="min-width: 270px">
      <select-by-searching
        [version]="2" type="client" 
        [showSubClient]="true"
        [formControlName]="key"
        [placeholder]="getFilterPlaceHolder(key)"
        (ngModelChange)="onFilterChange(key, $event)"
      >
      </select-by-searching>
    </div>
  </ng-container>
</ng-template>

<ng-template #tplFilter_status let-key="key" let-form="form">
  <ng-container [formGroup]="form">
    <nz-select
      nzBackdrop="true"
      nzAllowClear
      nzShowSearch
      [formControlName]="key"
      [nzPlaceHolder]="getFilterPlaceHolder(key)"
      (ngModelChange)="onFilterChange(key, $event)"
      style="min-width: 270px"
    >
      <nz-option
        *ngFor="let item of statusOptions"
        [nzValue]="item"
        [nzLabel]="getStatusName(item)"
      ></nz-option>
    </nz-select>
  </ng-container>
</ng-template>