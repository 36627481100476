<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>History DEPOSIT Amount</div>
  </div>
</ng-template>
<div>
  <div *ngIf="!isLoading" class="bottom20">
    <span>Total Amount:</span>
    <span class="left5 bold">{{ formatMoney(totalDepositAmount) }}</span>
  </div>
  <nz-table #nzTable [nzData]="listData"
    [nzLoadingIndicator]="tplLoading" [nzHideOnSinglePage]="true"
    [(nzPageIndex)]="pageIndex"
    [nzLoading]="isLoading" [nzNoResult]="tplNoData"
    [nzPageSize]="limit"  [nzSize]="'default'" [nzTotal]="totalCount"
    nzBordered="true" nzSize="small">
    <ng-template #tplNoData>
      <div *ngIf="!isLoading" class="nodata">
        <i nz-icon nzTheme="outline" nzType="search"></i>
        No data
      </div>
    </ng-template>
    <ng-template #tplLoading>
      <div class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>
    </ng-template>
    <thead>
      <tr>
        <th>No.</th>
        <th>Amount</th>
        <th>Payment Date</th>
        <th>References</th>
        <th>Created at</th>
        <th>Created by</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of nzTable.data; let i = index">
        <tr>
          <td>
            {{ getRowNumber(i) }}
          </td>
          <td>
            {{ item.displayInfo?.amount || 'N/A' }}
          </td>
          <td>
            {{ item.displayInfo?.paymentDate || 'N/A' }}
          </td>
          <td>
            {{ item.displayInfo?.refs || '-' }}
          </td>
          <td>
            {{ item.displayInfo?.createdAt || 'N/A'}}
          </td>
          <td>
            {{ item.displayInfo?.createdBy || 'N/A'}}
          </td>
        </tr>
      </ng-container>
    </tbody>
  </nz-table>
</div>
