<div class="list-container">
    <ng-container *ngFor="let item of list">
        <nz-badge *ngIf="isPOD" [nzCount]="getTemplateType(item)" nzOffset="[200,200]" [nzStyle]="{marginLeft: '48px', possiton:'relative'}">
            <ng-container *ngIf="isPdf(item)">
                <canvas id="{{randomId}}-{{item._id}}" width="48px" height="65px">
                </canvas>
            </ng-container>
            <ng-container *ngIf="!isPdf(item)">
                <div style="width: 48px; height: 65px; overflow: hidden; display: flex; align-items: center;" (click)="showItemPhoto(item.url); $event.stopPropagation()">
                    <img [src]="item.url" alt="" width="48px">
                </div>
            </ng-container>
        </nz-badge>
        <ng-container *ngIf="!isPOD">
            <ng-container *ngIf="isPdf(item)">
                <canvas id="{{randomId}}-{{item._id}}" width="48px" height="65px">
                </canvas>
            </ng-container>
            <ng-container *ngIf="!isPdf(item)">
                <div style="width: 48px; height: 65px; overflow: hidden; display: flex; align-items: center;" (click)="showItemPhoto(item.url); $event.stopPropagation()">
                    <img [src]="item.url" alt="" width="48px">
                </div>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
<ng-template #needConfirm>
    <span nz-icon style="font-size: 16px; position: absolute; bottom: -5px; right: -5px;" nzType="clock-circle" [nzTheme]="'twotone'" [nzTwotoneColor]="'#0A6CF5'"></span>
</ng-template>
<ng-template #hasBeenConfirmed>
    <span nz-icon style="font-size: 16px; position: absolute;  bottom: -5px; right: -5px;" nzType="check-circle" [nzTheme]="'twotone'" [nzTwotoneColor]="'#00B227'"></span>
</ng-template>