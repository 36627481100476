<div class="dashboard-child-container no-padding list">
  <div class="list-header">
    <div customer-lane-filter style="flex: 1"></div>
    <button nz-button (click)="onBtnAdd()"><i nz-icon nzType="plus" nzTheme="outline"></i>Create New</button>
  </div>
  <div class="list-body">
    <nz-table
      #nzTable nzBordered="true"
      [nzData]="listData" nzSize="small"
      [nzTotal]="totalCount" [nzFrontPagination]="false"
      [nzShowPagination]="totalCount > listData.length"
      [nzPageSize]="limit"
      [nzPageIndex]="pageIndex"
      [nzLoading]="isLoading"
      [nzLoadingIndicator]="tplLoading"
      [nzNoResult]="tplNoData"
      [nzSize]="'default'"
      (nzPageIndexChange)="onDataListPageChanged($event)">
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </ng-template>
      <thead (nzSortOrderChange)="onSortOrderChange($event)">
        <tr>
          <th>Dedicated Lane ID</th>
          <th>Customer</th>
          <th>Shipment Type</th>
          <th>Equipment</th>
          <th>Origin</th>
          <th>Destination</th>
          <th>Rate</th>
          <th>Created when</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of listData; let i = index">
          <td>
            <a [routerLink]="[routeAdminCustomerLaneList, item.id]">{{item.clientLaneCode}}</a>
          </td>
          <td>
            <a class="link" target="_blank" [routerLink]="[routeAdminClientList, item.client?.id]">{{item.client?.name}}</a>
          </td>
          <td>
            {{ getShipmentTypeName(item.shipmentType) }}
          </td>
          <td>
            {{ item.displayEquipment }}
          </td>
          <td>
            {{ getOrigin(item) }}
          </td>
          <td>
            {{ getDestination(item) }}
          </td>
          <td>
            {{ formatMoney(item.rate) }}
          </td>
          <td>{{displayDateTimeDB(item.createdWhen)}}</td>
        </tr>
      </tbody>
    </nz-table>
    <div w-paginator
      [currentPage]="currentPage"
      [isLastPage]="isLastPage"
      [fnCount]="countTotal"
      (pageChange)="onDataListPageChanged($event)">
    </div>
  </div>
</div>