<div class="dashboard-child-container no-padding list">
  <div class="list-header" style="align-items: center; gap: 20px;">
    <div class="flex1">
      <search-box #searchBox style="width: 300px; height: 38px;"
        [placeHolder]="'Search'"
        [searchKeyword]="search"
        (doSearch)="doSearch($event)">
      </search-box>
    </div>
    <button nz-button nzType="primary" (click)="createExternalRouteConnecting()">
      <i nz-icon nzType="plus" nzTheme="outline"></i>Create New Connect
    </button>
  </div>
  <div class="list-body">
    <nz-table #nzTable nzBordered="true" 
      [nzData]="listData" 
      [nzTotal]="totalCount"
      [nzFrontPagination]="false" 
      [nzShowPagination]="totalCount > listData.length" 
      [nzPageSize]="limit" 
      [nzPageIndex]="pageIndex"
      [nzLoading]="isLoading" 
      [nzLoadingIndicator]="tplLoading" 
      [nzNoResult]="tplNoData" 
      [nzSize]="'default'"
      (nzPageIndexChange)="onDataListPageChanged($event)">
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </ng-template>
      <thead>
        <tr>
          <th>Warehouse Origin</th>
          <th>Warehouse Destination</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of listData; let i = index">
          <td>
            {{getWarehouseName(item?.metadata?.fromWarehouse)}}
          </td>
          <td>
            <div *ngFor="let w of item?.metadata?.toWarehouses || []">
              {{getWarehouseName(w)}}
            </div>
          </td>
          <td>
            <span nz-icon style="cursor: pointer;" nzType="edit" nzTheme="outline" (click)="updateExternalRouteConnecting(item)"></span>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>