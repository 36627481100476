<div class="dashboard-child-container no-padding list">
  <div *ngIf="isAdmin" class="list-header">
    <search-box
      #searchBox (doSearch)="doSearch($event)"
      [searchKeyword]="searchKeyword"
      placeHolder="Search by name or ID"
    ></search-box>
    <div class="flex1"></div>
    <button (click)="onBtnAdd()" *ngIf="requirePermissions([PermissionCode.client.create])" nz-button><i nz-icon nzTheme="outline" nzType="plus"></i>Create
      new customer
    </button>
    <button (click)="onBtnExport()" [nzLoading]="isExporting" nz-button>
      <i nz-icon nzTheme="outline" nzType="file-excel"></i>
      Export
    </button>
  </div>

  <div class="list-body">
    <nz-table
      #nzTable (nzPageIndexChange)="onDataListPageChanged($event)"
      [nzData]="listData" [nzFrontPagination]="false"
      [nzLoadingIndicator]="tplLoading" [nzLoading]="isLoading"
      [nzNoResult]="tplNoData"
      [nzPageSize]="limit"
      [nzPageIndex]="pageIndex"
      [nzShowPagination]="totalCount > listData.length"
      [nzSize]="'default'"
      [nzTotal]="totalCount"
      nzBordered="true"
      nzSize="small">
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata"><i nz-icon nzTheme="outline" nzType="search"></i>No data</div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>
      </ng-template>
      <thead (nzSortOrderChange)="onSortOrderChange($event)">
      <tr>
        <th></th>
        <th [nzSortFn]="true" nzColumnKey="warpId" nzSortOrder="null" nzWidth="120px">WARP ID</th>
        <th [nzSortFn]="true" nzColumnKey="name" nzSortOrder="null">Name</th>
        <th>Address</th>
        <th nzWidth="130px">Locations</th>
        <th nzWidth="130px">Sub Accounts</th>
        <th *ngIf="shouldShowDenim()">Denim Customer</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let item of listData; let i = index">
        <!--        list client-->
        <tr>
          <td [(nzExpand)]="item.expand" style="width: 40px"><span *ngIf="item.metadata?.subClients" (click)="item.expand = !item.expand"
                                                                   [nzType]="item.expand?'minus':'plus'" nz-icon
                                                                   nzTheme="outline"></span></td>
          <td>
            <a [routerLink]="[routeAdminClientList, item.id]" class="inherit">{{item.warpId}}</a>
          </td>
          <td>
            <a [routerLink]="[routeAdminClientList, item.id]">{{item.name}}</a>
          </td>
          <td>{{getAddressText(item.address)}}</td>
          <td>
            <a [routerLink]="[routeAdminClientList, item.id, 'locations']">{{getLocationCount(item)}}</a>
          </td>
          <td>{{item.subClients?.length || ''}}</td>
          <td *ngIf="shouldShowDenim()">
            <div>
              <span>{{getDenimInfo(item)}}</span>
              <button title="edit" nz-button nzType="link" (click)="onBtnEditDenim(item)"><i nz-icon nzType="edit"></i></button>
            </div>
          </td>
        </tr>
        <!--        sub client-->
        <tr [nzExpand]="item.expand">
          <nz-table #innerTable [nzData]="item.subClients" [nzShowPagination]="false" nzSize="middle">
            <thead>
            <tr>
              <th nzWidth="120px">Warp ID</th>
              <th>Name</th>
              <th>Address</th>
              <th nzWidth="100px">Locations</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let subItem of innerTable.data">
              <td><a [routerLink]="[routeAdminClientList, subItem.id]" class="inherit">{{subItem.warpId}}</a></td>
              <td><a [routerLink]="[routeAdminClientList, subItem.id]">{{subItem.name}}</a></td>
              <td>{{getAddressText(subItem.address)}}</td>
              <td><a [routerLink]="[routeAdminClientList, subItem.id, 'locations']">{{getLocationCount(subItem)}}</a></td>
            </tr>
            </tbody>
          </nz-table>
        </tr>
      </ng-container>
      </tbody>
    </nz-table>
  </div>

</div>

<!-- <ng-template #tplEditStatus let-item>
  <div style="display: flex; justify-content: center;">
    <nz-select nzBackdrop="true" style="width: 250px;" [(ngModel)]="item.tmp.status" (ngModelChange)="onStatusChange(item)">
      <nz-option *ngFor="let item of allStatus" [nzValue]="item" [nzLabel]="getStatusClient(item)"></nz-option>
    </nz-select>
  </div>
</ng-template> -->
