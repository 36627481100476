<div #formComp form-shipment-location type="DROPOFF" [model]="formModel" [viewTemplate]="tplLocation"></div>

<ng-template #tplLocation>
  <form [formGroup]="formComp.formInput" nz-form>
    <div style="display: flex;">
      <div *ngIf="'locationName' as key" class="flex1 right20" style="max-width: 300px;">
        <div class="form-label-v2">{{formComp.getLabel(key)}}<span *ngIf="formComp.isRequired(key)" mark-required></span></div>
        <nz-form-item>
          <nz-form-control>
            <input nz-input [formControlName]="key" [nzAutocomplete]="autoComplete"
              (ngModelChange)="formComp.onFilterTextChange($event)">
            <nz-autocomplete #autoComplete [nzDefaultActiveFirstOption]="false">
              <cdk-virtual-scroll-viewport itemSize="32" minBufferPx="256" maxBufferPx="256" style="height: 256px;">
                <nz-auto-option *cdkVirtualFor="let item of formComp.locationsFiltered; let i = index" [nzValue]="item.name"
                  (selectionChange)="formComp.onLocationSelected($event, item)">
                  {{item.name}}
                </nz-auto-option>
              </cdk-virtual-scroll-viewport>
            </nz-autocomplete>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div *ngIf="'addr' as key" class="flex2 right20">
        <div class="form-label-v2">{{formComp.getLabel(key)}}<span *ngIf="formComp.isRequired(key)" mark-required></span></div>
        <nz-form-item>
          <nz-form-control>
            <form-address [formControlName]="key"></form-address>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div *ngIf="'refNums' as key" class="{{key}}">
      <div class="form-label-v2">{{formComp.getLabel(key)}}<span *ngIf="formComp.isRequired(key)" mark-required></span></div>
      <ng-container [formArrayName]="key">
        <div *ngFor="let f of formComp.getFormArrayControls(key); let arrIndex = index" class="refNum" style="display: flex;">
          <nz-form-item>
            <nz-form-control>
              <input nz-input [formControlName]="arrIndex" style="margin-right: 20px">
              <a *ngIf="formComp.shouldShowButtonRemoveFormArray(key, arrIndex)" (click)="formComp.onBtnRemoveFormArray(key, arrIndex)" class="danger right10">
                <i nz-icon nzType="close-square" nzTheme="outline"></i> Remove this reference number
              </a>
              <a *ngIf="formComp.shouldShowButtonAddFormArray(key, arrIndex)" (click)="formComp.onBtnAddFormArray(key)">Add more reference number</a>
            </nz-form-control>
          </nz-form-item>
        </div>
      </ng-container>
    </div>
    <div *ngIf="'primaryContact' as key" class="{{key}}">
      <div class="form-label-v2 bottom5 medium">{{formComp.getLabel(key)}}<span *ngIf="formComp.isRequired(key)" mark-required></span></div>
      <form nz-form [formGroupName]="key">
        <div class="flex">
          <div *ngFor="let childKey of ['fullName', 'phone', 'email']; let i = index;" class="flex1" [ngClass]="{'left20': i > 0}">
            <div class="form-label-v2">{{formComp.getLabel(join(key, childKey))}}<span *ngIf="formComp.isRequired(join(key, childKey))" mark-required></span></div>
            <ng-container [ngSwitch]="childKey">
              <div *ngSwitchCase="'phone'" [ngClass]="childKey">
                <nz-form-item>
                  <nz-form-control>
                    <nz-input-group nzCompact>
                      <input nz-input [formControlName]="childKey" style="width: 70%;">
                      <input nz-input [formControlName]="'phoneExtension'" [placeholder]="formComp.getPlaceHolder(join(key, 'phoneExtension'))" style="width: 30%;">
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div *ngSwitchDefault [ngClass]="childKey">
                <nz-form-item>
                  <nz-form-control>
                    <input nz-input [formControlName]="childKey">
                  </nz-form-control>
                </nz-form-item>
              </div>
            </ng-container>
          </div>
        </div>
      </form>
    </div>
  </form>
</ng-template>
