import { Component, TemplateRef, ViewChild } from "@angular/core";
import { Const } from "@const/Const";
import { Utils } from "@services/utils";
import { NzModalRef } from "ng-zorro-antd/modal";
import { BaseList } from "../base/list";
import { MasterData } from '@services/master.data';
import { ApiService } from "@services/api.service";
import { SearchDenimCompany } from "../fin/components/fin-statement/denim/search-denim-company";
import { ResponseDenimFetchingRelationship } from "../fin/components/fin-statement/interface";
import { DialogService } from "@dialogs/dialog.service";

@Component({
  selector: 'order-list',
  templateUrl: './list.html',
  styleUrls: ['../list.scss','./list.client.scss']
})
export class ClientList extends BaseList {

  private _topNavOps;
  public get topNavOps(): TopNavOptions {return this._topNavOps}

  constructor() {
    super();
    this._topNavOps = {
      title: 'Shipper Management',
      refreshFn: this.onBtnRefresh.bind(this),
      buttons: []
    }
    if (this.isAdmin) {
      this._topNavOps.buttons.push({title: 'Create new customer', nzIcon: 'plus', action: this.onBtnAdd.bind(this)});
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected getApiUrl(): string {
    return Const.APIURI_CLIENTS;
  }

  protected confirmDeleteMessage(item): string {
    return `Delete customer <b>${item.name}</b>?`;
  }

  // private dlgOrderStatus: NzModalRef;
  // @ViewChild('tplEditStatus') tplEditStatus: TemplateRef<any>;

  getLocationCount(item) {
    if (Utils.isArrayNotEmpty(item.warehouses)) {
      return item.warehouses.length;
    }
    return 0;
  }
  protected onGetDataSucceeded(resp) {
    this.fetchSubAccounts(this.listData);
    for (let item of this.listData) {
      if (item.address?.country) {
        let country = MasterData.getCountryByAlpha2Code(item.address.country);
        if (country?._id) item.address.countryId = country._id;
      }
    }
  }

  public isExporting = false;
  onBtnExport() {
    this.isExporting = true;
    let query = this.prepareParamGetList();
    query.isDownload = true;
    this.api.postExport(`${Const.APIURI_CLIENTS}/export`, query).subscribe(
      resp => {
        ApiService.handleDownloadResponse(resp);
        this.isExporting = false;
      }, err => {
        this.showErr(err);
        this.isExporting = false;
      }
    );
  }

  // onBtnEditStatus(item) {
  //   item.tmp = Object.assign(item.tmp || {}, {status: item.status});
  //   this.dlgOrderStatus = this.modalService.create({
  //     nzContent: this.tplEditStatus,
  //     nzComponentParams: item,
  //     nzTitle: `Update status for <b>${item.name}</b>`,
  //     nzKeyboard: false,
  //     nzMaskClosable: false,
  //     nzOkDisabled: true,
  //     nzOnOk: () => {
  //       this.updateStatus(item);
  //     }
  //   });
  // }

  onStatusChange(item) {
    // this.dlgOrderStatus.getConfig().nzOkDisabled = item.status == item.tmp.status;
  }

  // private updateStatus(item) {
  //   item.onProgress = true;
  //   let url = `${this.getApiUrl()}/${item._id}`;
  //   let params = {status: item.tmp.status};
  //   this.api.PUT(url, params).subscribe(resp => {
  //     this.onUpdateItemSuccess(resp.data);
  //   }, err => {
  //     this.showErr(err);
  //     item.onProgress = false;
  //   });
  // }
  fetchSubAccounts(clients:any[]) {
    if(clients.length==0) return;
    const apiUrl = Const.APIV2(`${Const.APIURI_CLIENTS}/sub-clients?${clients.map(client=>`clientIds[]=${client.id}`).join("&")}`);
    this.api.GET(apiUrl).subscribe(
      (resp) => {
        const listData = resp?.data?.list_data || [];
        let isSearching = this.queryParams['search'];
        clients.forEach(client =>{
          client.subClients = listData.filter(item => item.metadata?.parentClient===client.id);
          if(isSearching && client.metadata?.subClients){
            client.expand = true;
          }
        });
      },
      (err) => {}
    );
  }

  onBtnEditDenim(item) {
    const finAccount = this.getFindAccount(item);
    DialogService.openFormDialog1(SearchDenimCompany, {
      nzComponentParams: {
        companyType: 'debtor',
        accountInfo: {
          ...item,
          finAccount
        },
        selectedCompanies: this.getDenimCompanies(item),
        onClose: (reload: boolean) => {
          if (reload) {
            this.getData();
          }
        }
      },
      nzClassName: "modal-lg",
    });
  }

  getDenimInfo(item) {
    const denimCompanies = this.getDenimCompanies(item);
    if (!denimCompanies || denimCompanies.length === 0) return 'N/A';
    const denimInfo = denimCompanies.map(company => company?.company?.company_name).join(', ');
    return denimInfo;
  }

  getDenimCompanies(item) {
    const finAccounts = item.finAccounts || [];
    const receivableAccount = finAccounts.find(acc => acc.type === 'receivable');
    if (!receivableAccount || !receivableAccount.denimPaymentServiceInfo) return [];

    return Array.isArray(receivableAccount.denimPaymentServiceInfo) ? receivableAccount.denimPaymentServiceInfo: [receivableAccount.denimPaymentServiceInfo];
  }

  getFindAccount(item) {
    return item.finAccounts.find(acc => acc.type === 'receivable');
  }

  shouldShowDenim() {
    return this.isSysAdmin || this.isAccounting;
  }
}
