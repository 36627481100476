import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { DialogService } from "@dialogs/dialog.service";
import { ShipmentManifestContainer } from "../../../create-shipment";
import _ from "underscore";
import { Utils } from "@services/utils";
import { ShipmentManifestCreateMode } from "@app/enum";

@Component({
  selector: '[manifest-detail-delivery-planning]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss',]
})
export class ManifestDetailDeliveryPlanning extends BaseComponent {

  public isLoading: boolean = true;
  @Input() manifestId: string;
  @Input() clientId: string;
  @Input() warehouseId: string;
  public listOfData: any[] = [];

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getData(); 
  }

  private getData() {
    if (!this.manifestId) return;
    this.isLoading = true;
    this.api.GET(`${Const.APIURI_WAREHOUSE_MANIFEST}/${this.manifestId}/data`).subscribe(
      resp => {
        this.processListData(resp?.data);
        this.getDataPalletCreated();
        this.isLoading = false;
      }, err => {
        this.showErr(err);
        this.isLoading = false;
      }
    );
  }

  private processListData(data) {
    const cartons = data?.cartons || [];
    const groupByCarton = _.groupBy(cartons, (item) => {
      let carrier = item.carrier || '';
      let consignee = item.consignee || '';
      return `${carrier}---${consignee}`;
    });
    let listOfData = [];
    for (let arrItem of Object.values(groupByCarton)) {
      if (!Utils.isArrayNotEmpty(arrItem)) continue;
      const firstItem = arrItem[0];
      let palletIds = arrItem.map(it => it.palletId).filter(x=>x);
      palletIds = Utils.uniqElementsArray(palletIds);
      const obj = {
        carrier: firstItem.carrier,
        consignee: firstItem.consignee,
        numberOfCartonImported: arrItem.length,
        numberOfPallets: palletIds.length,
        numberOfCartonSeparate: arrItem.filter(it => !it.palletId).length,
        numberOfPalletDoHaveShipment: palletIds.length,
        shipmentsCreated: [],
        palletIds,
        consigneeInfo: {
          addr: {
            street: firstItem.consigneeStreet1,
            street2: firstItem.consigneeStreet2,
            city: firstItem.consigneeCity,
            state: firstItem.consigneeState,
            zipcode: firstItem.consigneeZipCode,
            countryAlpha2Code: firstItem.consigneeCountry,
          },
          contact: {
            fullName: firstItem.consigneeContactName,
            email: firstItem.consigneeContactEmail,
            phone: firstItem.consigneeContactPhone,
          }
        }
      }
      listOfData.push(obj);
    }
    this.listOfData = listOfData;
  }

  private async getDataPalletCreated() {
    let url = `${Const.API_WAREHOUSE_MANAGEMENT('shipment-items')}/?manifestId=${this.manifestId}`;
    this.api.GET(url).subscribe(
      (resp) => {
        if (!resp?.data?.list_data?.length) return;
        const palletDic: any = {}
        const shipmentOfPalletDic: any = {}
        for (let item of resp.data.list_data) {
          if (!item.manifestId || !item.manifestPalletId || !item.shipment?.id) continue;
          if (!palletDic[item.manifestPalletId]) palletDic[item.manifestPalletId] = item;
          if (!shipmentOfPalletDic[item.manifestPalletId]) {
            shipmentOfPalletDic[item.manifestPalletId] = item.shipment;
          }
        }
        const listOfPalletHaveShipment = Object.keys(palletDic);
        for (let item of this.listOfData) {
          const palletIds = item.palletIds;
          const palletIdDoHaveShipment = palletIds.filter(it => !listOfPalletHaveShipment.includes(it));
          const palletIdHaveShipment = palletIds.filter(it => listOfPalletHaveShipment.includes(it));
          let arrShipment = palletIdHaveShipment.map(palletId => shipmentOfPalletDic[palletId]).filter(x=>x);
          let group = _.groupBy(arrShipment, 'id');
          let shipments = _.values(group).map(arr => {
            return {
              id: arr?.[0]?.id,
              warpId: arr?.[0]?.warpId,
              orderId: arr?.[0]?.orderId,
            }
          });
          item.numberOfPalletDoHaveShipment = palletIdDoHaveShipment.length;
          item.shipmentsCreated = shipments;
        }
      },
      (err) => {
        this.showErr(err);
      }
    );

  }

  onBtnCreateShipment(item) {
    DialogService.openDialog(ShipmentManifestContainer, {
      replaceWrapClassName: true,
      nzClosable: false,
      nzMaskClosable: false,
      nzClassName: 'modal-fullscreen modal-no-padding',
      nzComponentParams: {
        modelManifest: { 
          manifestId: this.manifestId,
          clientId: this.clientId,
          warehouseId: this.warehouseId,
          createMode: item.carrier == 'WARP' ? ShipmentManifestCreateMode.warpCarrier : ShipmentManifestCreateMode.thirdPartyCarrier,
          carrier: item.carrier,
          consignee: item.consignee,
          consigneeInfo: item.consigneeInfo,
        },
        onComplete: (data) => { this.getData() },
      }
    });
  }

  copyAddress(event, addr) {
    let content = this.getAddressText(addr)

    //nếu giữ phím ctrl hoặc Command rồi click thì sẽ copy JSON. Phục vụ debug và dev
    if (event.ctrlKey || event.metaKey) {
      content = JSON.stringify(addr, null, 2)
    }

    Utils.copyTextToClipboard(content, () => {
      this.notification.success('Copy address', 'Address has been copied to clipboard')
    })
  }

}
