import { Component, forwardRef, Inject, Injector, Input, NgZone, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormControlDirective, FormControlName, FormGroupDirective, NgControl, NgModel, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { ConfirmDeleteOptions } from '@app/interfaces';
import { ApiService } from '@services/api.service';
import { Utils } from '@services/utils';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from "ng-zorro-antd/notification";

@Component({
    selector: '<custom-input-name>',
    template: '',
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => BaseInputComponent), multi: true }],
})
export class BaseInputComponent<T = any> implements ControlValueAccessor, OnInit {
    public selected: T;
    protected onChange: any = (value: T) => { };
    protected onTouch: any = () => { };
    public isLoading?: boolean = false;
    public isDisabled: boolean = false;
    protected control!: FormControl;
    protected modalService: NzModalService;

    constructor(@Inject(Injector) protected injector: Injector) {
      this.modalService = this.injector.get(NzModalService);
    }

    get value(): T {
        return this.selected;
    }

    set value(value: T) {
        if (this.isSameValue(this.selected, value)) return;
        this.selected = value;
        this.onChange(this.selected);
        this.onTouch(this.selected);
    }

    /**
     * Kiểm tra liệu value có thay đổi hay không. Chỉ call onChange nếu value thực sự thay đổi.
     * @param value1 
     * @param value2 
     */
    public isSameValue(value1, value2) {
        return value1 == value2
    }

    writeValue(value: T): void {
        this.value = value;
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    ngOnInit(): void {
        this.getControl();
    }

    private getControl() {
        const injectedControl = this.injector.get(NgControl);
        switch (injectedControl.constructor) {
            case NgModel: {
                const { control, update } = injectedControl as NgModel;
                this.control = control;
                break;
            }
            case FormControlName: {
                this.control = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
                break;
            }
            default: {
                this.control = (injectedControl as FormControlDirective).form as FormControl;
                break;
            }
        }
    }

    protected confirmDeletion(ops: ConfirmDeleteOptions) {
      let fnCancel = ops.fnCancel;
      if (!Utils.isFunction(fnCancel)) {
        fnCancel = () => {};
      }
      this.modalService.confirm({
        nzTitle: ops.message,
        nzClosable: false,
        nzMaskClosable: false,
        nzCentered: true,
        nzOkText: ops.txtBtnOk || 'Delete',
        nzOnOk: ops.fnOk,
        nzOkDanger: true,
        nzCancelText: ops.txtBtnCancel || 'Cancel',
        nzOnCancel: fnCancel,
      });
    }

}
