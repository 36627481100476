<div class="carrier-selection">
  <div class="group-header">
    <div class="f20 bottom20">Historic Rate</div>
    <div>
      <span *ngIf="firstStop || lastStop" class="medium right20">
        {{ firstStop }} → {{ lastStop}}
      </span>
      <span nzDanger (click)="closeDialog()" class="pointer">
        <i nz-icon nzType="close" nzTheme="outline"></i>
      </span>
    </div>
  </div>

  <div class="content">
    <nz-table #nzTable nzBordered="true" [nzData]="data.list_data" nzSize="small" [nzLoading]="isLoading"
      [nzLoadingIndicator]="tplLoading" [nzNoResult]="tplNoData" [nzSize]="'default'">
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </ng-template>
      <thead>
        <tr>
          <th>Route ID</th>
          <th>Warp ID</th>
          <th>Carrier</th>
          <th [nzSortOrder]="sortDirection['dropDt']" [nzSortDirections]="['ascend', 'descend', null]"
            (nzSortOrderChange)="changeSort($event, 'dropDt')">Completed Date</th>
          <th [nzSortOrder]="sortDirection['totalShipmentsCost']" [nzSortDirections]="['ascend', 'descend', null]"
            (nzSortOrderChange)="changeSort($event, 'totalShipmentsCost')">Revenue</th>
          <th [nzSortOrder]="sortDirection['carrierCost']" [nzSortDirections]="['ascend', 'descend', null]"
            (nzSortOrderChange)="changeSort($event, 'carrierCost')">Carrier Cost</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of nzTable.data; let i = index">
          <td>
            <a [routerLink]="[item.hyperLinkUrl]" target="_blank" style="color: black;">{{ item.code }}</a>
          </td>
          <td>
            <div *ngIf="!item.numOfShipments">-</div>
            <div *ngIf="item.numOfShipments">
              <span>{{ item.firstShipmentWarpId }}</span>
              <ng-container *ngIf="item.numOfShipments > 1">
                <span [nzTooltipTitle]="item.shipmentWarpIds" nz-tooltip
                  style="margin-left: 5px;">(+{{item.numOfShipments - 1}})</span>
              </ng-container>
            </div>
          </td>
          <td>
            <div *ngIf="!item.carrier?.basicInfo?.name">-</div>
            <div *ngIf="item.carrier?.basicInfo?.name">
              <a [routerLink]="[routeAdminCarrierList, item.carrier.id]" target="_blank" style="color: black;">{{ item.carrier.basicInfo.name}}</a>
            </div>
          </td>
          <td>{{ item.completedDate || '-' }}</td>
          <td>
            <div>
              {{item.totalShipmentsCost}}
              <i nz-icon nzType="info-circle" nzTheme="outline"
                nzTooltipPlacement="bottom"
                nzTooltipOverlayClassName="tooltip-info"
                style="margin-left: 10px;" class="clickable" nz-tooltip [nzTooltipTitle]="tplCostItems"></i>
              <ng-template #tplCostItems>
                <ng-container
                  [ngTemplateOutlet]="tplCostItemsInner"
                  [ngTemplateOutletContext]="{cost: item.totalShipmentsCost, units: item.totalShipmentsItems.units, weight: item.totalShipmentsItems.weight}">
                </ng-container>
                <ng-template #tplCostItemsInner let-cost="cost" let-units="units" let-weight="weight">
                  <div class="tplCostItems">
                    <div class="value">{{cost}}</div>
                    <div class="description">Add up grand total for all shipments on the route</div>
                    <div class="value">{{weight}}</div>
                    <div class="description">Add up weight for all shipments on the route</div>
                    <div class="value">{{units}}</div>
                    <div class="description">Add up units for all shipments on the route</div>
                  </div>
                </ng-template>
              </ng-template>
            </div>
            <div class="shipment-items">
              {{item.totalShipmentsItems?.weight}}
              <ng-container *ngIf="item.totalShipmentsItems?.units?.length">, {{item.totalShipmentsItems.units}}</ng-container>
            </div>
          </td>
          <td >
              <div>{{item.carrierCost ? item.carrierCost: '-'}}</div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
