<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>{{ headerText }}</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>

<div>
  <form [formGroup]="formInput" class="form-detail" nz-form>
    <div>
      <div>
        {{ getLabel("name") }}
        <span *ngIf="isRequired('name')" class="label-mark-required"></span>
      </div>
      <div class="top10">
        <input class="full-w" formControlName="name" nz-input [placeholder]="getPlaceHolder('name')" />
      </div>
    </div>
    <div class="top20">
      <div>
        {{ getLabel("feeType") }}
        <span *ngIf="isRequired('feeType')" class="label-mark-required"></span>
      </div>
      <div class="top10">
        <nz-select nzBackdrop="true" 
          nzAllowClear nzShowSearch
          [formControlName]="'feeType'"
          (ngModelChange)="onChangeFeeType($event)"
          [nzPlaceHolder]="getPlaceHolder('feeType')"
          class="full-w"
        >
          <nz-option *ngFor="let item of feeTypes" 
            [nzValue]="item" [nzLabel]="getFeeTypeName(item)">
          </nz-option>
        </nz-select>
      </div>
    </div>
    <div class="top20">
      <div>
        {{ getLabel("unit") }}
        <span *ngIf="isRequired('unit')" class="label-mark-required"></span>
      </div>
      <div class="top10">
        <nz-select nzBackdrop="true" 
          nzAllowClear nzShowSearch
          [formControlName]="'unit'"
          [nzPlaceHolder]="getPlaceHolder('unit')"
          class="full-w"
        >
          <nz-option *ngFor="let item of units" 
            [nzValue]="item" [nzLabel]="getUnitName(item)">
          </nz-option>
        </nz-select>
      </div>
    </div>
    <div class="top20">
      <div>
        {{ getLabel("price") }}
        <span *ngIf="isRequired('price')" class="label-mark-required"></span>
      </div>
      <div class="top10">
        <nz-input-group nzPrefix="$">
          <input 
            [max]="999999" [min]="0.01" [step]="0.1" 
            [type]="getInputType('price')" 
            class="full-w" nz-input
            formControlName="price" 
            [placeholder]="getPlaceHolder('price')"
          />
        </nz-input-group>
      </div>
    </div>
    <div class="top20">
      <div>
        {{ getLabel("triggerCode") }}
        <span *ngIf="isRequired('triggerCode')" class="label-mark-required"></span>
      </div>
      <div class="top10">
        <nz-select nzBackdrop="true" 
          nzAllowClear nzShowSearch
          [formControlName]="'triggerCode'"
          [nzPlaceHolder]="getPlaceHolder('triggerCode')"
          class="full-w"
        >
          <nz-option *ngFor="let item of triggers" 
            [nzValue]="item" [nzLabel]="getTriggerName(item)">
          </nz-option>
        </nz-select>
      </div>
    </div>
  </form>
</div>

<ng-template [nzModalFooter]>
  <div class="group-footer">
    <div (onCancel)="closeDialog()" (onOK)="clickAdd()" [canClickCancel]="!onProgress"
      [canClickOK]="needUpdate && !onProgress" [labelOK]="'Save'" [nzIconCancel]="null" [nzIconOK]="null"
      [onProgress]="onProgress" form-footer></div>
  </div>
</ng-template>