<nz-form-item>
  <nz-form-control>
    <nz-select [nzDisabled]="isDisabled" [(ngModel)]="paymentTerms" nzBackdrop="true"
      [nzDropdownRender]="tplAddNewPaymentTerms"
      nzPlaceHolder="Please select option" nzDropdownClassName="choose-carrier-payment-term">
      <nz-option nzCustomContent *ngFor="let option of options" [nzLabel]="option.name" [nzValue]="option.value">
        {{ option.name }}
      </nz-option>
    </nz-select>
    <ng-template #tplAddNewPaymentTerms>
      <div class="select-add-new" (click)="onBtnAddNewPaymentTerms()">
        <a >
          <span nz-icon nzType="plus"></span>
          Add new
        </a>
      </div>
    </ng-template>
  </nz-form-control>
</nz-form-item>
