<form class="form-detail" [formGroup]="formInput" nz-form>
  <div class="group no-padding">
    <!-- header -->
    <div class="group-header" style="display: flex; align-items: center">
      <div style="text-wrap: nowrap !important">Customer Logo</div>
      <div style="width: 100%"></div>
      <ng-container *ngIf="requirePermissions([PermissionCode.client.update])">
        <!-- edit button -->
        <button nz-button (click)="onBtnEdit()" class="btn-primary" *ngIf="!isEditing">
          <i nz-icon nzType="edit" nzTheme="outline"></i>
          Edit
        </button>
        <!-- cancel button with confirmation -->
        <button (nzOnConfirm)="onBtnCancel()" *ngIf="isEditing && isInputsChange()" [disabled]="onProgress"
          class="btn-primary right10" nz-button nz-popconfirm nzPopconfirmPlacement="top" nzOkText="Yes"
          nzCancelText="No" nzPopconfirmTitle="Are you sure you want to cancel editing?">
          <i nz-icon nzTheme="outline" nzType="close"></i>
          Cancel
        </button>
        <!-- cancel button -->
        <button (click)="onBtnCancel()" *ngIf="isEditing && !isInputsChange()" [disabled]="onProgress"
          class="btn-primary right10" nz-button>
          <i nz-icon nzTheme="outline" nzType="close"></i>
          Cancel
        </button>
        <!-- save button -->
        <button nz-button nzType="primary" (click)="onBtnSave()" class="btn-primary" *ngIf="isEditing"
          [disabled]="onProgress || !needUpdate" [nzLoading]="onProgress">
          <i nz-icon nzType="save" nzTheme="outline"></i>
          Save
        </button>
      </ng-container>
    </div>
    <!-- form -->
    <div *ngFor="let groupKey of ['logos']" class="group-content-v2">
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
        <div nz-col nzSpan="12" *ngFor="let key of ['logoLightMode', 'logoDarkMode']" [ngClass]="{'container-dark-mode': key == 'logoDarkMode'}" style="padding: 16px;">
          <div>
            <div class="title">{{getLabel(fullKey(groupKey, key))}}</div>
            <div *ngIf="isEditing" class="flex ratio-select">
              <div class="form-label-v2 right10">Aspect Ratio</div>
              <nz-select [ngModel]="aspectRatios[key]" (ngModelChange)="onChangeAspectRatios(key, $event)" [ngModelOptions]="{standalone: true}" style="width: 180px;">
                <nz-option *ngFor="let ratio of listAspectRatio" [nzValue]="ratio.value" [nzLabel]="ratio.label"></nz-option>
              </nz-select>
            </div>
            <div *ngIf="isEditing" class="flex ratio-select">
              <div class="form-label-v2 right10">Image Scale</div>
              <div class="slider-zoom top10 bottom10">
                <nz-slider [nzMax]="10" [nzMin]="-9" [nzStep]="1" [ngModel]="scale[key]" [nzTooltipVisible]="'never'"
                (ngModelChange)="onChangeScale(key, $event)" [ngModelOptions]="{standalone: true}"></nz-slider>
              </div>
            </div>
            <div>
              <div *ngIf="!imageFile[key] && !previews[key]" class="list-images-preview top10">No Image</div>

              <div *ngIf="isEditing" class="list-images-preview top10">
                <div class="group-img">
                  <image-cropper 
                    *ngIf="imageFile[key]" 
                    class="image-cropper"
                    [imageFile]="imageFile[key]" 
                    (imageCropped)="imageCropped($event, key)"
                    allowMoveImage="true"
                    [transform]="transform[key]"
                    [aspectRatio]="aspectRatios[key]"
                    [maintainAspectRatio]="maintainAspectRatio[key]"
                    [resizeToWidth]="202"
                    [onlyScaleDown]="true"
                    [alignImage]="'center'"
                    format="png"
                  ></image-cropper>
                </div>
              </div>

              <div *ngIf="isEditing" class="flex top10 bottom20">
                <button nz-button class="btn-black right10" [disabled]="onProgress" (click)="inputFile.click()">
                  Upload Logo
                </button>

                <button nz-button class="btn-black right10" [disabled]="onProgress" (click)="cropImage(key)" *ngIf="croppedImage[key] && fileToUpload[key]">
                  Apply Crop Logo
                </button>

                <input #inputFile type="file" hidden accept=".png,.jpg,.jpeg" (change)="onFileSelected(inputFile, key)" />

                <button *ngIf="previews[key] || imageFile[key]" class="del-image" nz-button nzType="primary" nzDanger
                  [disabled]="onProgress" (click)="onBtnDelImage(key)">Delete Logo
                </button>
              </div>

              <div *ngIf="previews[key]" class="top10">
                <div *ngIf="isEditing" class="form-label-v2 right10">Preview for Customer Portal</div>
                <div class="preview-container" [ngClass]="{'logo-dark-mode-container': key == 'logoDarkMode'}">
                  <img [src]="previews[key]" class="preview">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>