import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import TabComponent from '@app/admin/base/tabs/tab/tab.component';
import { Tab, TabContentComponent } from '@app/admin/base/tabs/interface';
import TabsComponent from '@app/admin/base/tabs/tabs/tabs.component';
import { ComponentForm, isComponentForm } from '../../../interface';
import { BaseForm } from '@app/admin/base/form-base';

@Component({
  selector: '[shipment-manifest-tabs]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
})
export class ShipmentManifestTabs {
  @Input() tabs: Tab<any>[] = [];
  @Output() tabChanged = new EventEmitter<string>();
  @Input() validateCanChangeTab: (currentIndex: number, nextIndex: number) => boolean = (index) => true;

  @ViewChild('tabCtrl') tabCtrl: TabsComponent;

  get currentTabId() {
    return this.tabCtrl?.getActiveTab()?.id
  }

  constructor() {
  }

  onTabChanged(tab: TabComponent<any>) {
    this.tabChanged.emit(tab.id);
  }

  private getTabForm(tab: TabComponent<any>): BaseForm<any> {
    if (isComponentForm(tab.component)) {
      return (tab?.component as ComponentForm<any>).getForm();
    } else {
      throw Error(`Please implement ComponentForm interface for the class ${tab.component.constructor.name}`);
    }
  }

  getActiveTab(): TabComponent<TabContentComponent> {
    return this.tabCtrl?.getActiveTab();
  }

  getActiveForm(): BaseForm<any> | null {
    const tab = this.getActiveTab();
    return tab?.component ? this.getTabForm(tab) : null;
  }

  getFormById(id: string): BaseForm<any> | null {
    const tab = this.tabCtrl?.getTabById(id);
    return tab?.component ? this.getTabForm(tab) : null;
  }

  getFormByIndex(index: number): BaseForm<any> | null {
    const tab = this.tabCtrl?.getTabByIndex(index);
    return tab?.component ? this.getTabForm(tab) : null;
  }

}