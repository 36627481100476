import { Component } from "@angular/core";
import { DateUtil } from "@services/date-utils";
import { ListFilterDrawer } from "../../../base/list-filter-drawer";

@Component({
  selector: '[warehouse-shipment-filter-extend]',
  templateUrl: './view.html',
  styleUrls: [
    './style.scss',
    '../../../../app.scss',
    '../../../../drawers/drawer.scss',
    '../../../../../styles/date-picker-row.scss',
    '../../../../../styles/form-v2.scss'
  ]
})
export class WarehouseShipmentFilterExtend extends ListFilterDrawer {
  private get declaration(): FormGroupDeclaration {return {
    search: {label: 'Keyword', notAcceptEmpty: true, readOnly: true, submitReadOnly: true},
    shipmentIds: {label: 'ShipmentIds', notAcceptEmpty: true, readOnly: true, submitReadOnly: true},
    client: {label: 'Client', notAcceptEmpty: true},
    isOpenTask: {label: '', notAcceptNull: true},
    pickupLocation: {label: 'Pickup Location', notAcceptEmpty: true},
    deliveryLocation: {label: 'Delivery Location', notAcceptEmpty: true},
    pickupState: {label: 'Original Pickup State', notAcceptEmpty: true},
    originalPickupLocation: {label: 'Original Pickup Location', notAcceptEmpty: true},
    deliveryState: {label: 'Final Delivery State', notAcceptEmpty: true},
    finalDeliveryLocation: {label: 'Final Delivery Location', notAcceptEmpty: true},
    status: {label: 'Status', notAcceptEmpty: true},
    schedulePickupFromDate: {label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'From date', formatValue: v => v, getValue: v => v},
    schedulePickupToDate: {label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'To date', formatValue: v => v, getValue: v => v},
    scheduleDeliveryFromDate: {label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'From date', formatValue: v => v, getValue: v => v},
    scheduleDeliveryToDate: {label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'To date', formatValue: v => v, getValue: v => v},
    schedulePickupTimezone: {label: '', notAcceptEmpty: true, placeHolder: 'Time zone'},
    scheduleDeliveryTimezone: {label: '', notAcceptEmpty: true, placeHolder: 'Time zone'},
    tabType: {label: '', notAcceptEmpty: true, readOnly: true, submitReadOnly: true},
  }}

  public listTimezones = DateUtil.listUsTimezones;

  private keys = [
    'client',
    'isOpenTask',
    'pickupLocation',
    'deliveryLocation',
    'pickupState',
    'originalPickupLocation',
    'deliveryState',
    'finalDeliveryLocation',
    'status'
  ];

  private timezoneKeys = {
    schedulePickupFromDate: 'schedulePickupTimezone',
    schedulePickupToDate: 'schedulePickupTimezone',
    scheduleDeliveryFromDate: 'scheduleDeliveryTimezone',
    scheduleDeliveryToDate: 'scheduleDeliveryTimezone',
  };

  public get formKeys(): string[] {
    return this.keys;
  }

  protected formGroupDeclaration: FormGroupDeclaration = {};

  showSelectionLabel(item: any) {
    return item ? `${item.name} (${item.count})` : ""
  }

  showSelectionLocation(item: any) {
    return item ? `${item?.locationName} (${item.count})` : ""
  }

  showSelectionLocationLabel(item: any) {
    return this.showSelectionLocation(item) + " - " + item.name
  }

  public clients: any = []
  public pickupLocations: any = []
  public deliveryLocations: any = []
  public statusOptions: any = []
  public deliveryStates: any = []
  public pickupStates: any = []
  public originalPickupLocations: any = []
  public finalDeliveryLocations: any = []

  private fields = [
    'schedulePickupFromDate', 'schedulePickupToDate',
    'scheduleDeliveryFromDate', 'scheduleDeliveryToDate'
  ];

  public set metadata(value) {
    this.clients = value?.clients || [];
    this.pickupLocations = value?.pickupLocations || [];
    this.deliveryLocations = value?.deliveryLocations || [];
    this.statusOptions = value?.status || [];
    this.deliveryStates = value?.deliveryStates || [];
    this.pickupStates = value?.pickupStates || [];
    this.originalPickupLocations = value?.originalPickupLocations || [];
    this.finalDeliveryLocations = value?.finalDeliveryLocations || [];
  }

  get needUpdate(): boolean {
    return true;
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.formGroupDeclaration = this.declaration;
    super.ngOnInit();
    this.setEnableFormGroup(true);
  }

  protected beforeBindModel(model: any): void {
    for(let field of this.fields) {
      if(model[field]) {
        const timezoneKey = this.timezoneKeys[field];
        if (timezoneKey && model[timezoneKey]) {
          let timezone = DateUtil.mapTimezoneUS(model[timezoneKey]);
          const date = DateUtil.convertLocalTime2(model[field], timezone).toISOString();
          model[field] = new Date(date);
        } else {
          model[field] = new Date(model[field]);
        }
      } 
    }
    return model;
  }

  protected getFormData_JSON(isCreateNew: boolean): object {
    let json: any = super.getFormData_JSON(isCreateNew);
    for(let field of this.fields) {
      if (json[field]) {
        const timezoneKey = this.timezoneKeys[field];
        if (timezoneKey && json[timezoneKey]) {
          let timezone = DateUtil.mapTimezoneUS(json[timezoneKey]);
          let date = DateUtil.toBeginOfDay(json[field]).toISOString();
          if(field.includes("ToDate")) date = DateUtil.toEndOfDay(json[field]).toISOString();
          date = DateUtil.convertLocalTime(new Date(date), timezone).toISOString();
          json[field] = date;
        } else {
          let date = DateUtil.toBeginOfDay(json[field]).toISOString();
          if(field.includes("ToDate")) date = DateUtil.toEndOfDay(json[field]).toISOString();
          json[field] = date;
        }
      }
    }
    
    return json;
  }
}
