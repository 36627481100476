<ng-container *ngIf="onProgress">
  <div class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>
</ng-container>
<ng-container *ngIf="allDataLoaded">
  <div class="flex-column">
    <!--    warehouse info-->
    <div class="border-box flex-space-between center-vertical">
      <nz-breadcrumb>
        <nz-breadcrumb-item>
          <a [queryParams]="{ warehouseId: warehouseId }" [routerLink]="[routeAdminWarehouseRatePlan]">Rate Plans</a>
        </nz-breadcrumb-item>
        <nz-breadcrumb-item>
          <a (click)="getRouterLink()">{{ getPlanType() }}</a>
        </nz-breadcrumb-item>
        <nz-breadcrumb-item>Rates Configuration</nz-breadcrumb-item>
      </nz-breadcrumb>
      <div class="flex center-vertical">
        <span nz-icon nzTheme="outline" nzType="home"></span>
        <div class="left5">{{ warehouseName }}</div>
        <span class="left20" nz-icon nzTheme="outline" nzType="clock-circle"></span>
        <div class="left5">{{ timezoneText }}</div>
      </div>
    </div>

    <div class="group-title">
      <div class="title">Rate Configurations</div>
    </div>

    <!--    header-->
    <div class="white-box">
      <div class="flex center-vertical" *ngIf="isCustomType">
        <div class="sub-title">Customer:</div>
        <div class="left5">{{ clientName }}</div>
      </div>

      <div class="flex center-vertical top10">
        <div class="sub-title">Rate Duration:</div>
        <div class="left5">{{ getDurationText() }}</div>

        <nz-tag [nzColor]="getStatusColor()" class="left15">
          <div class="flex center-vertical">
            <span *ngIf="isApplying" class="right5" nz-icon nzType="check"></span>
            <span>{{ statusText() }}</span>
          </div>
        </nz-tag>
      </div>
    </div>

    <!--    Fees-->
    <ng-container *ngFor="let typeItem of adjustmentTypes">
      <ng-container *ngIf="typeItem.type == 'table'">
        <div class="white-box top20">
          <div class="flex-space-between center-vertical">
            <div class="medium-title">{{ typeItem.label }}</div>
            <!--Add Fee Button-->
            <button (click)="clickAddFee(typeItem)" 
              *ngIf="!isExpired" 
              nz-button nzType="primary"
            >
              <span nz-icon nzType="plus-circle" nzTheme="outline"></span>
              Add fee
            </button>
          </div>
          <div class="top15">
            <nz-table #feeTable [nzData]="getFeeList(typeItem)" [nzFrontPagination]="false" [nzShowPagination]="false"
              [nzSize]="'default'" nzBordered="true" nzSize="small">
              <thead>
                <tr>
                  <th nzWidth="122px">Fee Code</th>
                  <th>Fee Name</th>
                  <th>Fee Type</th>
                  <th nzWidth="220px">
                    <div class="group-th">
                      <div>UoM</div>
                      <div nz-tooltip nzTooltipTitle="Unit of Measurement">
                        <span nz-icon nzType="info-circle" nzTheme="fill" style="color: #8D8D8D"></span>
                      </div>
                    </div>
                  </th>
                  <th>
                    <div class="group-th">
                      <div>Trigger</div>
                      <div nz-tooltip nzTooltipTitle="Status at which the applied rate begins">
                        <span nz-icon nzType="info-circle" nzTheme="fill" style="color: #8D8D8D"></span>
                      </div>
                    </div>
                  </th>
                  <th nzAlign="right">Rate (USD $)</th>
                  <th *ngIf="isApplySoon" nzWidth="150px" nzAlign="right">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let feeItem of feeTable.data; let index = index">
                  <td>{{ feeItem.code }}</td>
                  <td>{{ feeItem.name }}</td>
                  <td>{{ getFeeTypeName(feeItem.feeType) }}</td>
                  <td>{{ getUnitName(feeItem.unit) }}</td>
                  <td>{{ getTriggerName(feeItem.triggerCode) }}</td>
                  <td nzAlign="right">{{ formatMoney(feeItem.price) }}</td>
                  <td *ngIf="isApplySoon">
                    <div class="group-btn">
                      <span (click)="clickEditFee(feeItem)" 
                        class="accent click" 
                        nz-icon nz-tooltip nzTheme="outline"
                        nzTooltipTitle="Edit fee" nzType="edit"
                      ></span>
                      <div (nzOnConfirm)="clickDeleteFee(feeItem)" 
                        *ngIf="!feeItem.deleting"
                        class="flex center-vertical left20 click red" 
                        nz-popconfirm nz-tooltip
                        nzPopconfirmPlacement="top" 
                        nzPopconfirmTitle="Are you sure you want to delete this fee?"
                        nzTooltipTitle="Delete fee"
                      >
                        <span nz-icon nzTheme="outline" nzType="delete"></span>
                      </div>
                      <div *ngIf="feeItem.deleting" class="flex center-vertical left20">
                        <span nz-icon nzTheme="outline" nzType="loading"></span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="typeItem.type == 'input'">
        <div
          [feeType]="typeItem"
          [rateAdjustments]="getStorageRateAdjustment(typeItem)" 
          [warehouseId]="warehouseId"
          [plan]="model"
          (onAdd)="addAdjustmentToList($event)" 
          (onDelete)="removeAdjustmentFromList($event)"
          warehouse-rate-adjustment-storage-fee
        ></div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>