import { AfterViewInit, Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild, Input } from "@angular/core";
import { FilterLayout } from "@app/admin/components/filter-layout";
import { FilterFieldDeclaration } from "@app/admin/components/filter-layout";
import { BaseFormItem } from "@app/admin/base/form-item";
import { RatePlanStatus } from "@wearewarp/types";
import { Const as WarpConst } from '@wearewarp/universal-libs';

@Component({
  selector: '[rate-plan-filter]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class RatePlanFilter extends BaseFormItem implements OnInit, AfterViewInit {
  @Output() onReload: EventEmitter<any> = new EventEmitter<any>();

  public statusOptions = Object.values(WarpConst.RatePlanStatus);

  ngAfterViewInit(): void {
    this.filter.ngHostAfterViewInit(this.getFilterFields())
  }

  // for filter layout
  @ViewChild('filter') filter: FilterLayout;
  @ViewChild('tplFilter_client') tplFilter_client: TemplateRef<any>;
  @ViewChild('tplFilter_status') tplFilter_status: TemplateRef<any>;

  onFilterChange(key: string, value: any) {
    let keys = this.getFilterFields().map(it => it.key);
    this.filter.onChange({ key, value, includeKeys: keys });
  }

  getFilterPlaceHolder(key: string): string {
    return <string>this.filter.getPlaceHolder(key);
  }

  private filterFieldDeclaration: FilterFieldDeclaration[];
  getFilterFields(): FilterFieldDeclaration[] {
    if (!this.filterFieldDeclaration) {
      this.filterFieldDeclaration = [
        {
          key: 'clientId',
          declaration: { label: 'Customer', placeHolder: 'Select customer', notAcceptEmpty: true },
          templateInput: this.tplFilter_client
        },
        {
          key: 'status',
          declaration: { label: 'Status', placeHolder: 'Status', notAcceptEmpty: true },
          templateInput: this.tplFilter_status
        }
      ];
    }
    return this.filterFieldDeclaration;
  }

  getStatusName(status: RatePlanStatus) {
    switch (status) {
      case WarpConst.RatePlanStatus.apply_soon:
        return 'Apply Soon';
      case WarpConst.RatePlanStatus.applying:
        return 'Applying';
      case WarpConst.RatePlanStatus.expired:
        return 'Expired';
      default:
        return 'Unknown';
    }
  }
}
