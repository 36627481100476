import { Component, Input } from "@angular/core";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { InputHelper } from "@services/input-helper";
import { FormControl } from '@angular/forms';

@Component({
  selector: '[select-add-new-payment-terms]',
  templateUrl: './select-add-new.html',
  styleUrls: [ './style.scss' ]
})
export class SelectAddNewPaymentTerms extends BaseFormDialog1 {
  protected formGroupDeclaration: FormGroupDeclaration = {
    name: {label: 'Name', required: true, placeHolder: 'e.g Net xx'},
    numOfDay: {label: 'Due in fixed number of days', type: 'number', required: true, placeHolder: 'days'},
    feePercentage: {label: 'Fee Rate', type: 'number', required: true, placeHolder: '%', getValue: InputHelper.getValuePercentage, formatValue: InputHelper.formatPercentage},
  }

  @Input() onSave: (data) => void = null;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'numOfDay':
        return InputHelper.handleInputChangeNumberOnly(event, <FormControl>this.formInput.get(key), {isInteger: true});
      case 'feePercentage':
        return InputHelper.handleInputChangePercentage(event, <FormControl>this.formInput.get(key));
      default:
        return super.onInputChanged(event, key);
    }
  }

  onInputKeyPress(event, key) {
    switch (key) {
      case 'numOfDay':
        return InputHelper.handleInputKeyPressNumberOnly(event);
      case 'feePercentage':
        return InputHelper.handleInputKeyPressPercentage(event);
      default:
        return super.onInputKeyPress(event, key);
    }
  }

  onBtnSave() {
    const json = this.getFormData_JSON(true);
    const data = {
      name: `${json.name} (${json.feePercentage}% fee)`,
      numOfDay: json.numOfDay,
      feePercentage: json.feePercentage,
      value: `${json.numOfDay}_${json.feePercentage}`
    }
    this.onSave(data);
    this.closeDialog();
  }

}
