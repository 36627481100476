import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input, TemplateRef, ViewChild } from "@angular/core";
import { AttachedFileUtil } from "@services/attached-file-util";
import { Utils } from "@services/utils";
import { AttachedFile } from "@wearewarp/types/data-model";
import * as pdfjsLib from "pdfjs-dist";
@Component({
    selector: 'pallet-history-list-photo',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class ListPhoto extends BaseComponent{
    @ViewChild('hasBeenConfirmed') hasBeenConfirm: TemplateRef<void>;
    @ViewChild('needConfirm') needConfirm: TemplateRef<void>
    @Input() list: AttachedFile[] = [];
    @Input() isPOD: boolean = false; // nếu là POD thì sẽ hiện thêm thông tin đã confirm hay chưa 

    randomId: number = Math.floor(Math.random() * 1000000);;
    constructor() {
        super();
    }

    ngOnChanges() {
        this.formatPod();
    }

    async formatPod() {
        this.list = await Promise.all(this.list.map(async (item) => {
            const url = AttachedFileUtil.attachedFileUrl(item, true);
            const response = await this.api.GET(`${url}?no-redirect=1`).toPromise();
            const presignUrl = response?.data?.url || '';
            return { ...item, url: presignUrl };
        }));
        this.list = await this.preparePodUrlForTask(this.list)
    }

    async preparePodUrlForTask(pods) {
        if (!Utils.isArrayNotEmpty(pods)) {
            return [];
        }
        for (let i = 0; i < pods.length; i++) {
            let podItem = pods[i];
            if (this.isPdf(podItem)) {
              let url = podItem.url //this.attachedFileUrl(podItem);
              podItem.loadingTask = pdfjsLib.getDocument({
                url,
                // withCredentials: true
              });
              podItem.loadingTask.promise.then(
                (pdf) => {
                  pdf.getPage(1).then((page) => {
                    var desiredWidth = 48; // css class attached-pod
                    var viewport = page.getViewport({ scale: 1 });
                    var scale = desiredWidth / viewport.width;
                    var scaledViewport = page.getViewport({ scale: scale });
                    var canvas = <HTMLCanvasElement>(
                      document.getElementById(`${this.randomId}-${podItem._id}`)
                    );
                    if (!canvas) return;
                    var context = canvas.getContext("2d");
                    canvas.height = scaledViewport.height;
                    canvas.width = scaledViewport.width;
                    var renderContext = {
                      canvasContext: context,
                      viewport: scaledViewport,
                    };
                    var renderTask = page.render(renderContext);
                    renderTask.promise
                      .then()
                      .catch((e) => console.error("DPF render error ", e));
                  });
                },
                function (err) {
                  console.error("PDF loading error ", err);
                }
              );
            }
        }
        return pods;
    }

    showItemPhoto(url) {
      this.showDialog(`<img src=${url} width="100%" />`);
    }

    isConfirmPOD(item) {
      if (item.podConfirmed?.by && item.podConfirmed?.when) return true;
      return false;
    }

    getTemplateType(item){
      if(this.isConfirmPOD(item)) return this.hasBeenConfirm;
      return this.needConfirm;
    }

}