<ng-template [nzModalTitle]>
  <div class="flex-space-between component-header">
    <div>{{ headerText }}</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>

<div>
  <form [formGroup]="formInput" class="form-detail" nz-form>
    <div nz-row [nzGutter]="[16, 16]">
      <div nz-col [nzSpan]="12">
        <div class="form-label-v2">
          {{ getLabel("startDate") }}
          <span *ngIf="isRequired('startDate')" class="label-mark-required"></span>
        </div>
        <div class="top8">
          <nz-date-picker [nzShowToday]="false" class="full-w" formControlName="startDate"
            nzFormat="MM-dd-yyyy"></nz-date-picker>
        </div>
      </div>
      <div nz-col [nzSpan]="12">
        <div class="form-label-v2">
          {{ getLabel("endDate") }}
          <span *ngIf="isRequired('endDate')" class="label-mark-required"></span>
        </div>
        <div class="top8">
          <nz-date-picker [nzDisabledDate]="disabledEndDate" [nzShowToday]="false" class="full-w"
            formControlName="endDate" nzFormat="MM-dd-yyyy"></nz-date-picker>
        </div>
      </div>
    </div>
  </form>
</div>
<div *ngIf="timezone" class="flex center-vertical top8" style="color: #6b6b6b">
  <span nz-icon nzTheme="outline" nzType="clock-circle"></span>
  <div class="left5">Timezone: {{ timezoneText }}</div>
</div>

<ng-template [nzModalFooter]>
  <div class="group-footer">
    <div (onCancel)="closeDialog()" (onOK)="clickEdit()" [canClickCancel]="!onProgress"
      [canClickOK]="needUpdate && !onProgress" [labelOK]="'Save'" [nzIconCancel]="null" [nzIconOK]="null"
      [onProgress]="onProgress" form-footer></div>
  </div>
</ng-template>