import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { DetailModule } from '@app/admin/base/detail.module';
import { SelectBySearchingModule } from '@libs/select-client/module';
import { CustomerLaneDetail } from '.';
import { UiCommonModule } from '@app/admin/components/common/module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzFormModule,
    NzIconModule,
    NzInputModule,
    NzSelectModule,
    DetailModule,
    SelectBySearchingModule,
    UiCommonModule,
  ],
  declarations: [CustomerLaneDetail],
  exports: [CustomerLaneDetail]
})
export class ModuleCustomerLaneDetail {
}