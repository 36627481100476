import { Component, ViewChild } from '@angular/core';
import { BaseForm } from '../../../../../../base/form-base';
import { ComponentForm } from '../../../../interface';
import { FormDataShipmentManifestDraftData } from "@wearewarp/types/rest-api/admin/form-data/shipment-manifest";
import { ShipmentManifestTabContent } from '../../tab-content-component';
import { FormShipmentLocation } from '@app/admin/shipment-entry/components/forms/shipment-location';
import { FormDataShipmentLocation } from '@wearewarp/types/rest-api/admin/form-data/shipment-entry';
import { Utils } from '@services/utils';

@Component({
  selector: '[shipment-third-party-carrier-delivery]',
  templateUrl: './index.html'
})
export class ShipmentThirdPartyCarrierDelivery extends ShipmentManifestTabContent implements ComponentForm<FormDataShipmentLocation> {

  @ViewChild('formComp') formComp: FormShipmentLocation;
  
  getForm(): BaseForm<FormDataShipmentLocation> {
    return this.formComp;
  }

  get modelDraft(): FormDataShipmentManifestDraftData {
    return super.modelDraft;
  }

  get formModel() {
    let origin = this.modelDraft?.dropInfo;
    let consigneeInfo = super.modelManifest?.consigneeInfo;
    if (!origin?.id) {
      origin = { 
        id: Utils.generateULID(),
        requiresAppointment: true
      }
      if (consigneeInfo?.addr?.street) {
        origin.addr = consigneeInfo?.addr;
      }
      if (consigneeInfo?.contact?.fullName) {
        origin.contacts = [{
          type: 'primary',
          fullName: consigneeInfo?.contact?.fullName,
          phone: consigneeInfo?.contact?.phone,
          email: consigneeInfo?.contact?.email,
        }]
      }
    }
    return origin
  }

  join(...args): string {
    return args.join(".");
  }
}