<ng-container >
  <div class="rate-content {{carrierId ? 'link-active' : ''}}" (click)="onClickRateContent()">
    <div>
      <span class="rate-number">{{ numOfLike }}</span>
      <span  class="rate-icon"  nz-icon nzType="like" nzTheme="fill"></span>
      <!-- <img class="rate-icon" src="assets/img/thumb_up_black.svg" /> -->
    </div>
    <div>
      <span class="rate-number">{{ numOfDislike }}</span>
      <span class="rate-icon" nz-icon nzType="dislike" nzTheme="fill"></span>
      <!-- <img class="rate-icon" src="assets/img/thumb_down_black.svg" /> -->
    </div>
  </div>
</ng-container>
