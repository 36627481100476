<div nz-row nzGutter="16">
  <div nz-col nzXs="24" nzSm="24" nzXl="12" *ngFor="let groupKey of documentTypes; let i = index" class="col-item">
    <div class="data-item">
      <div class="header">
        <div class="title flex">{{ getLabel(groupKey.key) }}
          <div *ngIf="isRequiredDocument(groupKey.key)" class="danger left5">*</div>
        </div>
        <ng-container *ngIf="selected && isVerifiedByGoHighWay(selected[groupKey.key])">
          <div class="gohighway-status {{ getCssClassGoHighWay(selected[groupKey.key]?.goHighWayInfo) }}">{{ getGoHighWayStatus(selected[groupKey.key]?.goHighWayInfo) }}</div>
        </ng-container>
        <div *ngIf="isEditing && selected && !isVerifiedByGoHighWay(selected[groupKey.key])">
          <button *ngIf="isEmpty(selected[groupKey.key])" (click)="onClickUpload(groupKey.key)" nz-button>
            <i nz-icon nzTheme="outline" nzType="upload"></i> Upload
          </button>
          <button *ngIf="!isEmpty(selected[groupKey.key])" (click)="onClickUpload(groupKey.key)" nz-button>
            <i nz-icon nzTheme="outline" nzType="edit"></i> Edit
          </button>
        </div>
      </div>
      <div class="body">
        <ng-container *ngIf="selected">
          <div *ngIf="isVerifiedByGoHighWay(selected[groupKey.key])" class="document-gohighway">
            <div class="line-item flex-space-between">
              <div class="flex" style="align-items: center;">
                <img src="/assets/img/GoHighwayIcon.png" />
                <div class="medium left5">Verified by HighWay</div>
              </div>
              <div>
                <a [href]="getAttachedFileUrlOfGoHighWay(selected[groupKey.key]?.docs)" target="_blank">View</a>
              </div>
            </div>
            <div class="line-item">
              Insurer name: {{ selected[groupKey.key]?.goHighWayInfo?.insurance_insurer_name || 'N/A' }}
            </div>
            <div *ngIf="selected[groupKey.key]?.goHighWayInfo?.limit" class="line-item">
              Limit: {{ displayGoHighWayLimit(selected[groupKey.key]?.goHighWayInfo) }}
            </div>
            <div *ngIf="selected[groupKey.key]?.goHighWayInfo?.cancellation_date" class="line-item">
              Cancel Date: {{ getDateSingleOfInsurance(selected[groupKey.key].goHighWayInfo.cancellation_date) }}
            </div>
            <div class="line-item">
              <span>{{ getDateRangeOfInsurance(selected[groupKey.key], groupKey.key) }}</span>
              <span *ngIf="checkExpired(selected[groupKey.key])" class="danger left5">(Expired)</span>
            </div>
          </div>
          <div *ngIf="!isVerifiedByGoHighWay(selected[groupKey.key])" class="document-warp">
            <ng-container *ngFor="let item of selected[groupKey.key]?.docs ?? []; let i = index">
              <div *ngIf="!isFileUploadedViaGoHighWay(item)">
                <div *ngIf="!isFile(item)" class="flex-space-between bottom5">
                  <div>
                    <a [href]="attachedFileUrl(item)" [ngClass]="{'strikethrough': item.deleted}"
                       target="_blank" style="color: black; font-weight: 500;">
                      <i nz-icon nzTheme="outline" nzType="paper-clip" class="right5"></i>
                      <span class="description">{{ getAttachedFileDesc(item) }}</span>
                    </a>
                  </div>
                  <div>
                    <a [href]="attachedFileUrl(item)" target="_blank">View</a>
                  </div>
                </div>
                <div *ngIf="isFile(item)" class="bottom5">
                  <i nz-icon nzTheme="outline" nzType="paper-clip" class="right5"></i>
                  <span class="description">{{ getAttachedFileDesc(item) }}</span>
                  <span class="new-file">new</span>
                </div>
              </div>
            </ng-container>
            <div *ngIf="checkShowDate(groupKey.key) && !isEmpty(selected[groupKey.key])" (click)="onClickUpload(groupKey.key)" class="flex pointer">
              <div *ngIf="!hasExpirationDate(selected[groupKey.key])" class="danger flex center-vertical">
                <span nz-icon nzTheme="outline" nzType="edit"></span>
                <div class="left10">Add expiration date</div>
              </div>
              <div *ngIf="hasExpirationDate(selected[groupKey.key])" class="flex center-vertical" nz-tooltip nzTooltipTitle="Edit expiration date">
                <span>{{ getDateRangeOfInsurance(selected[groupKey.key], groupKey.key) }}</span>
                <span *ngIf="checkExpired(selected[groupKey.key])" class="danger left5">(Expired)</span>
              </div>
            </div>
            <ng-container *ngIf="isEmpty(selected[groupKey.key])">
              <div style="opacity: 0.5"><span nz-icon nzType="info-circle" nzTheme="outline"></span> No file uploaded</div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
