import {
  DataModelManifest, 
  FormDataShipmentManifestDraftData,
} from "@wearewarp/types/rest-api/admin/form-data/shipment-manifest";

import { TabContentContext } from "../../base/tabs/interface";
import { BaseForm } from "../../base/form-base";
import { EventEmitter } from "@angular/core";

export interface ComponentForm<T> {
  getForm(): BaseForm<T>;
}

export function isComponentForm(obj: any):  obj is ComponentForm<any> {
  return typeof (obj as ComponentForm<any>)?.getForm === 'function';
}

export interface ShipmentManifestContext extends TabContentContext {
  modelManifest?: DataModelManifest,
  modelDraft?: FormDataShipmentManifestDraftData,
  readonly modelDraftChange: EventEmitter<FormDataShipmentManifestDraftData>,
}

