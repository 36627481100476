<ng-container *ngFor="let shipment of getDisplayShipments(shipments);let i = index">
  <span>
    <a class="link-shipment" [routerLink]="getRouterLink(shipment)" target="_blank">
      {{ getWarpIdText(shipment) }}
    </a>
    <span *ngIf="i < limit-1 && i< shipments.length -1 "> • </span>
  </span>
</ng-container>
<ng-container *ngIf="shipments?.length > limit">
  <a class="link-shipment left5" [nzTooltipTitle]="tplMoreShipments" nzTooltipPlacement="top" nz-tooltip nz-typography nzEllipsis>
    (+{{ shipments?.length - limit }} more)
  </a>
  <ng-template #tplMoreShipments>
    <div *ngFor="let sh of getMoreShipments(shipments)">
      <a style="color: #FFFFFF; text-decoration: underline" [routerLink]="getRouterLink(sh)" target="_blank">
        {{ getWarpIdText(sh) }}
      </a>
    </div>
  </ng-template>
</ng-container>

