<form nz-form [formGroup]="formInput">
  <div *ngFor="let key of ['carrierName']" class="mw500">
    <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" mark-required></span></div>
    <nz-form-item>
      <nz-form-control>
        <input nz-input [formControlName]="key" [placeholder]="getPlaceHolder(key)">
      </nz-form-control>
    </nz-form-item>
  </div>

  <div *ngFor="let key of ['primaryContact']">
    <div class="bottom5">Contact information</div>
    <form nz-form [formGroupName]="key">
      <div class="flex">
        <div *ngFor="let childKey of ['firstName', 'lastName', 'phone', 'email']; let i = index;" class="flex1" [ngClass]="{'left20': i > 0}">
          <div class="form-label-v2">{{getLabel(join(key, childKey))}}<span *ngIf="isRequired(join(key, childKey))" mark-required></span></div>
          <ng-container [ngSwitch]="childKey">
            <div *ngSwitchCase="'phone'" [ngClass]="childKey">
              <nz-form-item>
                <nz-form-control>
                  <nz-input-group nzCompact>
                    <input nz-input [formControlName]="childKey" style="width: 70%;">
                    <input nz-input [formControlName]="'phoneExtension'" [placeholder]="getPlaceHolder(join(key, 'phoneExtension'))" style="width: 30%;">
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div *ngSwitchDefault [ngClass]="childKey">
              <nz-form-item>
                <nz-form-control>
                  <input nz-input [formControlName]="childKey">
                </nz-form-control>
              </nz-form-item>
            </div>
          </ng-container>
        </div>
      </div>
    </form>
  </div>

</form>
