import { Component, Input } from '@angular/core';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import { Const } from '@const/Const';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { Const as WarpConst } from "@wearewarp/universal-libs";
import { RateAdjustmentCategory, RateAdjustmentUnit, StringULID } from '@wearewarp/types';

@Component({
  selector: '[warehouse-store-fee]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss', '../../../../../styles/row-col.scss']
})
export class WarehouseStorageFee extends BaseFormDialog1 {
  @Input() warehouseId: StringULID;
  @Input() planId: StringULID;
  @Input() categoryCode: RateAdjustmentCategory;
  @Input() categoryName: string;
  @Input() codes: string[] = [];
  
  protected formGroupDeclaration: FormGroupDeclaration = {
    unit: { label: 'UoM', required: true, initialValue: WarpConst.RateAdjustmentUnit.pallet, validators: [this.existUnitValidator.bind(this)] },
    price: { 
      label: 'Starting Price', type: 'number', 
      inputType: 'number', notAcceptEmpty: true, 
      required: true, placeHolder: '0.00', 
      validators: [Validators.min(0), Validators.max(999999), Validators.pattern(/^(0|[1-9]\d*)(\.\d+)?$/)] 
    },
    tiers: { label: 'Tiers', type: 'formArray', initialValue: [], childItem: {
      day: { 
        label: 'From day', type: 'number', 
        inputType: 'number', required: true, 
        validators: [Validators.min(2)] 
      },
      price: { 
        label: 'Price', type: 'number', 
        inputType: 'number', required: true, 
        notAcceptEmpty: true, placeHolder: '0.00', 
        validators: [Validators.min(0.01), Validators.max(999999), Validators.pattern(/^(0|[1-9]\d*)(\.\d+)?$/)] 
      }
    }}
  };

  units = Object.values(WarpConst.RateAdjustmentUnit);

  get isEdit(): boolean {
    return this.model?.id
  }

  existUnitValidator(input: FormControl): any {
    if(!this.codes.includes(input?.value)) return null;
    return {
      "invalid": {
        'en': 'Pallet have been created. Change to other unit'
      }
    };
  }

  protected createFormInput(bindData?: any): void {
    if(!this.isEdit) {
      this.formGroupDeclaration.unit.initialValue = this.units.find(it => !this.codes.includes(it));
    }
    super.createFormInput(bindData);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected beforeBindModel(model: any) {
    if(this.isEdit) {
      this.formGroupDeclaration.unit.readOnly = true;
      this.formGroupDeclaration.unit.submitReadOnly = false;
    }
    return model;
  }

  getPriceControl(tierGroup): FormControl {
    return <FormControl> tierGroup.get('price');
  }

  getPriceErrorMsg(tierGroup) {
    const control = this.getPriceControl(tierGroup);
    if (control.hasError('required')) {
      return 'Price is required';
    }

    if (control.hasError('min')) {
      return 'Value must be greater than 0';
    }

    if (control.hasError('max')) {
      return 'The entered value is too large';
    }

    return '';
  }

  getFromDayControl(tierGroup): FormControl {
    return <FormControl> tierGroup.get('day');
  }

  getFromDayErrorMsg(tierGroup, index) {
    const control = this.getFromDayControl(tierGroup);
    if (control.hasError('required')) {
      return 'From day is required';
    }

    if (control.hasError('max')) {
      return 'The entered value is too large';
    }

    if (control.hasError('min')) {
      let failedNumber = 1;
      if (index > 0) {
        const previousTierControl = this.tierList.at(index - 1).get('day');
        failedNumber = Number(previousTierControl.value || '0');
      }
      return `Value must be greater than ${failedNumber}`;
    }
    if (!control.hasError('min') && index > 0) {
      const previousTierControl = this.tierList.at(index - 1).get('day');
      const currentDay = Number(control.value || '0');
      const previousDay = Number(previousTierControl.value || '0');
      if (currentDay <= previousDay) {
        control.clearValidators();
        control.setValidators([...this.getFromDayValidators(), Validators.min(previousDay + 1)]);
        setTimeout(() => {
          control.updateValueAndValidity();
        }, 1);
      }
    }

    return '';
  }

  getStartingPriceControl(): FormControl {
    return <FormControl> this.formInput.get('price');
  }

  getStartingPriceErrorMsg(): string {
    const control = this.getStartingPriceControl();

    if (control.hasError('required')) {
      return 'Starting price is required';
    }

    if (control.hasError('min')) {
      return 'Value must be greater than or equal to 0';
    }

    if (control.hasError('max')) {
      return 'The entered value is too large';
    }

    return '';
  }

  getUnitControl(): FormControl {
    return <FormControl> this.formInput.get('unit');
  }

  getUnitErrorMsg(): string {
    const control = this.getUnitControl();

    if (control.hasError('required')) {
      return 'Unit is required';
    }

    const value = this.getItemValue('unit');
    console.log("rthiss.codes", this.codes, value)
    if(this.codes.includes(value)) {
      return 'Pallet have been created. Change to other unit';
    }

    return '';
  }

  getUnitName(unit: RateAdjustmentUnit): string {
    const units = {
      pallet: 'Pallet',
      case: 'Case',
      pack: 'Pack',
      piece: 'Piece'
    }
    return units[unit] || ''
  }

  get countTiers() {
    return this.getFormArrayLength('tiers');
  }

  get tierList(): FormArray {
    return <FormArray> this.formInput.get('tiers');
  }

  get liveExplanationText() {
    const startingPrice = this.getItemValue('price') || 0;
    const tierList = this.tierList.getRawValue().filter(item => item.day);
    const unit = this.getItemValue('unit').toLowerCase();

    if (tierList.length == 0) {
      return `The storage price is <strong>$${startingPrice}</strong> per ${unit} per day for the entire duration.
        <br>If you wish to apply a different price for items based on stay duration, please click <strong>Add Tier</strong>.
        <br>*/ Weekend and US Holiday are excluded.`;
    }

    const firstTier = tierList[0];
    const firstCount = firstTier.day - 1;

    let explanationText = `The storage price changes depending on how long items are stored:`;

    explanationText += `<br> • First ${firstCount == 1 ? 'day' : `<strong>${firstCount}</strong> days`}: <strong>$${startingPrice}</strong> per ${unit} ${firstCount == 1 ? '' : 'per day'}`;

    for (const tier of tierList) {
      explanationText += `<br> • From day <strong>${tier.day}th</strong>: <strong>$${tier.price || '0'}</strong> per ${unit} per day`;
    }

    explanationText += `<br>*/ Weekend and US Holiday are excluded.`;

    return explanationText;
  }

  onSubmit() {
    if(this.isEdit) return this.onUpdate();
    return this.onAdd();
  }

  private onUpdate() {
    if (!this.warehouseId) {
      this.showErr('Missing warehouseId!');
      return;
    }
    this.onProgress = true;
    const url = Const.API_WAREHOUSE_MANAGEMENT(`rate_adjustments/${this.model?.id}`);
    const body = this.getFormData_JSON(true);
    const options = { customHeaders: { warehouseId: this.warehouseId } };
    this.api.PUT(url, body, options).subscribe(resp => {
      this.onProgress = false;
      this.onUpdateSuccess({ ...this.model, ...body });
    }, err => {
      this.showErr(err);
      this.onProgress = false;
    });
  }

  private onAdd() {
    if (!this.warehouseId) {
      this.showErr('Missing warehouseId!');
      return;
    }
    this.onProgress = true;
    const url = `${Const.APIURI_WAREHOUSE_RATE_PLAN}/${this.planId}/adjustments`;
    const params = this.getFormData_JSON(true);
    const options = { customHeaders: { warehouseId: this.warehouseId } };
    
    this.api.POST(url, { ...params, planId: this.planId, categoryCode: this.categoryCode }, options).subscribe(
      resp => {
        this.onProgress = false;
        this.onCreateSuccess(resp);
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }

  private getFromDayValidators() {
    return [Validators.required, Validators.max(999999), Validators.pattern(/^[0-9]*$/)];
  }
}