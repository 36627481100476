import { Component, ViewChild } from '@angular/core';
import { BaseForm } from '../../../../../../base/form-base';
import { ComponentForm } from '../../../../interface';
import { FormDataShipmentManifestDraftData } from "@wearewarp/types/rest-api/admin/form-data/shipment-manifest";
import { ShipmentManifestTabContent } from '../../tab-content-component';
import { FormShipmentLocation } from '@app/admin/shipment-entry/components/forms/shipment-location';
import { FormDataShipmentLocation } from '@wearewarp/types/rest-api/admin/form-data/shipment-entry';
import { Utils } from '@services/utils';

@Component({
  selector: '[shipment-warp-carrier-pickup]',
  templateUrl: './index.html'
})
export class ShipmentWarpCarrierPickup extends ShipmentManifestTabContent implements ComponentForm<FormDataShipmentLocation> {

  @ViewChild('formComp') formComp: FormShipmentLocation;
  
  getForm(): BaseForm<FormDataShipmentLocation> {
    return this.formComp;
  }

  get modelDraft(): FormDataShipmentManifestDraftData {
    return super.modelDraft;
  }

  get formModel() {
    let origin = this.modelDraft?.pickInfo;
    if (!origin?.id) {
      origin = { id: Utils.generateULID()}
    }
    let warehouseId = super.modelManifest?.warehouseId;
    if (warehouseId) {
      origin = { ...origin, warehouseId }
    }
    return origin
  }
}