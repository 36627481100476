import { BaseComponent } from "@abstract/BaseComponent";
import { Component } from "@angular/core";
import { Input } from "@angular/core";
import { Utils } from "@services/utils";
import { NzModalRef } from 'ng-zorro-antd/modal';
import { BizUtil } from '@services/biz';

@Component({
  selector: 'dlg-create-route-success',
  templateUrl: './view.html',
  styleUrls: ['../../../../dialogs/dialogs.scss', '../../../../app.scss']
})
export class DlgCreateRoute extends BaseComponent {

  @Input() route: any = {};
  @Input() onSuccess: () => void = () => { };

  constructor(private modal: NzModalRef) {
    super();
  }

  private _onProgress = false;

  get onProgress() {
    return this._onProgress;
  }

  public closeDialog() {
    if (this.modal) {
      this.modal.destroy();
    }
  }

  onDone() {
    this.closeDialog();
    this.onSuccess();
  }

  getShipmentInfo() {
    let arr: any = [];
    if (this.route && this.route?.shipments && this.route.shipments.length) {
      this.route.shipments.map(item => {
        if (item && (item.warpId || item.code)) arr.push(this.showShipmentCode(item));
      });
    }
    return arr.join(", ")
  }

  getDispatchLink() {
    let { hyperLinkText, hyperLinkUrl } = BizUtil.createHyperLinkForJob(this.route);
    hyperLinkUrl = `${window.location.origin}${hyperLinkUrl}?page=1&search=${this.route.code}`
    return { hyperLinkText, hyperLinkUrl }
  }

  copyTrackingLink() {
    let content = this.getDispatchLink();

    Utils.copyTextToClipboard(content.hyperLinkUrl, () => {
      this.notification.success('Copy Dispatch Link', 'Dispatch Link has been copied to clipboard')
    })
  }

} 