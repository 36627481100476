<form nz-form [formGroup]="formInput">
  <div *ngIf="isCreateModeWarpCarrier">
    <div class="mw392 flex1" *ngFor="let key of ['shipmentType']">
      <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
      <nz-form-item>
        <nz-form-control>
          <nz-radio-group nzBackdrop="true" nzButtonStyle="solid"
            (ngModelChange)="onDropdownSelectChange(key, $event)"
            [formControlName]="key" >
            <label nz-radio-button *ngFor="let item of listShipmentTypes"
              [nzValue]="item">{{getShipmentTypeName(item)}}</label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div class="flex">
    <div class="flex1 mw392" *ngFor="let key of ['vehicleType']">
      <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
      <nz-form-item class="right16">
          <div [value]="vehicleType" style="min-width: 300px;" vehicle-selector (valueChange)="onDropdownSelectChange(key, $event)" [quoting]="false" [withOptions]="true"></div>
      </nz-form-item>
    </div>
  </div>

  <div class="flex">
    <div *ngFor="let key of ['clientId']" class="flex1 mw392">
      <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
      <nz-form-item class="right16" style="width: 300px;">
        <nz-form-control>
          <select-by-searching [formControlName]="key"
            [isDisabled]="true"
            [version]="2"
            [(listData)]="listClients"
            [placeholder]="getPlaceHolder(key)"
            (ngModelChange)="onDropdownSelectChange(key, $event)">
          </select-by-searching>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <ng-container *ngIf="isCreateModeWarpCarrier">
    <div *ngFor="let key of ['isCrossDock']">
      <nz-form-item>
        <nz-form-control>
          <div nz-checkbox [formControlName]="key" nzDisabled>{{getLabel(key)}}</div>
        </nz-form-control>
      </nz-form-item>
    </div>
  </ng-container>
  <ng-container *ngIf="isCreateModeWarpCarrier && isFTL">
    <div *ngFor="let key of ['isNotAutoRouteFTL']">
      <nz-form-item>
        <nz-form-control>
          <div nz-checkbox [formControlName]="key">{{getLabel(key)}}</div>
        </nz-form-control>
      </nz-form-item>
    </div>
  </ng-container>

</form>
