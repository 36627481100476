import { Component, OnInit } from '@angular/core';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import { ApiService } from "@services/api.service";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";
import { InputHelper } from "@app/services/input-helper";
import { Utils } from '@services/utils';

const API_URLS = {
  GET_CARRIER_BIDS: Const.APIURI_CARRIER_BIDS,
};

@Component({
  selector: 'app-view-previous-route',
  templateUrl: './view-previous-route.component.html',
  styleUrls: ['./view-previous-route.component.scss']
})
export class ViewPreviousRouteComponent extends BaseFormDialog1 implements OnInit {

  public isLoading = false;
  public data: any = {};
  public firstStop;
  public lastStop;
  public sortDirection = {
    'dropDt': null,
    'totalShipmentsCost': null,
    'carrierCost': null
  };

  constructor(api: ApiService) {
    super();
    this.api = api;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initData()
  }

  public get carrierBidId() {
    return this.model.id
  }

  initData(){
    if (!this.carrierBidId) return;
    this.createFormInput();
    this.setEnableFormGroup(false);
    this.isLoading = true;

    this.api.GET(`${API_URLS.GET_CARRIER_BIDS}/${this.carrierBidId}/get_list_previous_routes`).subscribe(
      (response) => {
        const data = response.data || {};
        //convert data
        this.data = this.onGetDataSucceeded(data);

        this.isLoading = false;
        this.model = data;
      },
      (err) => {
        this.isLoading = false;
        this.showErr(err);
      }
    );
  }

  protected onGetDataSucceeded(res) {
    let data = []
    for (let item of res.list_data) {
      if (!this.firstStop) {
        this.firstStop = this.getFirstStopAddr(item?.stops);
      }
      if (!this.lastStop) {
        this.lastStop = this.getLastStopAddr(item?.stops);
      }
      data.push(this.processItem(item));
    }
    return {
      list_data: data
    }
  }

  protected processItem(item) {
    item.hyperLinkUrl = `${Const.routeAdminDispatchList}/${item.id}`;
    if (Utils.isNumber(item?.assignedCarrier?.cost?.grandTotal)) {
      item.carrierCost = InputHelper.formatMoney2(String(item?.assignedCarrier?.cost?.grandTotal));
    }
    item.numOfShipments = item.shipments?.length ?? 0;
    item.firstShipmentWarpId = item.shipments?.map(it => this.showShipmentWarpId(it.warpId))?.[0];
    item.shipmentWarpIds = item.shipments?.map(it => this.showShipmentWarpId(it.warpId))?.join(', ');
    if (item.status == Const.JobStatus.completed && item.dropDt) {
      item.completedDate = this.displayTime(item.dropDt);
    }
    return item;
  }

   // time, timeTo are ISO string 2022-03-29T18:19:10.000Z
   private displayTime(obj: {time: string, timeTo: string, timezone: string, isAppointment: boolean}) {
    if (!obj || (!obj.time && !obj.timeTo)) return 'N/A';
    let formatDateTime = 'MMM D, YYYY';
    let timeTo = obj.timeTo;
    timeTo = DateUtil.displayLocalTime(timeTo, {timezone: obj.timezone, format: formatDateTime});
    return timeTo;
  }

  private getFirstStopAddr(stops) {
    if (!stops?.length) return '';
    const firstStop = stops[0];
    return this.getStopAddressText(firstStop?.info?.addr);
  }

  private getLastStopAddr(stops) {
    if (!stops?.length) return '';
    const lastStop = stops[stops.length - 1];
    return this.getStopAddressText(lastStop?.info?.addr);
  }

  private getStopAddressText(addr) {
    if (!addr) return '';
    let addrArray = [addr.city, addr.state, addr.zipcode];
    return addrArray.filter(it => it).join(', ')
  }

  private getMoneyValue(text: string) {
    if (!text) return 0;
    return Number(Utils.onlyNumbers(text));
  }

  changeSort(event, field) {
    for(let key of Object.keys(this.sortDirection)) {
      this.sortDirection[key] = key == field ? event : null;
    }
    this.data = this.onGetDataSucceeded(this.model);
    if(!event) return;
    switch(field) {
      case 'dropDt':
        this.data.list_data = this.data.list_data.sort((a, b) => {
          if (this.sortDirection[field] === 'ascend') return new Date(b.dropDt?.timeTo).getTime() - new Date(a.dropDt?.timeTo).getTime()
          return new Date(a.dropDt?.timeTo).getTime() - new Date(b.dropDt?.timeTo).getTime()
        });
      break;
      case 'totalShipmentsCost':
        this.data.list_data = this.data.list_data.sort((a, b) => {
          if (this.sortDirection[field] === 'ascend') {
            return (this.getMoneyValue(b.totalShipmentsCost)) - (this.getMoneyValue(a.totalShipmentsCost));
          }
          return (this.getMoneyValue(a.totalShipmentsCost)) - (this.getMoneyValue(b.totalShipmentsCost));
        });
      break;
      case 'carrierCost':
        this.data.list_data = this.data.list_data.sort((a, b) => {
          if (this.sortDirection[field] === 'ascend') {
            return (this.getMoneyValue(b.carrierCost)) - (this.getMoneyValue(a.carrierCost));
          }
          return (this.getMoneyValue(a.carrierCost)) - (this.getMoneyValue(b.carrierCost));
        });
      break;
      default: return;
    }
  }

}
