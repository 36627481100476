<div class="list-history">
    <nz-timeline *ngIf="!isLoading && !noData" [nzMode]="'left'">
        <nz-timeline-item *ngFor="let item of listHistory" 
            [nzLabel]="getTimeLabel(item)"
            [nzColor]="getDotColor(item)"
        >
            <div>
                <span *ngIf="!isTransportTask(item) && !isWarehouseTask(item)" class="action-text" >{{getActionText(item)}} </span>
                <span *ngIf="isTransportTask(item)">
                    <span class="action-text-bold">Status: {{item.data.metadata?.task?.status || 'N/A'}}</span>
                    <br/>
                    <span class="action-text">{{getActionText(item)}} </span>
                </span>
                <span *ngIf="isWarehouseTask(item)">
                    <a class="action-text link" [href]="getLinkLocation(item)" target="_blank">{{getLocationNameForWarehouseTask(item)}}</a>
                    <span>, {{getActionText(item)}} </span>
                </span>
                <a class="actor-text" [href]="linkActorDetail(item)" target="_blank">{{getActorText(item)}}</a>
            </div>
            <div *ngIf="isUploadPODTask(item)">
                <pallet-history-list-photo [list]="getPodForTaskUploadPod(item)" [isPOD]="true"></pallet-history-list-photo>
            </div>
            <div *ngIf="isUploadProductPhotoWarehouseTask(item)">
                <pallet-history-list-photo [list]="getPhotoForTaskUploadProductPhoto(item)"></pallet-history-list-photo>
            </div>
            <div *ngIf="isAddShipmentItemsTask(item)">
                <pallet-history-list-photo [list]="getPhotoForTaskAddShipmentItems(item)"></pallet-history-list-photo>
            </div>
        </nz-timeline-item>
    </nz-timeline>
    <div *ngIf="!isLoading && noData" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
    <div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
</div>