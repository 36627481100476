import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: '[w-paginator]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class WPaginator {
  @Input() isLastPage = false;
  @Input() currentPage: number;
  @Output() pageChange = new EventEmitter<number>();
  @Input() fnCount: (() => Promise<number>) | undefined;
  isCounting: boolean = false;
  countTotal: number = -1;

  get isFirstPage() { return this.currentPage <= 1 }
  get txtSum() {
    if (this.isLastPage) {
      return `Page ${this.currentPage} of ${this.currentPage}`;
    } else {
      return `Page ${this.currentPage} of many`;
    }
  }
  get shouldShowButtonCount(): boolean { return typeof this.fnCount == 'function' }

  onBtnFirst() {
    this.changePage(1);
  }

  onBtnPrev() {
    this.changePage(this.currentPage - 1);
  }

  onBtnNext() {
    this.changePage(this.currentPage + 1);
  }

  changePage(index: number) {
    this.pageChange.emit(index);
  }

  async onBtnCount() {
    if (this.isCounting) {
      return;
    }
    this.isCounting = true;
    this.countTotal = await this.fnCount();
    this.isCounting = false;
  }

  formatTotalNumber(count: number): string {
    return count.toLocaleString();
  }
}