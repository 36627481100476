import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { DialogService } from '@dialogs/dialog.service';
import { Const as WarpConst } from '@wearewarp/universal-libs';
import { RateAdjustmentUnit, StringULID } from '@wearewarp/types';
import { WarehouseStorageFee } from '../warehouse-storage-fee';
import { RateAdjustment } from '@wearewarp/types/data-model';
import { Const } from '@const/Const';

@Component({
  selector: '[warehouse-rate-adjustment-storage-fee]',
  templateUrl: './index.html',
  styleUrls: ['./style.scss'],
})
export class WarehouseRateAdjustmentStorageFee extends BaseComponent {
  @Input() feeType: any;
  @Input() plan: any;
  @Input() rateAdjustments: RateAdjustment[] = [];
  @Input() warehouseId: StringULID;
  @Output() onAdd: EventEmitter<any> = new EventEmitter<any>();
  @Output() onUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    super.ngOnInit();
  }

  private codes (): string[] {
    let codes = [];
    for(let item of this.rateAdjustments) {
      codes.push(item.unit);
    }
    return codes;
  }

  getUnitName(unit: RateAdjustmentUnit): string {
    const units = {
      pallet: 'Pallet',
      case: 'Case',
      pack: 'Pack',
      piece: 'Piece'
    }
    return units[unit] || ''
  }

  liveExplanationText(item) {
    const startingPrice = item.price || 0;
    const tierList = item.tiers?.filter(item => item.day);
    const unit = item.unit?.toLowerCase();
    if (tierList.length == 0) {
      return `The storage price is <strong>$${startingPrice}</strong> per ${unit} per day for the entire duration.`;
    }

    const firstTier = tierList[0];
    const firstCount = firstTier.day - 1;

    let explanationText = ``;

    explanationText += `First ${firstCount == 1 ? 'day' : `<strong>${firstCount}</strong> days`}: <strong>$${startingPrice}</strong> per ${unit} ${firstCount == 1 ? '' : 'per day'}`;

    for (const tier of tierList) {
      explanationText += `<br> From day <strong>${tier.day}th</strong>: <strong>$${tier.price || '0'}</strong> per ${unit} per day`;
    }

    return explanationText;
  }

  clickAddFee(item) {
    DialogService.openFormDialog1(WarehouseStorageFee, {
      nzComponentParams: {
        warehouseId: this.warehouseId,
        planId: this.plan.id,
        categoryCode: item.value,
        categoryName: item.label,
        codes: this.codes(),
        closeOnSuccess: true,
        createSuccess: resp => {
          const newRate = resp.data;
          this.onAdd.emit(newRate);
        }
      }, nzClassName: 'modal-lg warehouse-add-storage-fee-modal',
    });
  }

  clickEditFee(feeItem) {
    DialogService.openFormDialog1(WarehouseStorageFee, {
      nzComponentParams: {
        model: feeItem,
        codes: this.codes(),
        warehouseId: this.warehouseId,
        closeOnSuccess: true,
        updateSuccess: resp => {
          feeItem.price = resp.price;
          feeItem.tiers = resp.tiers;
        }
      }, nzClassName: 'modal-lg warehouse-edit-storage-fee-modal',
    });
  }

  clickDeleteFee(feeItem) {
    if (!this.warehouseId) {
      this.showErr('Missing warehouseId!');
      return;
    }
    const url = Const.API_WAREHOUSE_MANAGEMENT(`rate_adjustments/${feeItem?.id}`);
    const options = { customHeaders: { warehouseId: this.warehouseId } };
    feeItem.deleting = true;
    this.api.DELETE(url, options).subscribe(
      resp => {
        feeItem.deleting = false;
        this.onDelete.emit(feeItem);
      }, err => {
        this.showErr(err);
        feeItem.deleting = false;
      }
    );
  }

  get isExpired() {
    return this.plan?.status && this.plan?.status === WarpConst.RatePlanStatus.expired;
  }

  private units = Object.values(WarpConst.RateAdjustmentUnit);
  get canAddFee(): boolean {
    if(this.codes().length === this.units.length) return false;
    return !this.isAdminReadOnlyRole && !this.isExpired;
  }

  get isApplySoon(): boolean {
    return this.plan?.status === WarpConst.RatePlanStatus.apply_soon;
  }
}