<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>Add DEPOSIT Amount</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>
<div>
  <form nz-form [formGroup]="formInput">
    <div *ngIf="'amount' as key" class="top20">
      <div nz-row nzGutter="16" class="bottom5">
        <div nz-col nzSm="7">
          <div class="form-label-v2">
            {{getLabel(key)}}
            <span *ngIf="isRequired(key)" class="label-mark-required"></span>
          </div>
        </div>
        <div nz-col nzSm="17">
          <nz-form-item style="width: 100%;">
            <nz-form-control>
              <input nz-input [formControlName]="key"
                [type]="getInputType(key)"
                [placeholder]="getPlaceHolder(key)"
                (input)="onInputChanged($event, key)"
                (keypress)="onInputKeyPress($event, key)"
                (focusout)="onInputFocusOut($event, key)">
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
    <div *ngIf="'paymentDate' as key">
      <div nz-row nzGutter="16" class="bottom5">
        <div nz-col nzSm="7">
          <div class="form-label-v2">
            {{getLabel(key)}}
            <span *ngIf="isRequired(key)" class="label-mark-required"></span>
          </div>
        </div>
        <div nz-col nzSm="17">
          <nz-form-item style="width: fit-content">
            <nz-form-control>
              <nz-input-group [nzAddOnAfter]="shortTimezone">
                <nz-date-picker nz-input
                  [formControlName]="key"
                  nzFormat="yyyy-MM-dd"
                ></nz-date-picker>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
    <div *ngIf="'refs' as key" nz-row nzGutter="16">
      <div nz-col nzSm="7">
        <div class="form-label-v2">
          {{getLabel(key)}}
          <span *ngIf="isRequired(key)" class="label-mark-required"></span>
        </div>
      </div>
      <div nz-col nzSm="17">
        <ng-container [formArrayName]="key">
          <div *ngFor="let f of getArrayControls(key); let arrIndex = index" style="display: flex;">
            <input nz-input [formControlName]="arrIndex" style="width: 300px; margin-bottom: 5px;">
            <div *ngIf="shouldShowRemoveReference" style="align-self: center;">
              <a (click)="onBtnRemoveFormArray(arrIndex)" class="danger left10">
                <i nz-icon nzType="close-square" nzTheme="outline" style="font-size: 18px;"></i>
              </a>
            </div>
          </div>
        </ng-container>
        <button nz-button nzType="dashed" (click)="onBtnAddRef()">
          <span nz-icon nzType="plus" nzTheme="outline"></span>
        </button>
      </div>
    </div>
  </form>
</div>
<div form-footer class="no-border"
  [canClickOK]="needUpdate" 
  [canClickCancel]="true"
  [onProgress]="onProgress"
  [nzIconOK]="false" [nzIconCancel]="false" labelOK="Add" nzOkDanger="false" (onOK)="onBtnSave()"
  (onCancel)="closeDialog()">
</div>