
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { Const } from '@const/Const';
import { ImageUtil } from '@services/image-util';
import { Warehouse } from '@wearewarp/types/data-model';
import { WarpId } from '@wearewarp/universal-libs';
import { DialogService } from '@dialogs/dialog.service';
import { PalletHistory } from '@app/admin/components/pallet-history';

@Component({
    selector: '[warehouse-item-content]',
    templateUrl: './view.html',
    styleUrls: ['./style.scss']
})
export class WarehouseItemContent extends BaseComponent {
    _item: any;
    shipmentItems: any[] = []
    pods: any[] = null
    showNetwork: boolean = false
    shipmentUploadBol: any
    outboundLeg: any;

    @Input() set item(value) {
        this._item = value;
        this.shipmentItems = value?.shipmentItems || []
        this.shipmentUploadBol = this._item?.inboundLeg || this._item?.outboundLeg;
        this.outboundLeg = this._item?.outboundLeg || {};
        this.pods = value?.bols || []
        this.processShipmentItems()
        this.fetchWarehouseDetail()
        console.log(value)
    }

    public warehouse: Warehouse;
    private fetchWarehouseDetail() {
        const warehouseId = this._item?.warehouseId;
        if (!warehouseId) return;
        const url = `${Const.APIURI_WAREHOUSES}/${warehouseId}`;
        this.api.GET(url).subscribe((res) => {
            this.warehouse = res?.data;
        });
    }

    @Output() onRefresh: EventEmitter<any> = new EventEmitter<any>();

    uploadingPodType: string = null
    onAddPodFile(type) {
        this.uploadingPodType = type
        this.onOpenUploadForm()
    }

    onOpenUploadForm() {
        setTimeout(() => {
            if (this.uploadingPodType) {
                let element: HTMLElement = document.getElementById("uploadPODInput-" + this.shipmentUploadBol?.id) as HTMLElement;
                //nếu chưa có element thì đợi thêm 200ms và thử lại
                if (!element) {
                    return this.onOpenUploadForm();
                }
                // this.openUploadFormChange.emit(false);
                element.click();
            }
        }, 200);
    }

    onFileSelected(inputElement: HTMLInputElement) {
        let file = inputElement.files[0];
        inputElement.value = "";
        ImageUtil.resizeImageIfNeeded(file).then((blob) =>
            this.uploadPOD(blob, blob.name)
        );
    }

    private async assignUploadFileToShipment(uploadIds) {
        this.isUploading = true;
        const url = `${Const.APIURI_WAREHOUSE_TASK}/${this.taskUploadBol?.id}`;
        const data = {
            shipmentId: this.shipmentUploadBol.id,
            uploadIds: uploadIds
        }
        this.api.PUT(url, data).subscribe(
            (resp) => {
                this.isUploading = false;
                let newBol = resp?.data.list_data?.[0]
                if (newBol) {
                    this.pods.push(newBol)
                }
                setTimeout(() => {
                    this.onRefresh.emit()
                }, 200);
                this.showSuccess('BOL upload successfully')
            },
            (err) => {
                this.isUploading = false;
                this.showErr(err);
            }
        );

    }

    isUploading = false
    private async uploadPOD(file: Blob, fileName: string) {
        this.isUploading = true;
        const shipmentId = this.shipmentUploadBol?.id
        let formData = new FormData();
        formData.append("file", file, fileName);
        formData.append("params", JSON.stringify({
            shipmentId: shipmentId,
        }));
        this.api.postFormData(Const.APIURI_WAREHOUSE_UPLOAD_FILES, formData).subscribe(
            (resp) => {
                this.isUploading = false;
                let uploadIds = []
                resp?.data?.list_data?.map(item => uploadIds.push(item.id))
                this.assignUploadFileToShipment(uploadIds)
            },
            (err) => {
                this.isUploading = false;
                this.showErr(err);
            }
        );
    }

    ngOnChanges(): void {
        if (this._item.expanded) {
            this.loadPods()
            if (!this.showNetwork) {
                this.showNetwork = true
            }
        }
        console.log(this._item)
    }

    async loadPods() {
        this.pods = this._item?.bols
        for (let file of this.pods) {
            file.fullPath = this.attachedFileUrl(file)
        }
    }

    get taskUploadBol() {
        const task = this._item?.shipmentTasks?.find(it =>
            it?.type == Const.WarehouseTaskType.uploadBOL && this.shipmentUploadBol?.id
            && (it?.inboundLegId == this.shipmentUploadBol.id || it?.outboundLegId == this.shipmentUploadBol.id)
        )
        return task
    }

    async correctShipmentItems() {
        const url = `${Const.APIURI_WAREHOUSE_SHIPMENT}/${this._item?.parent?.id}/correct_item`;
        const resp = await this.api.GET(url).toPromise().catch(err => {
            this.showErr(err);
            console.log(" error: ", err);
        });
        return resp
    }

    async correctWarehouseTasks() {
        const url = `${Const.APIURI_WAREHOUSE_TASK}/build`;
        const options = {
            customHeaders: { warehouseid: this._item?.warehouseId }
        };
        const resp = await this.api.POST(url, { shipmentId: this._item?.parent?.id }, options).toPromise().catch(err => {
            this.showErr(err);
            console.log(" error: ", err);
        });
        return resp
    }

    async correctShipmentStatus() {
        const url = `${Const.APIURI_WAREHOUSE_JOB}/${this._item?.id}/correct_status`;
        const options = {
            customHeaders: { warehouseid: this._item?.warehouseId }
        };
        const resp = await this.api.POST(url, null, options).toPromise().catch(err => {
            this.showErr(err);
            console.log(" error: ", err);
        });
        return resp
    }

    async comfirmCorrectDataAndStatus() {
        let confirmText = `Are you sure about this action?`
        this.confirmYesNo(
            confirmText,
            this.correctDataAndStatus.bind(this)
        )
    }

    isDataCorrecting = false;
    async correctDataAndStatus() {
        this.isDataCorrecting = true;
        if (await this.correctShipmentItems()) {
            if (await this.correctWarehouseTasks()) {
                await this.correctShipmentStatus()
            }
        }
        this.isDataCorrecting = false;
        this.onRefresh.emit()
    }

    totalItemTasks: number = 0
    noOfTaskUploadBol: number = 1
    processShipmentItems() { //thêm thứ tự task 1, task 2, task 3... cho item
        for (let index = 0; index < this.shipmentItems.length; index++) {
            let item = this.shipmentItems?.[index]
            let countWarehouseTasks = this.shipmentItems?.[index - 1]?.warehouseTasks?.length
            if (countWarehouseTasks) {
                this.totalItemTasks += countWarehouseTasks;
            }

            for (let taskIndex = 0; taskIndex < item?.warehouseTasks?.length; taskIndex++) {
                let task = item?.warehouseTasks?.[taskIndex];
                if (task) {
                    task.no = this.totalItemTasks + taskIndex + 1
                    if (taskIndex == item?.warehouseTasks?.length - 1) {
                        this.noOfTaskUploadBol = task.no + 1 //task upload BOL sẽ để là task cuối cùng
                    }
                }
            }
        }
    }

    async comfirmArchiveJob() {
        let confirmText = `Are you sure to archive this shipment ${this.showShipmentCode(this._item?.parent)}?`
        this.confirmYesNo(
            confirmText,
            this.pushArchiveJob.bind(this)
        )
    }

    isArchiving = false;
    async pushArchiveJob() {
        this.isArchiving = true;
        const url = `${Const.APIURI_WAREHOUSE_JOB}/${this._item?.id}/add_job_archive`;
        const options = {
            customHeaders: { warehouseid: this._item?.warehouseId }
        };
        await this.api.POST(url, null, options).toPromise().catch(err => {
            this.showErr(err);
            console.log(" error: ", err);
        });
        this.isArchiving = false;
        setTimeout(() => {
            this.onRefresh.emit()
        }, 200);
        this.showSuccess('Archived successfully')
    }

    async removeBol(data) {
        const taskId = this.taskUploadBol?.id;
        if(!taskId || !data?.uploadId) return;
        await this.resetTask({ taskId, data });
    }

    async resetTask({ taskId, data }) {
        if(!taskId) return;
        const url = `${Const.APIURI_WAREHOUSE_TASK}/${taskId}/reset`;
        this.api.PUT(url, data).subscribe(
            (resp) => {
                setTimeout(() => {
                    this.onRefresh.emit()
                }, 200);
                this.showSuccess('Updated successfully')
            },
            (err) => {
                this.showErr(err);
            }
        );
    }

    public isCompleted(status) {
        return [Const.WarehouseTaskStatus.completed, Const.WarehouseTaskStatus.skipped].includes(status)
    }

    skipTask(task) {
        const url = `${Const.APIURI_WAREHOUSE_TASK}/${task.id}/skip`;
        this.api.PUT(url).subscribe((res) => {
            setTimeout(() => {
                this.onRefresh.emit()
            }, 200);
        }, (err) => {
            this.showErr(err);
        });
    }

    get parentId() {
        return this._item?.parent?.id
    }

    get parentWarpId(){
        return WarpId.showShipmentCode(this._item?.parent)
    }

    onViewItemsHistory() {
        DialogService.openDialog(PalletHistory, {
            nzComponentParams: {
                shipmentId: this.parentId
            },
            nzWidth: '630px',
            nzStyle:{
                top: '20px'
            },
            nzTitle: `${this.parentWarpId} - Items history`
        })
    }
}