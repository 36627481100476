<div *ngIf="onProgress" class="nodata"><i nz-icon nzTheme="outline" nzType="loading"></i></div>
<div *ngIf="!onProgress" class="dashboard-child-container no-padding">
  <form class="form-detail" [formGroup]="formInput" nz-form *ngIf="shouldShowForm">
    <div class="group">
      <ng-container *ngFor="let key of formInputKeys">
        <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
        <nz-form-item style="max-width: 800px;">
          <nz-form-control>
            <ng-container [ngSwitch]="key">
              <select-by-searching *ngSwitchCase="'clientId'"
                [version]="2" type="client" [showSubClient]="true"
                [formControlName]="key" [apiUrl]="getApiListDataForFilter(key)"
                [placeholder]="getPlaceHolder(key)"
                [(listData)]="listClients">
              </select-by-searching>

              <div *ngSwitchCase="'shipmentType'">
                <nz-select nzBackdrop="true" nzShowSearch
                  [formControlName]="key"
                  [nzPlaceHolder]="getPlaceHolder(key)"
                  (ngModelChange)="onInputChanged($event, key)">
                  <nz-option *ngFor="let item of listShipmentTypes"
                    [nzValue]="item" [nzLabel]="item">
                  </nz-option>
                </nz-select>
              </div>

              <div *ngSwitchCase="'vehicleType'">
                <div vehicle-selector 
                  (valueChange)="onInputChanged($event, key)" 
                  [(value)]="vehicleType"
                  [isDisabled]="shouldDisableVehicleType"
                  [quoting]="false" [withOptions]="true"></div>
              </div>

              <div *ngSwitchCase="'fromZipcode'">
                <div zipcode-selector 
                  [initZipcode]="getValueInitZipcode(key)" 
                  [isDisabled]="!isCreateNew"
                  placeholder="From Zipcode" 
                  (onNewValue)="onInputChanged($event, key)">
                </div>    
              </div>

              <div *ngSwitchCase="'toZipcode'">
                <div zipcode-selector 
                [initZipcode]="getValueInitZipcode(key)" 
                [isDisabled]="!isCreateNew"
                placeholder="To Zipcode" 
                (onNewValue)="onInputChanged($event, key)">
              </div>    
              </div>

              <input *ngSwitchDefault nz-input
                [formControlName]="key"
                [type]="getInputType(key)"
                [placeholder]="getPlaceHolder(key)"
                (input)="onInputChanged($event, key)"
                (keypress)="onInputKeyPress($event, key)">
            </ng-container>
          </nz-form-control>
        </nz-form-item>
      </ng-container>
    </div>
    <div class = "footer-btn">
      <div detail-footer-buttons labelButtonCreate="Create New"
        [isCreateNew]="isCreateNew" [isEditing]="isEditing" [allowEdit]="false"
        [onProgress]="onProgress" [needUpdate]="needUpdate"
        (onEdit)="onBtnEdit()" (onSave)="onBtnSave()" (onCancel)="onBtnCancel()">
      </div>
    </div>
  </form>
</div>