<div #filter filter-layout></div>

<ng-template #tplFilter_search let-key="key" let-form="form">
  <ng-container [formGroup]="form">
    <nz-input-group [nzPrefix]="suffixIconSearch">
      <input style="width: 300px" nz-input [formControlName]="key" [placeholder]="getFilterPlaceHolder(key)"
        (ngModelChange)="onChangeSearchKeyWord($event)" />
    </nz-input-group>

  </ng-container>
</ng-template>
<ng-template #suffixIconSearch>
  <span nz-icon nzType="search"></span>
</ng-template>

<ng-template #tplFilter_shipmentCodes let-form="form" let-key="key">
  <ng-container [formGroup]="form">
    <nz-select [formControlName]="key" [nzDropdownClassName]="key" [nzMaxMultipleCount]="50"
      [nzPlaceHolder]="'Search by WARP IDs'" [nzTokenSeparators]="[',',' ']"
      class="{{key}}"
      nzBackdrop="true"
      nzMode="tags"
      (ngModelChange)="onFilterChange(key, $event)"
      style="min-width: 200px; width: 300px;">
      <nz-option [nzLabel]="'Can be multiple WARP IDs'" nzDisabled nzValue=""></nz-option>
    </nz-select>
  </ng-container>
</ng-template>