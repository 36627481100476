import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseDialog } from "@dialogs/base-dlg";
import { Utils } from "@services/utils";

@Component({
  selector: '[shipment-manifest-add-pallet-dlg]',
  templateUrl: './index.html',
  styleUrls: ['index.scss']
})
export class ShipmentManifestAddPalletDlg extends BaseDialog {
  
  constructor() {
    super();
  }

  public onOk: (data) => void = (data) => {};

  public limit = 10;
  public pageIndex: number = 1;
  public get numOfItemSelected() {
    return this.listOfData.filter(it => it.checked).length;
  }
  public get numOfItemShowing() {
    if (Number.isInteger(this.totalCount / this.limit)) {
      return this.limit;
    } else {
      let totalPage = Math.floor(this.totalCount / this.limit) + 1;
      return totalPage !== this.pageIndex ? this.limit : this.totalCount - this.limit * (this.pageIndex - 1);
    }
  }

  public listOfData: any[] = [];
  public displayData: any[] = [];
  @Input() set listItems(value) {
    this.listOfData = Utils.cloneObject(value || []);
    this.listOfData.map(it => it.checked = false)
  }

  allChecked = false;
  indeterminate = false;
  public onProgress = false;

  ngOnInit(): void {
    super.ngOnInit();
  }

  public get totalCount() {
    return this.listOfData.length || 0;
  }

  onDataListPageChanged(page) {
    this.pageIndex = page;
  }

  currentPageDataChange(event): void {
    this.displayData = event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    const validData = this.displayData.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = !allChecked && !allUnChecked;
  }

  checkAll(value: boolean): void {
    this.displayData.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }

  onBtnSave() {
    let palletSelected = this.listOfData.filter(it => it.checked);
    let palletIdsSelected = palletSelected.map(it => it.id);
    this.onOk(palletIdsSelected);
    this.closeDialog();
  }
  
  getWeightValue(item) {
    let weight = '';
    if (item.weight) {
      weight = item.weight;
      if (item.weightUnit) {
        weight = `${weight} ${item.weightUnit}`;
      }
    }
    return weight;
  }

  getDimValue(item) {
    let dim = '';
    if (item.length || item.width || item.height) {
      dim = `${item.length || 'N/A'}x${item.width || 'N/A'}x${item.height || 'N/A'}`;    // (LxWxH)
      if (item.dimsUnit) {
        dim = `${dim} ${item.dimsUnit}`;
      }
    }
    return dim;
  }
  
}
