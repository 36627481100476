import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { FilterLayout } from "@app/admin/components/filter-layout";
import { FilterFieldDeclaration } from "@app/admin/components/filter-layout";
import { BaseFormItem } from "@app/admin/base/form-item";
import { WarpIdMode } from "@wearewarp/ng-antd";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
@Component({
  selector: '[warehouse-shipment-filter]',
  templateUrl: './view.html',
  styleUrls: [
    './style.scss'
  ]
})
export class WarehouseShipmentFilter extends BaseFormItem implements OnInit, AfterViewInit {
  @Output() onReload: EventEmitter<any> = new EventEmitter<any>();

  isLoading = false
  private searchText$ = new Subject<string>();
  onChangeSearchKeyWord(value: any) {
    this.searchText$.next(value);
  }

  ngOnInit(): void {
    this.searchText$.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => {
      this.onFilterChange("search", value);
    });
  }

  ngAfterViewInit(): void {
    this.filter.ngHostAfterViewInit(this.getFilterFields())
  }

  // for filter layout
  @ViewChild('filter') filter: FilterLayout;
  @ViewChild('tplFilter_search') tplFilter_search: TemplateRef<any>;
  @ViewChild('tplFilter_shipmentCodes') tplFilter_shipmentCodes: TemplateRef<any>;

  onFilterChange(key: string, value: any) {
    let keys = this.getFilterFields().map(it => it.key);
    this.filter.onChange({ key, value, includeKeys: keys });
  }

  getFilterPlaceHolder(key: string): string {
    return <string>this.filter.getPlaceHolder(key);
  }

  private filterFieldDeclaration: FilterFieldDeclaration[];

  getFilterFields(): FilterFieldDeclaration[] {
    if (!this.filterFieldDeclaration) {
      this.filterFieldDeclaration = [
        {
          key: 'search',
          declaration: { label: '', notAcceptEmpty: true, placeHolder: 'Search by Ref' },
          templateInput: this.tplFilter_search
        },
        {
          key: 'shipmentCodes',
          declaration: { label: '', notAcceptEmpty: true },
          templateInput: this.tplFilter_shipmentCodes
        }
      ];
    }
    return this.filterFieldDeclaration;
  }

  warpIdMode: WarpIdMode = 'multi';
  warpIdModes: WarpIdMode[] = ['multi', 'range'];

  getWarpIdModeDesc(mode: WarpIdMode): string {
    switch (mode) {
      case 'range': return 'Range Mode';
      default: return 'Normal Mode';
    }
  }
}
