<div *ngIf="!model" class="nodata">
  <i nz-icon nzType="loading" nzTheme="outline"></i>
</div>
<div *ngIf="model">
  <div warehouse-manifest-navigation-bar [manifestModel]="{ id: model.id, mblNumber: model.mblNumber }"></div>
  <div class="manifest-body top20">
    <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
      <div nz-col nzSm="24" nzLg="14" nzXl="16">
        <div manifest-detail-basic-info [model]="model" (onRefresh)="onBtnRefresh()"></div>
      </div>
      <div nz-col nzSm="24" nzLg="10" nzXl="8">
        <div manifest-detail-documents [manifestId]="model.id"></div>
      </div>
    </div>
    <div>
      <nz-tabset
        (nzSelectChange)="onTabChange($event)"
        [(nzSelectedIndex)]="selectedTabIndex"
        [nzSize]="'small'" nzAnimated="false"
        style="width: 100%"
        type="card"
      >
        <ng-container *ngFor="let tab of tabs">
          <nz-tab [nzTitle]="titleTemplate">
            <ng-container *ngIf="tab.key == 'items'">
              <ng-template nz-tab>
                <div manifest-detail-items [manifestId]="model.id"></div>
              </ng-template>
            </ng-container>
            <ng-container *ngIf="tab.key == 'deliveryPlanning'">
              <ng-template nz-tab>
                <div manifest-detail-delivery-planning [manifestId]="model.id" [clientId]="model.clientId" [warehouseId]="model.warehouseId"></div>
              </ng-template>
            </ng-container>
          </nz-tab>
          <ng-template #titleTemplate>
            <nz-badge [nzCount]="tab?.total" [nzOverflowCount]="99" nzShowZero [nzOffset]="[18, 7]" nzShowZero [nzStyle]="{ backgroundColor: '#C01318'}">
              <span>{{tab.name}}</span>
            </nz-badge>
          </ng-template>
        </ng-container>
      </nz-tabset>
    </div>
  </div>
</div>
