
<ng-template [nzModalTitle]>
    <div class="flex-space-between">
      <div>{{ headerText }}</div>
    </div>
</ng-template>
<div *ngIf="!isLoadingWarehouses">
    <form [formGroup]="formInput" class="form-detail" nz-form>
        <div>
            <div>
            {{ getLabel("fromWarehouseId") }}
            <span *ngIf="isRequired('fromWarehouseId')" class="label-mark-required"></span>
            </div>
            <div class="top10">
                <nz-select
                    [nzDisabled]="!isCreateNew"
                    formControlName="fromWarehouseId"
                    nzAllowClear
                    nzShowSearch
                    [nzLoading]="isLoadingWarehouses"
                    [nzPlaceHolder]="'Select warehouse'"
                    style="width: 300px; height: 38px; margin-right: 20px;"
                    (ngModelChange)="reloadListWarehouseCanBeDestination($event)"
                >
                    <nz-option
                        *ngFor="let item of listOfCrossdockWarehouse"
                        [nzValue]="item.id"
                        [nzLabel]="item?.label || item?.name"
                    ></nz-option>
                </nz-select>
            </div>
        </div>
        <div class="top20">
            <div>
                {{ getLabel("toWarehouseIds") }}
                <span *ngIf="isRequired('toWarehouseIds')" class="label-mark-required"></span>
            </div>
            <div class="top10">
                <div warehouse-transfer formControlName="toWarehouseIds" [listOfCrossdockWarehouse]="listWarehouseCanBeDestination"></div>
            </div>
        </div>
    </form>
</div>
<div *ngIf="isLoadingWarehouses">
    <div class="nodata">
        <i nz-icon nzTheme="outline" nzType="loading"></i>
    </div>
</div>
<ng-template [nzModalFooter]>
    <div style="padding: 10px;">
      <div (onCancel)="closeDialog()" (onOK)="onOk()"
           [canClickCancel]="!onProgress" [canClickOK]="needUpdate && !onProgress"
           [labelOK]="'Save'"
           [nzIconCancel]="null" [nzIconOK]="null"
           [onProgress]="onProgress" form-footer></div>
    </div>
</ng-template>
  