<div class="left-pane">
  <!-- 10 per page -->
</div>
<div class="right-pane">
  <div *ngIf="shouldShowButtonCount" style="display: flex;">
    <div style="margin-right: 10px;" class="count" (click)="onBtnCount()">Count</div>
    <div *ngIf="!isCounting && countTotal >= 0">{{formatTotalNumber(countTotal)}}</div>
    <i *ngIf="isCounting" nz-icon nzType="loading" nzTheme="outline"></i>
  </div>
  <div class="icon-btn" [ngClass]="{'disabled': isFirstPage}" (click)="onBtnFirst()"
    nz-tooltip nzTooltipTitle="Go to first page" nzTooltipPlacement="top">
    <i nz-icon nzType="double-left" nzTheme="outline"></i>
  </div>
  <div class="sum">{{txtSum}}</div>
  <div class="icon-btn" [ngClass]="{'disabled': isFirstPage}" (click)="onBtnPrev()"
    nz-tooltip nzTooltipTitle="Go to previous page" nzTooltipPlacement="top">
    <i nz-icon nzType="left" nzTheme="outline"></i>
  </div>
  <div class="icon-btn" [ngClass]="{'disabled': isLastPage}" (click)="onBtnNext()"
    nz-tooltip nzTooltipTitle="Go to next page" nzTooltipPlacement="top">
    <i nz-icon nzType="right" nzTheme="outline"></i>
  </div>
</div>