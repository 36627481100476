import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTableModule } from 'ng-zorro-antd/table';
import { UiCommonModule } from '@app/admin/components/common/module';
import { CustomerAddDepositAmount } from './create';
import { DetailModule } from '@app/admin/base/detail.module';
import { HistoryDepositAmount } from './histories';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzFormModule,
    NzIconModule,
    NzInputModule,
    NzDatePickerModule,
    NzModalModule,
    NzTableModule,
    UiCommonModule,
    DetailModule,
  ],
  declarations: [
    CustomerAddDepositAmount,
    HistoryDepositAmount,
  ],
  exports: [
    CustomerAddDepositAmount,
    HistoryDepositAmount,
  ]
})
export class ModuleCustomerDepositAmount {
}