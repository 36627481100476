<div class="alert-info">
  <span nz-icon nzType="info-circle" nzTheme="fill" style="color: #0A6CF5;"></span>
  <span class="left10">Only pallets can be added to the shipment. If you have empty pallets or cartons or pieces separately, please palletize them first.</span>
</div>

<div nz-row nzGutter="16" class="top15">
  <div nz-col nzXs="24" nzLg="12" *ngFor="let item of listOfData;let i=index" class="col-item">
    <div class="data-item">
      <div class="flex-space-between carrier-consignee">
        <div class="medium">Carrier {{ item.carrier || 'N/A' }}</div>
        <div>
          <img src="/assets/img/distance.svg" class="right5" style="position: relative; bottom: 2px;"/>
          <span class="right5">Consignee:</span>
          <span style="text-decoration: underline;" 
            nz-popover [nzPopoverContent]="tooltipConsigneeTemplate" 
            nzPopoverOverlayClassName="tooltip-addr"
            [nzPopoverTrigger]="item.consigneeInfo?.addr?.street ? 'hover': null">
            {{ item.consignee || 'N/A' }}
          </span>
          <ng-template #tooltipConsigneeTemplate>
            <ng-container
              [ngTemplateOutlet]="tplConsigneeInner"
              [ngTemplateOutletContext]="{addr: item.consigneeInfo?.addr}">
            </ng-container>
            <ng-template #tplConsigneeInner let-addr="addr">
              <nz-descriptions *ngIf="addr?.street" nzBordered [nzColumn]="1" nzSize="small">
                <nz-descriptions-item nzTitle="Address">
                  {{getAddressText(addr)}}
                  <span nz-icon nzType="copy" nzTheme="outline" (click)="copyAddress($event, addr)" nz-tooltip
                    nzTooltipTitle="Copy address" style="cursor: pointer;"></span>
                </nz-descriptions-item>
                <nz-descriptions-item nzTitle="Country">
                  {{addr?.countryAlpha2Code || 'US'}}
                </nz-descriptions-item>
              </nz-descriptions>
            </ng-template>
          </ng-template>
        </div>
      </div>
      <div class="body">
        <div class="cartons-imported bottom5">Imported {{ item.numberOfCartonImported }} in {{ item.numberOfPallets }} {{ item.numberOfPallets > 1 ? 'pallets' : 'pallet' }} - {{ item.numberOfCartonSeparate }} separate</div>
        <div class="flex-space-between bottom5">
          <div class="label">Current in-stock items</div>
          <div class="value">
            <span *ngIf="item.numberOfPalletDoHaveShipment">
              {{ item.numberOfPalletDoHaveShipment }} {{ item.numberOfPalletDoHaveShipment > 1 ? 'pallets' : 'pallet' }}
              <span *ngIf="item.numberOfCartonSeparate"> - </span>
            </span>
            <span *ngIf="item.numberOfCartonSeparate">
              {{ item.numberOfCartonSeparate }} separate
            </span>
            <a *ngIf="item.numberOfPalletDoHaveShipment || item.numberOfCartonSeparate" (click)="onBtnCreateShipment(item)" class="left5" style="text-decoration: underline;">Create shipment</a>
            <span *ngIf="!item.numberOfPalletDoHaveShipment && !item.numberOfCartonSeparate">None</span>
          </div>
        </div>
        <div class="flex-space-between">
          <div class="label">Created shipment</div>
          <div class="value">
            <div *ngIf="!item.shipmentsCreated?.length">None</div>
            <div *ngIf="item.shipmentsCreated?.length">
              <div display-manifest-shipment-warpid [shipments]="item.shipmentsCreated"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>