import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";

export interface FormCreateExternalRouteConnecting{
    id: string,
    fromWarehouseId: string,
    toWarehouseIds: string[]
}
@Component({
    selector: "[create-external-route-connecting]",
    templateUrl: "./index.html",
    styleUrls: ["./index.scss"],
})
export class CreateExternalRouteConnecting extends BaseFormDialog1<FormCreateExternalRouteConnecting> {
    public isLoadingWarehouses: boolean = true;
    public listOfCrossdockWarehouse = [];
    public listWarehouseCanBeDestination = [];
    protected formGroupDeclaration: FormGroupDeclaration = {
        fromWarehouseId: {label: 'Warehouse Original', type: 'string', notAcceptEmpty: true, required: true},
        toWarehouseIds: {label: 'Warehouse Destination', type: 'array', notAcceptEmpty: true, required: true},
    };

    get shouldCreateFormImmediately() {
        return false;
    }

    ngOnInit(){
        super.ngOnInit();
        this.getCrossdockWarehouseList();
    }

    private getCrossdockWarehouseList() {
        this.isLoadingWarehouses = true;
        this.api.GET(Const.APIURI_SELECT_WAREHOUSES).subscribe(
        resp => {
            if (resp?.data?.list_data && resp.data.list_data.length) {
            this.listOfCrossdockWarehouse = resp.data.list_data
            .map((item: any) => {
                let { id, warpId, name } = item;
                return {
                id, warpId, name, label: `${warpId} - ${name}`
                }
            });
            }
            this.createFormInput(this.model);
            if(this.model?.fromWarehouseId) {
                this.listWarehouseCanBeDestination = this.listOfCrossdockWarehouse.filter(it => it.id != this.model.fromWarehouseId);
            }
            else this.listWarehouseCanBeDestination = this.listOfCrossdockWarehouse
            this.isLoadingWarehouses = false;
        }, err => {
            this.isLoadingWarehouses = false;
        }
        )
    }

    get headerText() {
        return this.isCreateNew ? 'Add New External Route Connect' : 'Edit External Route Connect';
    }

    get isCreateNew(): boolean {
        return !this.model
      }

    onOk(){
        const formValue = this.getFormData_JSON(true);
        const {fromWarehouseId, toWarehouseIds} = formValue;
        if(!fromWarehouseId) {
            this.showErr('Missing origin warehouse!');
            return;
        }
        if(!toWarehouseIds || !toWarehouseIds.length){
            this.showErr('Missing destination warehouse!');
            return;
        }
        if(this.isCreateNew){
            this.create(formValue);
        }else{
            this.update(formValue)
        }
    }

    update(formValue){
        const url = `${Const.APIURI_WAREHOUSE_EXTERNAL_ROUTE_CONNECTING}/${this.model.id}`;
        const {toWarehouseIds} = formValue;
        this.onProgress = true;
        this.api.PUT(url, {toWarehouseIds}).subscribe(
            resp => {
                this.onProgress = false;
                this.onUpdateSuccess(resp)
            }, 
            err => {
                this.showErr(err);
                this.onProgress = false;
            }
        )
    }

    create(formValue){
        const url = `${Const.APIURI_WAREHOUSE_EXTERNAL_ROUTE_CONNECTING}`;
        this.onProgress = true;
        this.api.POST(url, formValue).subscribe(
            resp => {
                this.onProgress = false;
                this.onCreateSuccess(resp)
            }, 
            err => {
                this.showErr(err);
                this.onProgress = false;
            }
        )
    }

    reloadListWarehouseCanBeDestination(id){
        this.listWarehouseCanBeDestination = this.listOfCrossdockWarehouse.filter(it => it.id != id)
    }

}