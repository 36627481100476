import { Component, Input } from '@angular/core';
import { Const } from '@const/Const';
import { BaseDialog } from '@dialogs/base-dlg';
import { DateUtil } from '@services/date-utils';
import { InputHelper } from '@services/input-helper';

@Component({
  selector: 'history-deposit-amount',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class HistoryDepositAmount extends BaseDialog {

  @Input() clientId: string;

  listData = [];
  limit = 5;
  totalCount = 0;
  totalDepositAmount = 0;
  isLoading = false;
  pageIndex = 1;
  public defaulTimeZone = DateUtil.Timezone_LA;
  public shortTimezone = DateUtil.timezoneStandardToUsShort(this.defaulTimeZone);

  ngOnInit(): void {
    this.getHistoryList();
    super.ngOnInit();
  }

  getRowNumber(index) {
    return (this.pageIndex - 1) * this.limit + index + 1;
  }

  getHistoryList() {
    this.isLoading = true;
    const url = `${Const.APIV2(Const.APIURI_CLIENTS)}/${this.clientId}/payment-deposit`;
    this.api.GET(url).subscribe(
      resp => {
        this.totalDepositAmount = (resp?.data?.list_data || []).reduce((sum, item) => sum + (item.info?.amount ?? 0), 0);
        this.listData = this.buildDisplayInfo(resp?.data?.list_data || []);
        this.totalCount = resp?.data?.count ?? 0;
        this.isLoading = false;
      }, err => {
        this.showErr(err);
        this.isLoading = false;
      }
    );
  }

  private buildDisplayInfo(listData: any[]) {
    const timezone: string = DateUtil.getLocalTimeZone();
    for (let item of listData) {
      item.displayInfo = {
        amount: this.formatMoney(item.info?.amount),
        paymentDate: DateUtil.displayLocalTime(item.info?.paymentDate, { timezone: this.defaulTimeZone, format: 'MM/DD/YYYY' }),
        refs: this.getReferences(item),
        createdAt: DateUtil.displayLocalTime(item.insert.when, { timezone, format: 'MM/DD/YYYY, h:mm a' }),
        createdBy: this.getFullName(item.insert?.byUser || {})
      }
    }
    return listData;
  }

  formatMoney(amt: number | string) {
    return InputHelper.formatMoney2(`${amt || 0}`);
  }

  getReferences(item) {
    return (item?.info?.refs || []).join(', ');
  }

}