import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FromRatingDialog } from './rate-dlg';
import { ModalHelper } from "@wearewarp/ng-antd";
import { Observable } from "rxjs";
import { BaseComponent } from '@abstract/BaseComponent';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: '[app-rating]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class AppRating extends BaseComponent {

  constructor(
    protected activatedRoute: ActivatedRoute,
    private modalHelper: ModalHelper
  ) {
    super(activatedRoute)
  }

  @Input() onSubmit: (data) => Observable<any>;
  @Input() titleHeader: string = 'Rate';
  @Input() model;
  @Input() isShowPopover = true;
  @Input() thumpUpOptions: string[] = [];
  @Input() thumpDownOptions: string[] = [];
  @Output() onDone: EventEmitter<any> = new EventEmitter<any>();
  
  onClickBtn(type: 'like'|'dislike'|'') {
    this.modalHelper.openForm(FromRatingDialog, {
      nzTitle: this.titleHeader,
      nzClosable: false,
      labelBtnOK: 'Confirm',
      labelBtnCancel: 'Cancel',
      nzComponentParams: {
        type: type,
        model: this.model,
        thumpUpOptions: this.thumpUpOptions,
        thumpDownOptions: this.thumpDownOptions,
        submit: (data) => this.onSubmit(data)
      },
      onSubmitError: err => this.showErr(err),
      onSubmitSucceeded: () => {
        this.notification.create('success', 'Rated successfully!', 'Thank you for your feedback!');
        this.onDone.emit();
      },
    });
  }

  get isLike() {
    return this.model?.type === 'like';
  }

  get isDislike() {
    return this.model?.type === 'dislike';
  }

}