import { Component, Inject, Injector, Input, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { BaseInputComponent } from "@app/admin/components/forms/base-custom-input";
import { TransferChange, TransferItem } from "ng-zorro-antd/transfer";

@Component({
    selector: "[warehouse-transfer]",
    templateUrl: "./index.html",
    styleUrls: ["./index.scss"],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => WarehouseTranfer), multi: true },
    ],
})
export class WarehouseTranfer extends BaseInputComponent{
    
    @Input() listOfCrossdockWarehouse = [];
    list: TransferItem[] = [];
    get value() {
        return this.selected
    }

    //override
    set value(value: any) {
        this.selected = value;
        //seclected Data
        this.updateSelectedOnGUI();
        this.onChange(this.selected);
        this.onTouch(this.selected);
    }

    constructor(@Inject(Injector) protected injector: Injector) {
        super(injector);
      }

    updateSelectedOnGUI() {
        let forceList = this.list.map((item) => {
            item.direction = this.value?.includes(item.key) ? "right" : "left"
            return item
        });
        this.list=[...forceList]
        // console.log('updateSelectedOnGUI',this.selected);
    }
    

    ngOnChanges(){
        if(!this.listOfCrossdockWarehouse?.length) return;
        this.list = this.listOfCrossdockWarehouse.map(w => {
            return {
                key: w.id,
                title: w.label,
            }
        });
        this.updateSelectedOnGUI();
    }

    writeValue(value: any): void {
        if (!value || value.length == 0) return;
        this.value = value || [];
    }

    change(ret: TransferChange): void {
        const listKeys = ret.list.map((l) => l.key);
        if (ret.from == "left") {
          //selected new items
          this.value = [...(this.value || []), ...listKeys];
        } else {
          //remove selected items
          this.value = this.value.filter((key) => !listKeys.includes(key));
        }
    }

    filterWarehouse(inputValue: string = '', item: any): boolean {
        inputValue = (inputValue ?? '').toLowerCase();
        let title = (item?.title ?? '').toLowerCase();
        return title.includes(inputValue);
    }
}

