import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { InputHelper } from "@services/input-helper";
import { CarrierBid, Job } from "@wearewarp/types/data-model";
import moment from "moment";

@Component({
  selector: "calendar-same-lane",
  templateUrl: "./index.html",
  styleUrls: [
    "./index.scss",
  ],
})
export class CalendarSameLane extends BaseComponent {
  @Input() public bidId: string;
  @Input() carrierSaleUrl: string = Const.routeAdminCarrierSales
  public loading: boolean = true;

  ngOnInit(): void {
    super.ngOnInit();
    this.initData();
  }
  public highlightDays: Map<string, Set<{ bid: CarrierBid, job: Job }>> = new Map();
  public currentDate: Date;
  public popupContent: any = {
    selft: null,
    data: [],
    more: 0,
  }

  public isHighlight(date: Date) {
    const dateString = moment(date).format('YYYY-MM-DD');
    const listDays = Array.from(this.highlightDays.keys());
    return listDays.includes(dateString);
  }
  public hasSelf(date: Date) {
    const dateString = moment(date).format('YYYY-MM-DD');
    let findSelf = false;
    this.highlightDays.get(dateString)?.forEach((d) => d.bid.id === this.bidId ? findSelf = true : null);
    return findSelf;
  }

  public updatePopupContent(date: Date) {
    const dateString = moment(date).format('YYYY-MM-DD');
    const dateSet = this.highlightDays.get(dateString);
    const data = Array.from(dateSet);
    const findSelf = data.findIndex(d => d.bid.id === this.bidId);
    const self = findSelf !== -1 ? data.splice(findSelf, 1)[0] : null;
    const more = data.length > 3 ? data.length - 3 : 0;
    data.splice(3);

    this.popupContent = {
      self,
      data,
      more,
    }
  }

  /** Chỉ update content khi date thay đổi */
  public onChangeDate(visible, date: Date) {
    if (visible === false || date === this.currentDate) return;
    this.updatePopupContent(date);
  }

  public formatMoney(value: string) {
    return InputHelper.formatMoney2(value);
  }

  public async initData() {
    this.loading = true;
    this.api.GET(`${Const.APIURI_CARRIER_BIDS}/${this.bidId}/get-similar-routes`).subscribe((res) => {
      const bids: CarrierBid[] = res?.data?.bids || [];
      const jobs: Job[] = res?.data?.jobs || [];
      let jobMap: { [jobId: string]: Job } = {};
      for (let job of jobs) {
        jobMap[job.id] = job;
      }

      for (let bid of bids) {
        let job = jobMap[bid.jobId];
        let timezone = job?.stops.find(stop => stop.type === Const.TaskType.PICKUP)?.info?.addr?.metadata?.timeZoneStandard ?? 'America/Los_Angeles';
        const date = moment(bid.pickupDate).tz(timezone).format('YYYY-MM-DD');
        if (!this.highlightDays.has(date)) {
          this.highlightDays.set(date, new Set());
        }
        this.highlightDays.get(date).add({ bid, job: jobMap[bid.jobId] });
      }
      this.loading = false;
    });
  }
}
