import { Component, ViewChild } from '@angular/core';
import { BaseForm } from '../../../../../../base/form-base';
import { ShipmentManifestTabContent } from '../../tab-content-component';
import { ComponentForm } from '../../../../interface';
import { FormDataShipmentThirdPartyCarrierInfo } from "@wearewarp/types/rest-api/admin/form-data/shipment-manifest";
import { FormShipmentThirdPartyCarrierInfo } from '../../forms/third-party-carrier-info';

@Component({
  selector: '[shipment-third-party-carrier-info]',
  templateUrl: './index.html',
  styleUrls: []
})
export class ShipmentThirdPartyCarrierInfo extends ShipmentManifestTabContent implements ComponentForm<FormDataShipmentThirdPartyCarrierInfo> {
  @ViewChild('formComp') formComp: FormShipmentThirdPartyCarrierInfo;
  
  getForm(): BaseForm<FormDataShipmentThirdPartyCarrierInfo> {
    return this.formComp;
  }

  get formModel() {
    let origin = this.modelDraft?.thirdPartyCarrierInfo;
    const carrierName = this.modelManifest?.carrier || '';
    return { ...(origin ?? {}), carrierName }
  }

}