import { Component, EventEmitter, ViewChild } from '@angular/core';
import { BaseDialog } from '@dialogs/base-dlg';
import { Tab, TabHeaderProgress } from '@app/admin/base/tabs/interface';
import { Log } from '@services/log';
import { Const } from '@const/Const';
import { ShipmentManifestCreateMode } from '@app/enum';
import { ShipmentManifestFormId } from '../enum';
import { ShipmentManifestItems } from './components/warp-carrier/items';
import { ShipmentManifestContext } from '../interface';
import {
  FormDataShipmentManifestDraftData,
  FormDataShipmentManifestRevenue,
} from "@wearewarp/types/rest-api/admin/form-data/shipment-manifest";
import { BaseForm } from '@app/admin/base/form-base';
import { ShipmentManifestTabs } from './components/shipment-tabs';
import { ShipmentWarpCarrierBasicInfo } from './components/warp-carrier/basic-info';
import { ShipmentManifestBuilder } from './shipment-builder';
import { ShipmentWarpCarrierPickup } from './components/warp-carrier/pickup';
import { ShipmentWarpCarrierDelivery } from './components/warp-carrier/delivery';
import { ShipmentWarpCarrierRevenue } from './components/warp-carrier/revenue';
import { ShipmentThirdPartyCarrierInfo } from './components/third-party-carrier/carrier-info';
import { ShipmentThirdPartyCarrierVehicle } from './components/third-party-carrier/vehicle';
import { ShipmentThirdPartyCarrierPickup } from './components/third-party-carrier/pickup';
import { ShipmentThirdPartyCarrierDelivery } from './components/third-party-carrier/delivery';
import { DlgCreateSuccessComponent } from '@dialogs/dlg-create-success';

@Component({
  selector: '[shipment-manifest-container]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss', '../../../../dialogs/dialogs.scss']
})
export class ShipmentManifestContainer extends BaseDialog {

  private readonly _shipmentManifestContext: ShipmentManifestContext = {
    modelManifest: null,
    modelDraft: null,
    modelDraftChange: new EventEmitter<FormDataShipmentManifestDraftData>(),
    getHeaderProgress: this.getShipmentManifestProgress.bind(this),
  }

  get shipmentManifestContext() { return this._shipmentManifestContext }

  onComplete: (data) => void;

  tabs: Tab<any>[] = [];
  isSavingData = false;
  private dataBuilder: ShipmentManifestBuilder;

  get modelDraft(): FormDataShipmentManifestDraftData {
    return this.shipmentManifestContext.modelDraft;
  }
  set modelDraft(value) {
    this.shipmentManifestContext.modelDraft = value;
    this.shipmentManifestContext.modelDraftChange.emit(value);
  }
  
  get createMode() {
    return this.modelManifest?.createMode ?? ShipmentManifestCreateMode.warpCarrier;
  }

  get modelManifest() {
    return this.shipmentManifestContext.modelManifest
  }
  set modelManifest(value) {
    this.shipmentManifestContext.modelManifest = value;
  }

  @ViewChild('shipmentTabs') shipmentTabs: ShipmentManifestTabs;

  get canClose() {
    return !this.isSavingData;
  }

  constructor() {
    super();
  }
  ngOnInit(): void {
    this.dataBuilder = new ShipmentManifestBuilder(this.modelDraft);
    this.initTabs();
  }

  private initTabs() {
    const context = this.shipmentManifestContext;
    switch (this.createMode) {
      case ShipmentManifestCreateMode.warpCarrier:
        this.tabs = [
          {
            id: ShipmentManifestFormId.warpCarrierItems,
            title: 'Items',
            component: ShipmentManifestItems,
            active: true,
            context,
          },
          {
            id: ShipmentManifestFormId.warpCarrierBasicInfo,
            title: 'Customer & Equipments',
            component: ShipmentWarpCarrierBasicInfo,
            active: false,
            context,
          },
          {
            id: ShipmentManifestFormId.warpCarrierPickup,
            title: 'Pickup',
            component: ShipmentWarpCarrierPickup,
            active: false,
            context,
          },
          {
            id: ShipmentManifestFormId.warpCarrierDropoff,
            title: 'Delivery',
            component: ShipmentWarpCarrierDelivery,
            active: false,
            context,
          },
          {
            id: ShipmentManifestFormId.warpCarrierRevenue,
            title: 'Revenue',
            component: ShipmentWarpCarrierRevenue,
            active: false,
            context,
          },
        ]
        break;
      case ShipmentManifestCreateMode.thirdPartyCarrier:
        this.tabs = [
          {
            id: ShipmentManifestFormId.thirdPartyCarrierInfo,
            title: 'External Carrier',
            component: ShipmentThirdPartyCarrierInfo,
            active: true,
            context,
          },
          {
            id: ShipmentManifestFormId.thirdPartyCarrierItems,
            title: 'Items',
            component: ShipmentManifestItems,
            active: false,
            context,
          },
          {
            id: ShipmentManifestFormId.thirdPartyCarrierVehicle,
            title: 'Customer & Equipments',
            component: ShipmentThirdPartyCarrierVehicle,
            active: false,
            context,
          },
          {
            id: ShipmentManifestFormId.thirdPartyCarrierPickup,
            title: 'Pickup',
            component: ShipmentThirdPartyCarrierPickup,
            active: false,
            context,
          },
          {
            id: ShipmentManifestFormId.thirdPartyCarrierDropoff,
            title: 'Delivery',
            component: ShipmentThirdPartyCarrierDelivery,
            active: false,
            context,
          },
          {
            id: ShipmentManifestFormId.thirdPartyCarrierRevenue,
            title: 'Revenue',
            component: ShipmentWarpCarrierRevenue,
            active: false,
            context,
          },
        ]
        break;
      default:
        break;
    }
    this.autoAssignActiveTab();
  }

  // Gọi hàm này để tìm ra những form nào đã nhập đủ dữ liệu rồi
  // Mục đích để nhảy đến form đầu tiên chưa đủ dữ liệu
  private autoAssignActiveTab() {
    let index = this.findNextActiveTab();
    for (let i = 0; i < this.tabs.length; i++) {
      this.tabs[i].active = i == index;
      Log.d(`tab ${this.tabs[i].id} ${this.tabs[i].active ? 'active' : 'inactive'}`);
    }
  }

  validateCanChangeTab = (currentIndex: number, nextIndex: number) => {
    // const currentForm = this.shipmentTabs.getFormByIndex(currentIndex);
    // let err = currentForm.validate();
    // if (err) {
    //   return false;
    // }
    return true;
  }

  onTabChanged(tabId: string) {
    Log.d(`onTabChanged ${tabId}`);
  }

  private assertBeforeSave(): boolean {
    switch (this.shipmentTabs.currentTabId) {
      case ShipmentManifestFormId.warpCarrierRevenue:
      case ShipmentManifestFormId.thirdPartyCarrierRevenue:
        // Trước khi save revenue thì tất cả các tab trước phải nhập đủ hết dữ liệu
        for (let i = 0; i < this.tabs.length; i++) {
          let tabId = this.tabs[i].id;
          if (tabId == this.shipmentTabs.currentTabId) {
            continue;
          }
          if (this.getShipmentManifestProgress(tabId) != TabHeaderProgress.done) {
            let tabTitle = this.tabs[i].title;
            this.showDialog(`Please complete <b>${tabTitle}</b> first`, () => this.goToTab(i));
            return false;
          }
        }
        break;
    }
    return true;
  }

  onBtnSave() {
    if (!this.assertBeforeSave()) {
      return;
    }
    const form: BaseForm<any> = this.shipmentTabs.getActiveForm();
    let err = form?.validate({scrollToErrorField: true});
    if (err) {
      let message = 'Please make sure all the fields in form are valid.';
      if (err.error && err.message) {
        // form tạo sẵn error message để hiển thị
        message = err.message;
        err = err.error;
      }
      const modal = this.modalService.create({
        nzContent: message,
        nzClosable: false,
        nzMaskClosable: false,
        nzCentered: true,
        nzOkText: 'OK',
        nzCancelText: null,
      });
      modal.afterClose.subscribe(() => {
        form.scrollToFirstInvalidControl();
      });
      return Log.e(`Form error: `, err);
    }
    const formData = form.getFormData();
    Log.d('currentTab formData: ', formData);
    if (this.shipmentTabs.currentTabId == ShipmentManifestFormId.warpCarrierRevenue 
      || this.shipmentTabs.currentTabId == ShipmentManifestFormId.thirdPartyCarrierRevenue) {
      if (!(<FormDataShipmentManifestRevenue>formData).cost?.grandTotal) {
        return this.confirmCostZeroBeforeSaving(formData);
      }
    }
    this.save(formData, this.shipmentTabs.currentTabId);
  }

  private confirmCostZeroBeforeSaving(formData) {
    return this.confirmYesNo('Revenue is zero, are you sure?', () => {
      this.save(formData, this.shipmentTabs.currentTabId);
    })
  }

  private save(formData, currentTabId: string) {
    let orderData = this.dataBuilder.buildDraftData(formData, currentTabId);
    this.modelDraft = orderData;
    if (this.isAllTabsDone()) {
      const lastIndex = this.tabs.length - 1;
      if (this.shipmentTabs.tabCtrl.currentIndex == lastIndex) {
        // Đang ở tab cuối rồi => gọi API tạo order
        this.completeDraft();
      } else {
        // Nhảy về tab cuối để review, phải click button Save ở tab cuối cùng thì mới tạo order
        this.goToTab(lastIndex);
      }
    } else {
      this.goNextTab()
    }
  }

  private goNextTab() {
    let index = this.findNextActiveTab();
    Log.d(`goNextTab ${index}`);
    this.goToTab(index);
  }

  private goToTab(index: number) {
    this.shipmentTabs.tabCtrl.selectTab(index);
  }

  private isAllTabsDone(): boolean {
    if (!this.modelDraft) {
      return false;
    }
    for (let tab of this.tabs) {
      if (this.getShipmentManifestProgress(tab.id) != TabHeaderProgress.done) {
        return false;
      }
    }
    return true;
  }

  private findNextActiveTab(): number {
    for (let i = 0; i < this.tabs.length; i++) {
      let stt = this.getShipmentManifestProgress(this.tabs[i].id);
      if (stt == TabHeaderProgress.inProgress) {
        return i;
      }
    }
    return 0;
  }

  // Dựa vào draft data check xem form đã nhập đủ dữ liệu chưa
  private getShipmentManifestProgress(tabId: string): TabHeaderProgress {
    if (!this.modelDraft) {
      return TabHeaderProgress.inProgress;
    }
    let id = <ShipmentManifestFormId>tabId;
    const form: BaseForm<any> = this.shipmentTabs?.getFormById(id);
    let err = form?.validate();
    if (err) {
      // Nếu form đang lỗi thì ko được coi là done
      return TabHeaderProgress.inProgress;
    }
    // Nếu form không lỗi và có modelDraft thì chỉ cần check xem trong modelDraft đã có data của form chưa, nếu có rồi tức là form đã done rồi.
    let isDone = false;
    switch (id) {
      case ShipmentManifestFormId.thirdPartyCarrierInfo:
        isDone = this.modelDraft.thirdPartyCarrierInfo?.carrierName != null;
        break;
      case ShipmentManifestFormId.warpCarrierItems:
      case ShipmentManifestFormId.thirdPartyCarrierItems:
        isDone = this.modelDraft.items != null;
        break;
      case ShipmentManifestFormId.warpCarrierBasicInfo:
        isDone = this.modelDraft.basicInfo?.shipmentType != null;
        break;
      case ShipmentManifestFormId.thirdPartyCarrierVehicle:
        isDone = this.modelDraft.basicInfo != null;
        break;
      case ShipmentManifestFormId.warpCarrierPickup:
      case ShipmentManifestFormId.thirdPartyCarrierPickup:
        isDone = this.modelDraft.pickInfo?.type == Const.TaskType.PICKUP;
        break;
      case ShipmentManifestFormId.warpCarrierDropoff:
      case ShipmentManifestFormId.thirdPartyCarrierDropoff:
        isDone = this.modelDraft.dropInfo?.type == Const.TaskType.DROPOFF;
        break;
      case ShipmentManifestFormId.warpCarrierRevenue:
      case ShipmentManifestFormId.thirdPartyCarrierRevenue:
        isDone = this.modelDraft.cost?.currency?.type != null;
        break;
    }
    return isDone ? TabHeaderProgress.done : TabHeaderProgress.inProgress;
  }

  private completeDraft() {
    this.isSavingData = true;
    let url = `${Const.API_WAREHOUSE_MANAGEMENT('order-manifest')}`;
    this.api.POST(url, { data: this.modelDraft, manifestId: this.modelManifest.manifestId}).subscribe(
      resp => {
        this.isSavingData = false;
        this.showDialogComplete(resp?.data || {});
        this.onComplete?.(resp?.data);
      }, err => {
        this.showErr(err);
        this.isSavingData = false;
      }
    );
  }

  private showDialogComplete(order) {
    this.modalService.create({
      nzContent: DlgCreateSuccessComponent,
      nzFooter: null,
      nzClosable: false,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzComponentParams: {order, shouldShowBtnCreateNewShpment: false}
    });
    this.closeDialog();
  }

}
