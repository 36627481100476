import { ChangeDetectorRef, Component, ElementRef, Injector, Input } from "@angular/core";
import { BaseForm, ValidateOptions } from "@app/admin/base/form-base";
import { DialogService } from "@dialogs/dialog.service";
import { DataModelManifest, FormDataManifestItems } from "@wearewarp/types/rest-api/admin/form-data/shipment-manifest";
import { ApiService } from "@services/api.service";
import { getInjector } from "@services/index";
import { Const } from "@const/Const";
import { UIHelper } from "@services/UIHelper";
import { Utils } from "@services/utils";
import { ShipmentManifestAddPalletDlg } from "./add-item-dlg";
import { ValidationErrors } from "@angular/forms";
import { DrawerService } from "@app/drawers/drawer.service";
import { WarehouseManifestEditPallet } from "@app/admin/crossdock/manifests/detail/components/edit-pallet";

@Component({
  selector: '[form-manifest-items]',
  templateUrl: './view.html',
  styleUrls: ['./index.scss']
})
export class FormManifestItems extends BaseForm<FormDataManifestItems> {

  protected injector: Injector;
  protected api: ApiService;

  constructor(protected hostElement: ElementRef<HTMLElement>, private cd: ChangeDetectorRef) {
    super(hostElement);
    this.injector = getInjector();
    this.api = this.injector.get(ApiService);
  }

  @Input() manifestId: string;
  @Input() modelManifest: DataModelManifest | undefined;
  public isLoading: boolean = true;
  public isError: boolean = false;
  public listOfPalletOriginal: any[] = [];

  protected formGroupDeclaration: FormGroupDeclaration = {
    pallets: {label: '', required: true, type: 'formArray', childItem: {
      id: {label: '', required: true},
      name: {label: ''},
      label: {label: ''},
      weight: {label: ''},
      weightUnit: {label: ''},
      length: {label: ''},
      width: {label: ''},
      height: {label: ''},
      dimsUnit: {label: ''},
    }}
  }

  // goi api get list pallet truoc roi createFormInput
  get shouldCreateFormInput() {
    return false;
  }

  get isEnabledBtnAddPallet() {
    return this.listOfData.length != this.listOfPalletOriginal.length;
  }

  get listOfData(): any[] {
    if (!this.formInput) return [];
    return this.getItemValue('pallets') || [];
  }

  async ngOnInit() {
    this.isLoading = true;
    const resp = await this.getData();
    if (resp?.data?.list_data) {
      this.processListData(resp.data.list_data);
      this.createFormInput(this.model);
    } else {
      this.createFormInput();
    }
    this.isLoading = false;
  }

  private async getData() {
    if (!this.manifestId) return;
    let url = `${Const.APIURI_WAREHOUSE_MANIFEST}/${this.manifestId}/available-pallets`;
    const params = {
      carrier: this.modelManifest?.carrier,
      consignee: this.modelManifest?.consignee,
      warehouseId: this.modelManifest?.warehouseId
    }
    url = Utils.appendQueryStringIntoUrl(url, params);
    const resp = await this.api.GET(url).toPromise().catch(err => {
      UIHelper.showErr(err);
      this.isError = true;
    });
    return resp;
  }

  private processListData(pallets = []) {
    pallets = pallets.map(pallet => ({
      id: pallet.id,
      name: pallet.name,
      label: pallet.label,
      weight: pallet.weight,
      weightUnit: pallet.weightUnit,
      length: pallet.length,
      width: pallet.width,
      height: pallet.height,
      dimsUnit: pallet.dimsUnit,
    }));
    this.listOfPalletOriginal = Utils.cloneObject(pallets);
    this.model = { pallets };
  }

  private async refreshDataPalletItem(item) {
    const resp = await this.getData();
    if (resp?.data?.list_data) {
      const pallet = resp.data.list_data.find(it => it.id == item.id);
      if (!pallet) return;
      const index = this.listOfData.findIndex(it => it.id == item.id);
      let keys = ['label', 'weight', 'weightUnit', 'length', 'width', 'height', 'dimsUnit'];
      if (index != -1) {
        for (let key of keys) {
          this.setItemValue(`pallets[${index}].${key}`, pallet[key]);
        }
      }
      for (let it of this.listOfPalletOriginal) {
        if (it.id != item.id) continue;
        for (let key of keys) {
          it[key] = pallet[key];
        }
      }
    }
  }

  onBtnEditPallet(item) {
    DrawerService.openFormDrawer1(WarehouseManifestEditPallet, {
      nzContentParams: {
          updateSuccess: () => {
            this.refreshDataPalletItem(item);
          },
          manifestId: this.manifestId,
          model: item,
          closeOnSuccess: true
      },
      nzWidth: 410,
      nzWrapClassName: 'wrap-drawer import-manifest-drawer',
    });
  }

  onBtnAddPallet() {
    let pallets: any[] = this.getItemValue('pallets') || [];
    let palletIds = pallets.map(it => it.id);
    let listOfPalletOriginalIds = this.listOfPalletOriginal.map(it => it.id);
    let palletIdsRemoved = listOfPalletOriginalIds.filter(it => !palletIds.includes(it));
    let palletRemoved = this.listOfPalletOriginal.filter(it => palletIdsRemoved.includes(it.id));
    if (!palletRemoved.length) return;
    DialogService.openDialog(ShipmentManifestAddPalletDlg, {
      nzComponentParams: {
        listItems: palletRemoved,
        onOk: (additonalIds) => {
          for (let id of additonalIds) {
            if (!palletIds.includes(id)) {
              const pallet = this.listOfPalletOriginal.find(it => it.id == id);
              if (pallet) {
                this.addItemToFormArray('pallets', pallet);
              }
            }
          }
        }
      },
      nzCentered: true,
      nzTitle: null,
      nzClosable: false,
    });
  }

  onBtnRemovePallet(item) {
    let pallets: any[] = this.getItemValue('pallets') || [];
    let index = pallets.indexOf(item);
    this.removeItemInFormArray('pallets', index);
  }

  public validate(options?: ValidateOptions): ValidationErrors | { error: ValidationErrors; message: string; }{
    let pallets: any[] = this.getItemValue('pallets');
    if (!pallets?.length) {
      let err = {error: `Number of pallet must be greater than 0`};
      return { error: err, message: err.error }
    }
    const keys = ['label', 'weight', 'weightUnit', 'length', 'width', 'height', 'dimsUnit'];
    for (let pallet of pallets) {
      for (let key of keys) {
        if (!pallet[key]) {
          let err = {error: `Please make sure pallet details (Label, Weight, DIM) are added.`};
          return { error: err, message: err.error }
        }
      }
    }
    return null;
  }

  getWeightValue(item) {
    let weight = '';
    if (item.weight) {
      weight = item.weight;
      if (item.weightUnit) {
        weight = `${weight} ${item.weightUnit}`;
      }
    }
    return weight;
  }

  getDimValue(item) {
    let dim = '';
    if (item.length || item.width || item.height) {
      dim = `${item.length || 'N/A'}x${item.width || 'N/A'}x${item.height || 'N/A'}`;    // (LxWxH)
      if (item.dimsUnit) {
        dim = `${dim} ${item.dimsUnit}`;
      }
    }
    return dim;
  }

}