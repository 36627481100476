import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FilterLayout } from "@app/admin/components/filter-layout";
import { FilterFieldDeclaration } from "@app/admin/components/filter-layout";
import { BaseFormItem } from "@app/admin/base/form-item";
@Component({
  selector: '[warehouse-shipment-filter-tab]',
  templateUrl: './view.html',
  styleUrls: [
    './style.scss'
  ]
})
export class WarehouseShipmentFilterTab extends BaseFormItem implements OnInit, AfterViewInit {
  _options;
  _currentFilter;

  @Output() onTabChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() set currentFilter(value) {
    this._currentFilter = value;
  };
  @Input() set options(value) {
    this._options = value;
  };
  get currentFilter() { return this._currentFilter }  
  get options() { return this._options }  

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.filter.ngHostAfterViewInit(this.getFilterFields())
  }

  // for filter layout
  @ViewChild('filter') filter: FilterLayout;

  private tabKey = "tabType"

  onTabChange(key: any) {
    this.filter.onTabChange({ key: this.tabKey, value: key });
    this.onTabChanged.emit();
  }

  getFilterFields(): FilterFieldDeclaration[] {
    return []
  }

  get selectedTabIndex() {
    let tabIndex = 0 //mặc định là "All"
    const tab = this.currentFilter?.[this.tabKey]
    if (!tab) return tabIndex

    const currentTab = this.listTabs.find(item => item.key === tab);
    if (!currentTab) return tabIndex
    tabIndex = currentTab?.index
    return tabIndex;
  }

  getCountShipments(key: string): number {
    return this.options?.[this.tabKey]?.[key] || 0;
  }

  public listTabs = [
    {
      index: 0,
      title: 'All Shipments',
      key: 'all',
    },
    {
      index: 1,
      title: 'Inbound Today',
      description: 'Inbound Scheduled by today',
      key: 'inbound_today',
    },
    {
      index: 2,
      title: 'Inbound Tomorrow',
      description: 'Inbound Scheduled by tomorrow',
      key: 'inbound_tomorrow',
    },
    {
      index: 3,
      title: 'Inventory',
      description: '“Arrived at Warehouse” & “Ready for Outbound”',
      key: 'inventory',
    },
    {
      index: 4,
      title: 'Outbound Today',
      description: 'Outbound Scheduled by today',
      key: 'outbound_today',
    },
    {
      index: 5,
      title: 'Outbound Tomorrow',
      key: 'outbound_tomorrow',
      description: 'Outbound Scheduled by tomorrow',
    },
    {
      index: 6,
      title: 'Archive',
      description: 'Checked out & Departed',
      key: 'archive'
    }
  ];
}
